import { axiosAuthorized } from "../../api/config";

export const getVendors = async () => {
  try {
    const response = await axiosAuthorized.get("/getverifiedvendorincharge");
    return response;
  } catch (error) {
    throw error;
  }
}

export const addVendor = async (initialState, vendorId = null) => {
  try {
    const requestData = {
      ...initialState
    };

    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const url = vendorId
      ? `/vendor/update_vendor/${vendorId}`
      : `/vendor/add_vendor`;

    const response = await axiosAuthorized[vendorId ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const verifyVenderOtp = async (id, otpVerify, setError) => {
  try {
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const response = await axiosAuthorized.post(`/vendorotpverify/${id}`, { otpVerify }, requestConfig);
    return response;
  } catch (error) {
    setError({ global: "Invalid Otp" });
    throw error;
  }
}

export const sendLoginCredentials = async (id, status) => {
  try {
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const response = await axiosAuthorized.put(`/vendor/update_vendor/${id}`, { 
      is_login_credentials: status
     }, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteVendor = async (vendorId) => {
  try {
    const response = await axiosAuthorized.delete(`/vendor/delete_vendor/${vendorId}`);
    return response;
  } catch (error) {
    throw error;
  }
}