import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import {
  ImageInput,
  ShowImage,
  TextAreaInput,
  TextInput,
} from "../Common/FormComponents";
import { toast } from "react-toastify";
import { useForm } from "../../hooks";
import { addAboutHeaderSection } from "../../utils/aboutHeaderSectionUtils/aboutHeaderSectionUtils";

const AboutHeaderSectionModal = ({
  setOpen,
  setDatasFn,
  editData = null,
}) => {
  const [err, setErr] = useState({ global: "" });
  const [loading, setLoading] = useState(false);
  const [profile_picture, setProfilePicture] = useState(
    editData?.profile_picture || null
  );
  const [past_company_logo, setCompanyLogo] = useState(
    editData?.past_company_logo || null
  );
  const initialsState = {
    title: editData?.title || "",
    sub_title: editData?.sub_title || "",
    description: editData?.description || "",
    experience: editData?.experience || "",
  };
  const [formData, handleFormData] = useForm(initialsState);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.title.trim().length)
      return setErr({ global: "Invalid Title" });
    if (!formData.sub_title.trim().length)
      return setErr({ global: "Invalid Subtitle" });
    if (!formData.experience.trim().length)
      return setErr({ global: "Invalid Experience " });
    if (!formData.description.trim().length)
      return setErr({ global: "Invalid Description " });
    if (!profile_picture) return setErr({ global: "Please Select Image" });
    setErr({ global: "" });
    setLoading(true);
    addAboutHeaderSection(formData, profile_picture, past_company_logo, editData?._id)
      .then((res) => {
        setDatasFn();
        setProfilePicture(null);
        setCompanyLogo(null);
        setOpen(false);
        toast(`About Header Section ${editData ? "Edited" : "Added"}`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        toast(
          `About Header Section ${editData ? "Edit Failed" : "Add Failed"}`,
          {
            type: "error",
            autoClose: 3000,
          }
        );
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="flex z-10 absolute w-full h-fit  rounded-md justify-start items-start bg-white pb-10">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className=" flex  w-fit overflow-y-auto max-h-[400px] border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"About Header Section"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5 ">
          <TextInput
            data={formData}
            formName={"title"}
            name="Title"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5 ">
          <TextInput
            data={formData}
            formName={"sub_title"}
            name="Subtitle"
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            data={formData}
            formName={"experience"}
            name="Experience"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5 ">
          <TextAreaInput
            data={formData}
            formName={"description"}
            name="Description"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-fit h-fit justify-between gap-5">
          <ImageInput
            imageName={"Profile Picture"}
            setImage={setProfilePicture}
            dimension="4096px, height: 3162px"
          />
          <ShowImage image={profile_picture} setImage={setProfilePicture} />
        </div>
        <div className="flex w-fit h-fit justify-between gap-5">
          <ImageInput
            imageName={"Company Logo"}
            setImage={setCompanyLogo}
            dimension="width: 155px, height: 155px"
          />
          <ShowImage image={past_company_logo} setImage={setCompanyLogo} />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHeaderSectionModal;
