export const validateAdminForm = (initialState,pages, image, setErr, isMaster) => {
    const emailPattern = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;
    const phoneNumberPattern = /^(?!0{10}$)\d{10}$/;
    
    if (!initialState.name.trim().length) {
        setErr({ global: "Invalid Name" });
        return false;
    }
    if (!phoneNumberPattern.test(initialState.contact)) {
        setErr({ global: "Invalid Name" });
        return false;
    }
    if (!emailPattern.test(initialState.email)) {
        setErr({ global: "Invalid Name" });
        return false;
    }
    if (!initialState.department.trim().length && !isMaster) {
        setErr({ global: "Invalid Department" });
        return false;
    }
    if (!initialState.category.trim().length && !isMaster) {
        setErr({ global: "Invalid Category" });
        return false;
    }
    if (!pages.length && !isMaster) {
        setErr({ global: "Add At Least One Page" });
        return false;
    }
    if (!image) {
        setErr({ global: "Select Image" });
        return false;
    }
    return true;
}