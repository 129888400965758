import { axiosAuthorized } from "../../api/config"

export const getOurSpecailties = async () => {
  try {
    const response = await axiosAuthorized.get(`/getourspecialities`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addOurSpecailties = async (initialState , id = null) => {
  try {
    const requestData = {
      ...initialState,
    };

    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const url = id
      ? `/ourspecialities/update_ourspecialities/${id}`
      : `/ourspecialities/add_ourspecialities`;
    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteOurSpeciality = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/ourspecialities/delete_ourspecialities/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}