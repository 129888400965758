import React, { useState } from 'react'
import { GrFormClose } from 'react-icons/gr';
import { ImageInput, ShowImage, TextInput } from "../Common/FormComponents";
import { useForm } from '../../hooks';
import { vaidateLabForm } from '../../utils/labUtils/validateLabForm';
import { addLab } from '../../utils/labUtils/labUtils';
import { toast } from 'react-toastify';

const LabModal = ({ setOpen,editData = null,setEditData, setLabsFn }) => {
    const labInitialState = {
        name: editData?.name || "",
        owner: editData?.owner_name || "",
        email: editData?.email_id || "",
        phone: editData?.phone_number || "",
        department: editData?.department || "",
        category: editData?.category || ""
    };
    const [labData, handleLabData, clearForm] = useForm(labInitialState);
    const [image, setImage] = useState(editData?.lab_profile_image || null);
    const [err, setErr] = useState({ global: "" });
    const [loading, setLoading] = useState(false);
    const handleSubmit = (e) => {
      e.preventDefault();
      if (vaidateLabForm(labData, image, setErr)) {
        setErr({ global: "" });
        setLoading(true);
        addLab(labData, image, editData?._id)
          .then(res => {
            setErr({ global: "" }); setLabsFn(); clearForm(); setImage(null);
            toast(`Lab ${editData ? "Edited" : "Added"}`, {
              type: "success",
              autoClose: 3000,
            });setEditData(null); setOpen(false);
          })
          .catch(err => {
            console.log(err);
            toast(`Lab ${editData ? "edit" : "add"} failed`, {
              type: "error",
              autoClose: 3000,
            });
          })
          .finally(() => setLoading(false));
      }
    }
  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className="flex  w-full max-w-screen-md overflow-y-auto max-h-screen  border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{editData ? "Edit Lab" : "Add new Lab"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            name={"Name"}
            formName={"name"}
            data={labData}
            handleInput={handleLabData}
            setError={setErr}
          />
          <TextInput
            name={"Owner Name"}
            formName={"owner"}
            data={labData}
            handleInput={handleLabData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            name={"Email"}
            formName={"email"}
            data={labData}
            handleInput={handleLabData}
            setError={setErr}
          />
          <TextInput
            name={"Phone"}
            formName={"phone"}
            data={labData}
            handleInput={handleLabData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            name={"Department"}
            formName={"department"}
            data={labData}
            handleInput={handleLabData}
            setError={setErr}
          />
          <TextInput
            name={"Category"}
            formName={"category"}
            data={labData}
            handleInput={handleLabData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <ShowImage image={image} setImage={setImage} />
          <ImageInput imageName={"Lab Image"} setImage={setImage} />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LabModal