import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import {
  AddButton,
  ImageInput,
  ShowImage,
  TextInput,
} from "../Common/FormComponents";
import { toast } from "react-toastify";
import { useForm } from "../../hooks";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { addOfferSlider } from "../../utils/userDashboardUtils/userDashboardUtils";

const OfferSliderModal = ({
  setOpen,
  setDatasFn,
  editData = null,
  setEditData,
}) => {
  const [err, setErr] = useState({ global: "", offers: "" });
  const [loading, setLoading] = useState(false);
  const [user_offers, setOffers] = useState(editData?.user_offers || []);
  const offerInitialState = { offer: "" };
  const [offerForm, handleOfferForm, clearOfferForm] =
    useForm(offerInitialState);
  const handleOffers = (action, idx = 0) => {
    if (action === "add") {
      if (!offerForm.offer.trim().length) {
        return setErr({ offers: "Please Enter Offer" });
      } else {
        setErr({ offers: "" });
        setOffers([
          ...user_offers,
          offerForm.offer,
        ]);
        clearOfferForm();
      }
    } else if (action === "remove") {
      setOffers(user_offers?.filter((_, index) => index !== idx));
    }
  };
  const [user_offer_image, setOfferImage] = useState(
    editData?.user_offer_image || null
  );
  const initialsState = {
    title: editData?.title || "",
    home_page_number: editData?.home_page_number || "",
    home_page_emailId: editData?.home_page_emailId || "",
  };
  const [formData, handleFormData] = useForm(initialsState);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.title.trim().length)
      return setErr({ global: "Invalid Title" });
    if (!formData.home_page_number.trim().length)
      return setErr({ global: "Invalid Number" });
    if (!formData.home_page_emailId.trim().length)
      return setErr({ global: "Invalid Email" });
    if (!user_offer_image) return setErr({ global: "Please Select Offer Image" });
    setErr({ global: "", user_offers: "" });
    setLoading(true);
    addOfferSlider(formData, user_offers, user_offer_image, editData?._id)
      .then((res) => {
        setDatasFn();
        setOfferImage(null);
        setEditData(null);
        setOpen(false);
        toast(`Offer Slider ${editData ? "Edited" : "Added"}`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        toast(`Offer Slider ${editData ? "Edit Failed" : "Add Failed"}`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="flex z-10 absolute w-full h-fit  rounded-md justify-start items-start bg-white pb-10">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className=" flex  w-fit overflow-y-auto max-h-[400px] border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"Offer Slider"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5 ">
          <TextInput
            data={formData}
            formName={"title"}
            name="Title"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5 ">
          <TextInput
            data={formData}
            formName={"home_page_number"}
            name="Phone"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5 ">
          <TextInput
            data={formData}
            name="Email"
            formName={"home_page_emailId"}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        {user_offers?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {user_offers?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs text-white"
                key={idx}
              >
                <span>{item}</span>
                <button
                  onClick={() => {
                    handleOffers("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer">
          <TextInput
            data={offerForm}
            handleInput={handleOfferForm}
            name={"Offer"}
            formName={"offer"}
            setError={setErr}
          />
          <AddButton handleAdd={handleOffers} />
        </div>
        {err.offers && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.offers}
          </div>
        )}
        <div className="flex w-fit h-fit justify-between gap-5">
          <ImageInput
            imageName={"Offer Image"}
            dimension="Width: 280px, Height: 280px"
            setImage={setOfferImage}
          />
          <ShowImage image={user_offer_image} setImage={setOfferImage} />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferSliderModal;
