import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";

const MapFaqs = ({ faqs, removeFaq, setEditData =null  }) => {
  return (
    <>
      {faqs.length > 0 && (
        <div className="flex w-full flex-wrap gap-2">
          {faqs.map((faq, idx) => (
            <div
              onClick={() => {setEditData && setEditData(faq)}}
              className={`flex gap-2 items-center border w-fit h-fit rounded-md p-1 shadow-md text-xs ${setEditData && "cursor-pointer"}`}
              key={idx}
            >
              <div className="flex items-center gap-1">
                <span>{faq.faq_title}</span>
              </div>
              <button
                onClick={() => {
                  removeFaq(faq?._id);
                }}
              >
                <AiOutlineCloseCircle fontSize={14} color="red" />
              </button>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default MapFaqs;
