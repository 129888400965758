import React from "react";
import { AboutUsMainSection } from "../../components/AboutComponents";

const About = () => {
  return (
    <div className=" w-full h-full px-2 pt-10 sm:p-10 flex flex-col gap-4 relative">
      <AboutUsMainSection/>
    </div>
  );
};

export default About;
