import React from "react";
import SalesFormsMainSection from "../../components/SalesFormsComponents/SalesFormsMainSection";

const SalesForms = () => {
  return (
    <div className=" w-full h-full px-2 pt-10 sm:p-10 flex flex-col gap-4 relative">
      <SalesFormsMainSection/>
    </div>
  );
};

export default SalesForms;
