import { axiosAuthorized } from "../../api/config"

export const getBannerSlider = async () => {
  try {
    const response = await axiosAuthorized.get(`/bannerslider/get_bannerslider`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addBannerSlider = async (banner_slider, id = null) => {
  try {
    const requestData = {
      banner_slider
    }
    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/bannerslider/update_bannerslider/${id}`
      : `/bannerslider/add_bannerslider`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getListingBanners = async () => {
  try {
    const response = await axiosAuthorized.get(`/listingbanner/get_listingbanner`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addListingBanner = async (initialState, listing_banner_image, id = null) => {
  try {
    const requestData = {
      ...initialState,
      listing_banner_image 
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/listingbanner/update_listingbanner/${id}`
      : `/listingbanner/add_listingbanner`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteListingBanner = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/listingbanner/delete_listingbanner/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}