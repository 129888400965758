import React, { useEffect } from 'react'
import { useState } from 'react';
import { LuPlusCircle } from "react-icons/lu";
import { NoDataFound, SearchInput } from '../Common';
import ReferTable from './ReferTable';
import ReferModal from './ReferModal';
import { useCallback } from 'react';
import { getRefferals } from '../../utils/referUtils/referUtils';
import LisOfHospitals from './ListOfHospitals';

const ReferMainSection = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [editData, setEditData] = useState(null);
    const [open, setOpen] = useState(false);
    const [refferals, setRefferals] = useState([]);
    const setRefersFn = useCallback(() => {
        getRefferals()
            .then((res) => setRefferals(res.data.data || []))
            .catch((err) => console.log(err));
    }, []);
    useEffect(() => {
      setRefersFn();
    }, [setRefersFn]);
    const [openList, setOpenList] = useState(false);
  return (
    <>
    {open && <ReferModal setOpen={setOpen} editData={editData} setEditData={setEditData} />}
    {openList && <LisOfHospitals setOpen={setOpenList} editData={editData} setEditData={setEditData} setRefersFn={setRefersFn}/>}
      <div className=" flex w-full items-center gap-5 flex-col">
        <div className="flex w-fit h-fit text-xl font-semibold">Refer</div>
        <div className="flex w-full h-full justify-center items-center">
          <div className="flex w-full md:w-1/2">
            <SearchInput
              placeholder={"Search by Name, Id"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full p-3 flex-col">
        <div className="flex w-full justify-between items-center h-fit">
          <span className="font-semibold text-base">Informations</span>
          <button
            style={{
              background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
            }}
            className={` flex text-white text-sm rounded-md gap-2 p-2  items-center `}
            onClick={() => setOpen(true)}
          >
            <span>Refer</span>
            <span>
              <LuPlusCircle />
            </span>
          </button>
        </div>
        {refferals?.length > 0 ? (
          <ReferTable
            datas={refferals}
            setEditData={setEditData}
            setOpen={setOpen}
            setOpenList={setOpenList}
            setRefersFn={setRefersFn}
          />
        ) : (
          <div className="flex mt-10">
            <NoDataFound data={"refer patients"} />
          </div>
        )}
      </div>
    </>
  );
}

export default ReferMainSection