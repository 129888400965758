import React, { useCallback, useEffect, useState } from "react";
import { NoDataFound } from "../Common";
import { BiImageAdd } from "react-icons/bi";
import OfferSliderModal from "./OfferSliderModal";
import { MdOutlineDeleteOutline } from "react-icons/md";
import {
  deleteOfferImage,
  getOfferImages,
} from "../../utils/sliderUtils/offerSliderUtils";

const OfferSlider = () => {
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const setImagesFn = useCallback(() => {
    getOfferImages()
      .then((res) => setImages(res.data.data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setImagesFn();
  }, [setImagesFn]);
  const delImage = (imageId) => {
    const confirmed = window.confirm("Are you sure you want to delete ?");
    if (!confirmed) return;
    deleteOfferImage(imageId)
      .then((res) => {
        setImagesFn();
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="flex w-full flex-col gap-3">
      <div className="flex w-full h-fit text-base font-semibold gap-5 items-center">
        <span>Offer Slider</span>
        <button
          style={{
            background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
          }}
          onClick={() => {
            setOpen(true);
          }}
          className="flex items-center gap-2 px-2 py-1 text-white text-sm font-normal rounded-md"
        >
          <BiImageAdd fontSize={18} />
          <span>Add</span>
        </button>
      </div>
      <div className="flex w-full h-full relative ">
        {open && (
          <OfferSliderModal setOpen={setOpen} setOfferImagesFn={setImagesFn} />
        )}
        {images?.length > 0 ? (
          <div className=" flex h-fit items-center w-full flex-col mt-5">
            <div className="flex flex-col w-full mt-3 max-h-[400px]">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-blue-100">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase "
                          >
                            Image
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase "
                          >
                            Delete
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200 max-h-[400px] overflow-y-auto">
                        {images.map((image, index) => (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900">
                                <img
                                  src={image?.offer_slider}
                                  className="w-[100px] h-[80px] rounded-md object-cover"
                                  alt={"home_image"}
                                />
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div
                                className="text-sm text-gray-900 capitalize cursor-pointer"
                                onClick={() => {
                                  delImage(image?._id);
                                }}
                              >
                                <MdOutlineDeleteOutline color="red" />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <NoDataFound data={"offer images"} />
        )}
      </div>
    </div>
  );
};

export default OfferSlider;
