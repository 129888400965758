import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { ImageInput, SelectInput, ShowImage, TextInput } from "../Common/FormComponents";
import { toast } from "react-toastify";
import { useForm } from "../../hooks";
import { addAboutCtaAndValuesIcon } from "../../utils/aboutCtaAndValuesIconUtils/aboutCtaAndValuesIconUtils";

const AboutCtaAndValuesIconModal = ({
  setOpen,
  setDatasFn,
  editData = null,
  setEditData,
}) => {
  const [err, setErr] = useState({ global: "" });
  const [loading, setLoading] = useState(false);
  const [about_cta_image, setCTAImage] = useState(
    editData?.about_cta_image || null
  );
  const initialsState = {
    about_cta_text: editData?.about_cta_text || "",
    type: editData?.type || "Select Type",
  };
  const [formData, handleFormData] = useForm(initialsState);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.about_cta_text.trim().length)
      return setErr({ global: "Invalid Title" });
    if (formData.type === "Select Type")
      return setErr({ global: "Please Select Type" });
    if (!about_cta_image) return setErr({ global: "Please Select Image" });
    setErr({ global: "" });
    setLoading(true);
    addAboutCtaAndValuesIcon(formData, about_cta_image, editData?._id)
      .then((res) => {
        setDatasFn();
        setCTAImage(null);
        setOpen(false);
        toast(`About CTA And Values Icon ${editData ? "Edited" : "Added"}`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        toast(`About CTA And Values Icon ${editData ? "Edit Failed" : "Add Failed"}`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="flex z-10 absolute w-full h-fit  rounded-md justify-start items-start bg-white pb-10">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className=" flex  w-fit overflow-y-auto max-h-[400px] border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"About CTA And Values Icon"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5 ">
          <SelectInput
            data={formData}
            formName={"type"}
            name="Type"
            items={["Select Type", "CTA", "Values"]}
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            data={formData}
            formName={"about_cta_text"}
            name="Title"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-fit h-fit justify-between gap-5">
          <ImageInput
            imageName={"Image"}
            setImage={setCTAImage}
            dimension="width: 51px, height: 51px"
          />
          <ShowImage image={about_cta_image} setImage={setCTAImage} />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCtaAndValuesIconModal;
