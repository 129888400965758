import React, { useMemo } from 'react'
import { NoDataFound } from '../Common';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { deleteBlog } from '../../utils/blogsUtils/blogsUtils';
import { CiEdit } from 'react-icons/ci';

const BlogsTable = ({
  setOpen,
  setEditData,
  blogs,
  setBlogsFn,
  user,
  currentPage,
  limit = 10,
}) => {
  const startIndex = (currentPage - 1) * limit + 1; 
  const tableHeadings = useMemo(() => {
    const defaultHeadings = ["SL No", "Title", "Department"];
    const userHasEditAccess = user?.access?.includes("Edit");
    const userHasDeleteAccess = user?.access?.includes("Delete");

    const headings = [...defaultHeadings];

    if (userHasEditAccess) {
      headings.push("Edit");
    }

    if (userHasDeleteAccess) {
      headings.push("Delete");
    }
    return headings;
  }, [user]);
  const delBlog = (blogId) => {
    const confirmed = window.confirm("Are you sure you want to delete ?");
    if (!confirmed) return;
    deleteBlog(blogId)
      .then((res) => setBlogsFn())
      .catch((err) => console.log(err));
  };
  return (
    <div className="flex h-fit items-center w-full flex-col mt-5">
      <div className="flex flex-col w-full mt-3 max-h-screen">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              {blogs.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-blue-100">
                    <tr>
                      {tableHeadings.map((heading, index) => (
                        <th
                          scope="col"
                          key={`${heading}-${index}`}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                        >
                          {heading}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 max-h-screen ">
                    {blogs?.map((blog, index) => (
                      <tr key={`${blog?._id}-${index}`}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 capitalize">
                            {startIndex + index}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center gap-4">
                            <div className="flex-shrink-0  h-16 w-16  justify-center items-center  rounded-md flex">
                              <img
                                className="rounded-md h-16 w-16 object-cover"
                                src={blog?.blog_image}
                                alt="blog_image"
                              />
                            </div>
                            <div
                              className="text-sm text-gray-900 capitalize max-w-sm truncate"
                              style={{ color: "black" }}
                              dangerouslySetInnerHTML={{
                                __html: blog?.blog_title,
                              }}
                            ></div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 capitalize">
                            {blog?.blog_category?.department}
                          </div>
                        </td>
                        <td
                          className={`${
                            !user?.access.includes("Edit") && "hidden"
                          } px-6 py-4 whitespace-nowrap `}
                        >
                          <div
                            className="text-sm text-gray-900 capitalize cursor-pointer"
                            onClick={() => {
                              setEditData(blog);
                              setOpen(true);
                            }}
                          >
                            <CiEdit />
                          </div>
                        </td>
                        <td
                          className={`${
                            !user?.access.includes("Delete") && "hidden"
                          } px-6 py-4 whitespace-nowrap `}
                        >
                          <div
                            className="text-sm text-gray-900 capitalize cursor-pointer"
                            onClick={() => {
                              delBlog(blog?._id);
                            }}
                          >
                            <MdOutlineDeleteOutline color="red" />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <NoDataFound data={" blogs "} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogsTable