import React, { useRef } from "react";
import "./FileInput.css";
import { AiOutlineCloudUpload } from "react-icons/ai";

const FileInput = ({ setFile, fileName }) => {
  const fileInputRef = useRef(null);

  const handleFileInput = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    event.target.value = "";
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    const selectedFile = event.dataTransfer.files[0];
    setFile(selectedFile);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <div
      className="file-input-container"
      onDrop={handleFileDrop}
      onDragOver={handleDragOver}
    >
      <div className="file-input">
        <div className="upload-icon-container text-white">{fileName}</div>
        <div className="file-input-content">
          <button
            type="button"
            className="file-input-button border-primary gap-2 text-white"
            onClick={() => fileInputRef.current?.click()}
          >
            Upload{" "}
            <AiOutlineCloudUpload
              style={{ width: "20px", height: "20px", color: "white" }}
            />
          </button>
        </div>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileInput}
        accept="image/*, video/*, .pdf, .doc, .docx, .txt"
      />
    </div>
  );
};

export default FileInput;
