import React from 'react'
import VendorsMainSection from '../../components/VendorsComponents/VendorsMainSection';

const Vendors = () => {
  return (
    <div className=" w-full h-full px-2 pt-10 sm:p-10 flex flex-col gap-4 relative">
      <VendorsMainSection />
    </div>
  );
}

export default Vendors