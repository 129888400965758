import React, { useCallback, useEffect, useState } from "react";
import { NoDataFound } from "../Common";
import Table from "../Common/Table/Table";
import BannerSliderModal from "./BannerSliderModal";
import { getBannerSlider } from "../../utils/hospitalListingUtils/hospitalListingUtils";

const BannerSlider = () => {
  const [open, setOpen] = useState(false);
  const [datas, setDatas] = useState([]);
  const setDatasFn = useCallback(() => {
    getBannerSlider()
      .then((res) => setDatas(res?.data?.data?.splice(0)))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setDatasFn();
  }, [setDatasFn]);
  const [editData, setEditData] = useState(null);
  return (
    <div className="w-full h-fit flex flex-col gap-5 relative">
      {open && (
        <BannerSliderModal
          setOpen={setOpen}
          setDatasFn={setDatasFn}
          editData={editData}
          setEditData={setEditData}
        />
      )}
      <div className="flex w-full h-fit text-base font-semibold gap-5 items-center">
        <span>Banner Slider</span>
        <button
          style={{
            background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
          }}
          onClick={() => {
            setEditData(datas[0]);
            setOpen(true);
          }}
          className="flex items-center gap-2 px-2 py-1 text-white text-sm font-normal rounded-md"
        >
          <span>Update</span>
        </button>
      </div>
      {datas?.length > 0 ? (
        <div className="max-w-md">
          <Table headings={["Banner"]}>
            {datas?.map((data, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    <div className="flex-shrink-0  h-16 w-16  justify-center items-center  rounded-md flex">
                      <img
                        className="rounded-md h-16 w-16 object-cover"
                        src={data?.banner_slider}
                        alt="banner_slider_image"
                      />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </Table>
        </div>
      ) : (
        <NoDataFound data={"Banner"} />
      )}
    </div>
  );
};

export default BannerSlider;
