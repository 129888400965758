import React from "react";
import CTAIconsMainSection from "../../components/CTAIconsComponents/CTAIconsMainSection";

const CTAIcons = () => {
  return (
    <div className=" w-full h-full px-2 py-10 sm:p-10 flex flex-col gap-4 relative">
      <CTAIconsMainSection/>
    </div>
  );
};

export default CTAIcons;
