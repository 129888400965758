import { axiosInstance } from "../../api/config"

export const getDoctorVideos = async () => {
  try {
    const response = await axiosInstance.get(`/gettestinomal`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addDoctorVideos = async (initialState, id = null) => {
  try {
    const requestData = {
      ...initialState
    };

    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const url = id
      ? `/testinomal/update_testinomal/${id}`
      : `/testinomal/add_testinomal`;

    const response = await axiosInstance[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteDoctorVideos = async (id) => {
  try {
    const response = await axiosInstance.delete(`/testinomal/delete_testinomal/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}
export const getTechnologyVideos = async () => {
  try {
    const response = await axiosInstance.get(`/gettechnologyvideo`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addTechnologyVideos = async (initialState, id = null) => {
  try {
    const requestData = {
      ...initialState,
    };

    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const url = id
      ? `/technologyvideo/update_technologyvideo/${id}`
      : `/technologyvideo/create_technologyvideo`;

    const response = await axiosInstance[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteTechnologVideos = async (id) => {
  try {
    const response = await axiosInstance.delete(`/technologyvideo/delete_technologyvideo/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}
export const getPatientVideos = async () => {
  try {
    const response = await axiosInstance.get(`/getpatientvideo`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addPatientVideos = async (initialState, id = null) => {
  try {
    const requestData = {
      ...initialState
    };

    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const url = id
      ? `/patientvideo/update_patientvideo/${id}`
      : `/patientvideo/create_patientvideo`;

    const response = await axiosInstance[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deletePatientVideos = async (id) => {
  try {
    const response = await axiosInstance.delete(`/patientvideo/delete_patientvideo/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}