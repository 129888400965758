import React, { useCallback, useEffect, useState } from 'react'
import {   MapFaqs } from '../Common/FormComponents';
import {  addFaq, deleteFaq, getFaqs } from '../../utils/departmentUtils/departmentUtils';
import { validateFaqForm } from "../../utils/departmentUtils/validateFaqForm";

const FaqSection = ({ selectedDepartment }) => {
    const [editData, setEditData] = useState(null);
    const [title, setTitle] = useState(editData?.faq_title || "");
    const [answer, setAnswer] = useState(editData?.faq_answer || "");
    const [faqs,setFaqs] = useState([]);
    const setFaqsFn = useCallback(() => {
      getFaqs(selectedDepartment?.slug)
        .then((res) => setFaqs(res.data.data))
        .catch((err) => console.log(err));
    }, [selectedDepartment?.slug]);
    useEffect(() => {
      setFaqsFn();
    }, [setFaqsFn]);
    const remvoeFaq = (faqId) => {
      deleteFaq(faqId).then(res => { setEditData(null); setFaqsFn(); }).catch(err => console.log(err));
  };
  useEffect(() => {
    if (editData) {
      setTitle(editData?.faq_title || "");
      setAnswer(editData?.faq_answer || "");
    } else {
      setTitle("");
      setAnswer("");
    }
  }, [editData]);
    const [err,setErr] = useState({faq:""})
    const [loading, setLoading] = useState(false);
        const handleSubmit = (e) => {
        e.preventDefault();
            if (validateFaqForm({title, answer}, setErr)) {
            setLoading(true);
            setErr({ faq: "" });
            addFaq({ title, answer }, selectedDepartment?._id, editData?._id)
              .then((res) => {
                setTitle("");
                setAnswer("");
                setFaqsFn();
                setEditData(null);
              })
              .catch((err) => {
                console.log(err);
              })
              .finally(() => {
                setLoading(false);
              });
        }
    }
  return (
    <div className="flex flex-col gap-4">
      <div className="flex w-full h-fit items-center justify-between font-semibold">
        <span>{"Add Faqs"}</span>
      </div>
      <MapFaqs faqs={faqs} removeFaq={remvoeFaq} setEditData={setEditData} />
      <div className="feild-conatainer">
        <div className="lable-and-filed">
          <label htmlFor={"Question"} className="label">
            {"Question"}
            <span className="text-red-600">*</span>
          </label>
          <div
            className="input-coantiner"
            onClick={() => setErr({ content: "" })}
          >
            <input
              type="text"
              name={"Question"}
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              placeholder={"Question"}
              className="input"
            />
          </div>
        </div>
      </div>
      <div className="feild-conatainer">
        <div className="lable-and-filed">
          <label htmlFor={"Answer"} className="label">
            {"Answer"}
            <span className="text-red-600">*</span>
          </label>
          <div
            className="input-coantiner"
            onClick={() => setErr({ content: "" })}
          >
            <textarea
              type="text"
              name={"Question"}
              value={answer}
              onChange={(e) => {
                setAnswer(e.target.value);
              }}
              placeholder={"Answer"}
              className="input  min-h-[150px]"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-2">
        <div className="flex bg-slate-200 rounded-md w-fit h-fit">
          {
            <button
              disabled={loading}
              className="p-1 border rounded-md text-black -translate-y-1 active:-translate-y-0 translate-x-1 active:translate-x-0 duration-200 font-semibold bg-white text-xs"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              {editData ? "Edit" : "Add"}
            </button>
          }
        </div>
        <div className="flex bg-slate-200 rounded-md w-fit h-fit">
          {editData && (
            <button
              className="p-1 border rounded-md text-black -translate-y-1 active:-translate-y-0 translate-x-1 active:translate-x-0 duration-200 font-semibold bg-white text-xs"
              onClick={() => {
                setEditData(null);
              }}
            >
              Clear
            </button>
          )}
        </div>
      </div>
      {err.faq && (
        <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
          {err.faq}
        </div>
      )}
    </div>
  );
}

export default FaqSection

