import React from "react";
import SalesMainSection from "../../components/SalesComponents/SalesMainSection";

const Sales = () => {
  return (
    <div className=" w-full h-full px-2 pt-10 sm:p-10 flex flex-col gap-4 relative">
      <SalesMainSection/>
    </div>
  );
};

export default Sales;
