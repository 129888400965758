import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import {
  ImageInput,
  ShowImage,
  TextInput,
} from "../Common/FormComponents";
import { toast } from "react-toastify";
import { useForm } from "../../hooks";
import { addHospitalNetwork } from "../../utils/homeUtils/hospitalNetworkUtils";

const HospitalNetworkModal = ({ setOpen, setDatasFn, editData = null, setEditData }) => {
  const [err, setErr] = useState({ global: "" });
  const [loading, setLoading] = useState(false);
  const initialsState = {
    hospital_name: editData?.hospital_name || "",
    location: editData?.location || "",
    link: editData?.link || "",
    region_name: editData?.region_name || "",
  };
  const [formData, handleFormData] = useForm(initialsState);
  const [image_hospital , setHospitalImage] = useState(
    editData?.image_hospital  || null
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.hospital_name.trim().length)
      return setErr({ global: "Invalid Hospital Name" });
    if (!formData.location.trim().length)
      return setErr({ global: "Invalid Location" });
    if (!formData.region_name.trim().length)
      return setErr({ global: "Invalid Region" });
    if (!formData.link.trim().length)
      return setErr({ global: "Invalid Link" });
    if (!image_hospital ) return setErr({ global: "Please Select Image" });
    setErr({ global: "" });
    setLoading(true);
    addHospitalNetwork(formData, image_hospital , editData?._id)
      .then((res) => {
        setDatasFn();
        setHospitalImage(null);
        setOpen(false);
        toast(`Hosptail Network ${editData ? "Edited" : "Added"}`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        toast(`Hosptail Network ${editData ? "Edit Failed" : "Add Failed"}`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="flex z-10 absolute w-full h-fit  rounded-md justify-start items-start bg-white pb-10">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className=" flex  w-fit overflow-y-auto max-h-[400px] border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"Hospital Network"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            data={formData}
            formName={"hospital_name"}
            name="Hospital Name"
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            data={formData}
            formName={"location"}
            name="Location"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            data={formData}
            formName={"region_name"}
            name="Region"
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            data={formData}
            formName={"link"}
            name="Link"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="feild-conatainer">
          <ShowImage image={image_hospital} setImage={setHospitalImage} />
          <ImageInput
            imageName={"Hospital Image"}
            setImage={setHospitalImage}
          />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HospitalNetworkModal;
