import { axiosAuthorized } from "../../api/config"

export const getFaq = async () => {
  try {
    const response = await axiosAuthorized.get(`/homepage/get_faq`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addFaq = async (faq, faq_image, id = null) => {
  try {
    const requestData = {
      faq,
      faq_image
    };
    
    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/homepage/update_faq/${id}`
      : `/homepage/add_faq`;
    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteFaq = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/homepage/delete_faq/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}