import React, { useState, useEffect, useMemo, useCallback } from "react";
import "./DoctorsMainSection.css";
import DoctorsTable from "./DoctorsTable";
import { getDoctors } from "../../utils/doctorUtils/doctorUtils";
import { FaUserDoctor } from "react-icons/fa6";
import { Loader, Pagination, SearchInput } from "../Common";
import RequestMode from "./RequestMode/RequestMode";
import AddAndEditModal from "./AddAndEditModal";
import { useSelector } from "react-redux";
import _ from "lodash";

const limit = 10;

const DoctorsMainSection = () => {
  const [selected, setSelected] = useState("Approved Requests");
  const [searchQuery, setSearchQuery] = useState("");
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    nextPage: null,
    prevPage: null,
    totalPages: 1,
  });
  const user = useSelector((state) => state.userReducer.admin);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);

  const fetchDoctors = useCallback(
    async (search, page = 1) => {
      setLoading(true);
      try {
        const response = await getDoctors(selected, true, search, page);
        const { data: doctors, pagination } = response?.data;
        setDoctors(doctors);
        setPagination(pagination);
      } catch (error) {
        console.error("Error fetching doctors:", error);
      } finally {
        setLoading(false);
      }
    },
    [selected]
  );

  const debouncedFetchDoctors = useMemo(
    () =>
      _.debounce(async (search, page = 1) => {
        await fetchDoctors(search, page);
      }, 300),
    [fetchDoctors]
  );

  useEffect(() => {
    debouncedFetchDoctors(searchQuery, pagination?.currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, debouncedFetchDoctors, pagination?.currentPage]);

  useEffect(() => {
    debouncedFetchDoctors(searchQuery, 1);
  }, [selected, debouncedFetchDoctors, searchQuery]);

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({ ...prev, currentPage: newPage }));
  };
  const handleSearchQueryChange = (newSearchQuery) => {
    const value = newSearchQuery;
    if (value.length === 1 && value === " ") {
      return;
    }
    const modifiedValue = value.replace(/\s+$/, " ");
    setSearchQuery(modifiedValue);
    setPagination((prev) => ({ ...prev, current: 1 }));
    debouncedFetchDoctors(modifiedValue, 1);
  };
  return (
    <>
      {open && (
        <AddAndEditModal
          setOpen={setOpen}
          editData={editData}
          setEditData={setEditData}
          setDoctorsFn={() =>
            fetchDoctors(searchQuery, pagination?.currentPage)
          }
        />
      )}
      <div className="dp-header flex-col ">
        <div className="flex w-fit h-fit text-xl font-semibold">Doctors</div>
        <div className="dp-header-search-container">
          <div className="flex w-full md:w-1/2">
            <SearchInput
              placeholder={"Search by Name, Id"}
              searchQuery={searchQuery}
              setSearchQuery={handleSearchQueryChange}
            />
          </div>
        </div>
      </div>
      <div className="dp-info-container">
        <div className="dp-info-header">
          <RequestMode selected={selected} setSelected={setSelected} />
          <button
            disabled={!user.access.includes("Edit")}
            style={{
              background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
            }}
            className={`doctor-add-button ${
              !user.access.includes("Edit") ? "hidden" : "flex"
            }`}
            onClick={() => setOpen(true)}
          >
            <span>
              <FaUserDoctor />
            </span>
            <span>Doctor</span>
          </button>
        </div>
        {loading ? (
          <div className="flex mt-10">
            <Loader />
          </div>
        ) : (
          <>
            <DoctorsTable
              doctors={doctors}
              setDoctorsFn={() => {
                fetchDoctors(searchQuery, pagination?.currentPage);
              }}
              setEditData={setEditData}
              selected={selected}
              setOpen={setOpen}
              user={user}
              currentPage={pagination?.currentPage}
              limit={limit}
            />
            {!open && <Pagination
              current={pagination?.currentPage}
              total={pagination?.totalPages}
              onPageChange={handlePageChange}
            />}
          </>
        )}
      </div>
    </>
  );
};

export default DoctorsMainSection;
