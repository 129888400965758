import { axiosAuthorized } from "../../api/config"

export const getRefferals = async () => {
    try {
        const response = await axiosAuthorized.get(`/refferal/get_doctorrefferal`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const updateRefferalStatus = async (refferalId, refferal_status) => {
    try {
        const url = `/refferal/update_doctorrefferal/${refferalId}`;
        const response = await axiosAuthorized.put(url, { refferal_status });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getRefferedListOfHospitals = async (refferalId) => {
    try {
        const response = await axiosAuthorized.get(`/getrequesthospitalrefferal/${refferalId}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const updateHospitalRefferalStatus = async (initialState,selectedHospital) => {
    try {
        const requestData = {
            ...initialState,
            hospital_id: selectedHospital?.hospital_id?._id,
            hospital_quotation: selectedHospital?.hospital_quotation,
            remarks: selectedHospital?.remarks,
            negotiation_status: "Pending",
        }
        const url = `/hospitalrefferal/update_hospitalrefferal/${selectedHospital?._id}`;
        const response = await axiosAuthorized.put(url, requestData);
        return response;
    } catch (error) {
        throw error;
    }
}

export const addRefferal = async (initialState, hospital_lab_report, hospital_insurance_details, hospital_prescription, doctor_id, selectedHospital, refferal_id = null) => {
    try {
        const requestData = {
            ...initialState,
            hospital_lab_report,
            hospital_insurance_details,
            doctor_approx_cost: initialState?.surgery_cost,
            hospital_prescription,
            doctor_id,
            hospital_id: selectedHospital?.hospital_id?._id,
            hospital_quotation: selectedHospital?.hospital_quotation,
            remarks: selectedHospital?.remarks,
            refferal_id
        }
        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };
        const url =  `/hospitalrefferal/add_hospitalrefferal`;
        const response = await axiosAuthorized.post(url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}