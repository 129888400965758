import React, { useEffect, useState } from "react";
import "./DepartmentsMainSection.css";
import { Loader, Pagination, SearchInput } from "../Common";
import { CiHospital1 } from "react-icons/ci";
import DepartmentsTable from "./DepartmenstTable";
import { getDepartments } from "../../utils/departmentUtils/departmentUtils";
import AddAndEditDepartmentModal from "./AddAndEditDepartmentModal";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import _ from "lodash";

const limit = 10;

const DepartmentsMainSection = () => {
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    next: null,
    prev: null,
    total: 1,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [editData, setEditData] = useState(null);
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.userReducer.admin);

  const fetchDepartments = async (search, page = 1) => {
    setLoading(true);
    try {
      const response = await getDepartments(true, search, page);
      const { data: inside } = response?.data;
      const { data: departments, pagination } = inside;
      setDepartments(departments);
      setPagination(pagination);
    } catch (error) {
      console.error("Error fetching departments:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchDepartments = useMemo(
    () =>
      _.debounce(async (search, page = 1) => {
        await fetchDepartments(search, page);
      }, 300),
    []
  );

  useEffect(() => {
    debouncedFetchDepartments(searchQuery, pagination?.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, debouncedFetchDepartments, pagination?.current]);

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({ ...prev, current: newPage }));
  };

  const handleSearchQueryChange = (newSearchQuery) => {
    const value = newSearchQuery;
    if (value.length === 1 && value === " ") {
      return;
    }
    const modifiedValue = value.replace(/\s+$/, " ");
    setSearchQuery(modifiedValue);
    setPagination((prev) => ({ ...prev, current: 1 }));
    debouncedFetchDepartments(modifiedValue, 1);
  };
  return (
    <>
      {open && (
        <AddAndEditDepartmentModal
          setOpen={setOpen}
          user={user}
          editData={editData}
          setEditData={setEditData}
          setDepartmentsFn={() =>
            fetchDepartments(searchQuery, pagination?.current)
          }
        />
      )}
      <div className="department-header flex-col">
        <div className="flex w-fit h-fit text-xl font-semibold">
          Departments
        </div>
        <div className="department-header-search-container">
          <div className="flex w-full md:w-1/2">
            <SearchInput
              placeholder={"Search By Name"}
              searchQuery={searchQuery}
              setSearchQuery={handleSearchQueryChange}
            />
          </div>
        </div>
      </div>
      <div className="department-info-container">
        <div className="department-info-header">
          <span className="font-semibold text-base">Informations </span>
          <button
            disabled={!user.access.includes("Edit")}
            style={{
              background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
            }}
            className={`department-add-button  ${
              !user.access.includes("Edit") ? "hidden" : "flex"
            }`}
            onClick={() => setOpen(true)}
          >
            <span>
              <CiHospital1 />
            </span>
            <span>Department</span>
          </button>
        </div>
        {loading ? (
          <div className="flex mt-10">
            <Loader />
          </div>
        ) : (
          <>
            <DepartmentsTable
              setEditData={setEditData}
              setOpen={setOpen}
              departments={departments}
              user={user}
              setDepartmentsFn={() =>
                fetchDepartments(searchQuery, pagination?.current)
              }
              currentPage={pagination?.current}
              limit={limit}
            />
            {!open && (
              <Pagination
                current={pagination?.current}
                total={pagination?.total}
                onPageChange={handlePageChange}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DepartmentsMainSection;
