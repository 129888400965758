import React from "react";
import MediDocsNumbersMainSection from "../../components/MediDocsNumbersComponents/MediDocsInNumbersMainSection";
const MediDocsNumbers = () => {
  return (
    <div className=" w-full h-full px-2 py-10 sm:p-10 flex flex-col gap-4 relative">
      <MediDocsNumbersMainSection/>
    </div>
  );
};

export default MediDocsNumbers;
