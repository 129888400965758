import React, { useCallback, useEffect, useState } from "react";
import Table from "../Common/Table/Table";
// import { CiEdit } from "react-icons/ci";
// import { MdOutlineDeleteOutline } from "react-icons/md";
import { NoDataFound } from "../Common";
import {
  // deleteOurSpeciality,
  getOurSpecailties
} from "../../utils/homeUtils/ourSpecialities";
import OurSpecialitiesModal from "./OurSpecialitiesModal";

const OurSpecialities = () => {
  const [open, setOpen] = useState(false);
  const [datas, setDatas] = useState({});
  const [editData, setEditData] = useState(null);
  const setDatasFn = useCallback(() => {
    getOurSpecailties()
      .then((res) => setDatas(res.data.data || []))
      .catch((err) => console.error(err));
  }, []);
  useEffect(() => {
    setDatasFn();
  }, [setDatasFn]);
  // const delData = (dataId) => {
  //   const confirmed = window.confirm("Are you sure you want to delete ?");
  //   if (!confirmed) return;
  //   deleteOurSpeciality(dataId)
  //     .then((res) => {
  //       setDatasFn();
  //     })
  //     .catch((err) => console.log(err));
  // };
  return (
    <div className="w-full h-fit flex flex-col gap-5 relative">
      {open && (
        <OurSpecialitiesModal
          setDatasFn={setDatasFn}
          setOpen={setOpen}
          editData={editData}
          setEditData={setEditData}
        />
      )}
      <div className="flex w-full h-fit text-base font-semibold gap-5 items-center">
        <span>Our Specialities</span>
        <button
          style={{
            background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
          }}
          onClick={() => {
            setEditData(datas[0]);
            setOpen(true);
          }}
          className="flex items-center gap-2 px-2 py-1 text-white text-sm font-normal rounded-md"
        >
          <span>Update</span>
        </button>
      </div>
      {datas?.length > 0 ? (
        <Table headings={["Title", "Description"]}>
          {datas?.map((data, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 capitalize">
                  {data?.title}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 capitalize">
                  {data?.description}
                </div>
              </td>
            </tr>
          ))}
        </Table>
      ) : (
        <NoDataFound data={"speciality data"} />
      )}
    </div>
  );
};

export default OurSpecialities;
