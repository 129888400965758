import { axiosAuthorized } from "../../api/config"

export const getAboutHeaderSection = async () => {
  try {
    const response = await axiosAuthorized.get(`/aboutus/get_aboutus`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addAboutHeaderSection = async (initialState, profile_picture, past_company_logo, id = null) => {
  try {
    const requestData = {
      ...initialState,
      profile_picture,
      past_company_logo
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/aboutus/update_aboutus/${id}`
      : `/aboutus/create_aboutus`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteAboutHeaderSection = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/aboutus/delete_aboutus/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}