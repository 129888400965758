import React from 'react'
import { AdminsMainSection } from '../../components/AdminsComponents';

const Admins = () => {
  return (
    <div className=" w-full h-full px-2 pt-10 sm:p-10 flex flex-col gap-4 relative">
      <AdminsMainSection />
    </div>
  );
}

export default Admins