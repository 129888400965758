import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { useForm } from "../../hooks";
import {
  TextAreaInput,
  TextInput,
} from "../Common/FormComponents";
import FileInput from "../Common/FormComponents/FileInput/FileInput";
import ShowFile from "../Common/FormComponents/ShowFile/ShowFile";
import { IoSearchOutline } from "react-icons/io5";
import { useCallback } from "react";
import { getHospitals } from "../../utils/hospitalUtils/hospitalUtils";
import { useEffect } from "react";
import { addRefferal } from "../../utils/referUtils/referUtils";
import { validateReferForm } from "../../utils/referUtils/validateReferForm";

const ReferModal = ({
  setOpen,
  editData = null,
  setEditData = () => {},
  setRefersFn = ()=> {},
}) => {
//   const [selectedHospitals, setSelectedHospitals] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const setHospitalsFn = useCallback(() => {
        getHospitals().then(res => setHospitals(res.data.data)).catch(error => console.error(error));
  }, []);
  useEffect(() => {
    setHospitalsFn();
  }, [setHospitalsFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterdHospitals, setFilteredHospitals] = useState([]);
  useEffect(() => {
    const filteredData = hospitals?.filter((hospital) => {
      const nameMatch = hospital?.hospital_name?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
      return nameMatch;
    });
    setFilteredHospitals(filteredData);
  }, [hospitals, searchQuery]);
  const initialState = {
    hospital_name: editData?.hospital_name || "",
    type: editData?.type || "hospital",
    email_id: editData?.email_id || "",
    region: editData?.region || "",
    patient_name: editData?.patient_name || "",
    contact_no: editData?.contact_no || "",
    alternative_no: editData?.alternative_no || "",
    disease_name: editData?.disease_name || "",
    patient_id: editData?.patient_id || "",
    surgery_cost: editData?.surgery_cost || "",
    medidocs_quotation: editData?.medidocs_quotation || "",
    disease_description: editData?.disease_description || "",
  };
  const [ hospital_lab_report, setLabReportAttachment] = useState(
    editData?.lab_report || null
  );
  const [hospital_insurance_details, setInsuranceDetailsAttachment] = useState(
    editData?.insurance_details || null
  );
  const [hospital_prescription, setPrescription] = useState(
    editData?.prescription || null
  );
  const [selected, setSelected] = useState({ hospital_id: editData?.hospital_id || null, hospital_quotation: editData?.hospital_quotation || "", remarks:  editData?.remarks || "" });
//   const handleHospitals = (selectedHospital, idx = null) => {
//       if (idx !== null) {
//           setSelectedHospitals(selectedHospitals.filter((item, index) => index !== idx));
//           return;
//     }
//     if (!selectedHospital.hospital_quotation.trim().length) {
//         setErr({ hospital: "Please Enter Quotation" });
//         return;
//       }
//       const index = selectedHospitals.findIndex(hospital => hospital.hospital_id?._id === selectedHospital.hospital_id?._id);
//       const updatedHospitals = [...selectedHospitals]; 
//     if (index !== -1) {
//         updatedHospitals[index] = { hospital_id: selectedHospital.hospital_id, hospital_quotation: selectedHospital.hospital_quotation, remarks: selectedHospital.remarks };
//     } else {
//         updatedHospitals.push({
//           hospital_id: selectedHospital.hospital_id,
//           hospital_quotation: selectedHospital.hospital_quotation,
//           remarks: selectedHospital.remarks,
//         });
//     }
//     setSelectedHospitals(updatedHospitals);
//   };
  const [formData, handleFormData] = useForm(initialState);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState({ global: "", hospital: "" });
  const handleSubmit = (e) => {
    e.preventDefault();
      if (validateReferForm(formData, hospital_lab_report, hospital_insurance_details, hospital_prescription, selected, setErr)) {
          setErr({ global: "" });
          setLoading(true)
          addRefferal(formData, hospital_lab_report, hospital_insurance_details, hospital_prescription, editData?.doctor_id?._id, selected, editData?._id)
          .then(res=>{
              setRefersFn();
              setEditData(null);
              setSelected({ hospital_id: null, hospital_quotation: "", remarks: "" });
              setOpen(false);
          })
          .catch(err=> {
            console.error(err)
          })
          .finally(()=>setLoading(false));
      }
  };
  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className="flex  w-full max-w-screen-md overflow-y-auto max-h-screen  border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"Patient Details"}</span>
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </button>
        </div>
        <div className="flex w-full h-fit flex-col gap-5">
          {/* <div className="flex flex-wrap w-full">
            {selectedHospitals?.map((item, idx) => (
              <div
                key={idx}
                className="text-sm px-2 py-1 text-white flex gap-2 border border-white items-center rounded-md"
              >
                <button onClick={()=>{setSelected({ hospital_id: item.hospital_id, hospital_quotation: item.hospital_quotation, remarks: item.remarks });}}>{item?.hospital_id?.hospital_name}</button>
                <button><AiOutlineCloseCircle color="red" onClick={()=>handleHospitals(item,idx)}/></button>
              </div>
            ))}
          </div> */}
          <div className="relative flex flex-col gap-0.5">
            <span className="absolute inset-y-0 left-0 top-5 flex items-center pl-2">
              <IoSearchOutline fontSize={23} />
            </span>
            <label
              htmlFor={"List Of Hospitals"}
              className="text-[14px] text-shadow-black text-white"
            >
              {"List Of Hospitals"}
            </label>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              className="appearance-none rounded-md w-full bg-white border border-gray-300 py-2 pl-10 pr-4 text-gray-700 leading-tight focus:outline-none focus:border-primary"
              placeholder="Search By Name"
            />
          </div>
          <div className="flex gap-5 flex-wrap min-h-[100px] max-h-[100px] overflow-y-scroll px-5 m-3 ">
            {filterdHospitals.length > 0 ? (
              filterdHospitals.map((item) => (
                <div
                  key={item._id}
                  onClick={() => {
                    setSelected({
                      hospital_id: item,
                      hospital_quotation: "",
                      remarks: "",
                    });
                  }}
                  className={`flex px-2 py-1 cursor-pointer w-fit h-fit  border rounded-md ${
                    selected.hospital_id === item
                      ? `bg-white text-primary border-primary`
                      : `text-white`
                  }`}
                >
                  {item.hospital_name}
                </div>
              ))
            ) : (
              <div className="flex h-full w-full justify-center items-center text-white">
                No Hospitals Found!
              </div>
            )}
          </div>
          {selected.hospital_id && (
            <div className="flex flex-col w-full gap-5">
              <div className="flex flex-col w-full gap-0.5 ">
                <label
                  htmlFor={""}
                  className=" text-[14px] text-shadow-black text-white "
                >
                  {`${selected.hospital_id.hospital_name} Quotation`}
                  <span className="text-red-600">*</span>
                </label>
                <div
                  className=" relative flex flex-col justify-center items-end "
                  onClick={() => setErr({ hospital: "" })}
                >
                  <input
                    type="text"
                    value={selected.hospital_quotation}
                    onChange={(e) => {
                      setSelected({
                        ...selected,
                        hospital_quotation: e.target.value,
                      });
                    }}
                    placeholder={`${selected.hospital_id.hospital_name} Quotation`}
                    className="border  p-2 text-[14px] w-full rounded-md outline-none shadow-md "
                  />
                </div>
              </div>
              <div className="flex flex-col w-full gap-0.5 ">
                <label
                  htmlFor={""}
                  className=" text-[14px] text-shadow-black text-white "
                >
                  {`${selected.hospital_id.hospital_name} remarks`}
                </label>
                <div
                  className=" relative flex flex-col justify-center items-end "
                  onClick={() => setErr({ hospital: "" })}
                >
                  <textarea
                    value={selected.remarks}
                    onChange={(e) => {
                      setSelected({
                        ...selected,
                        remarks: e.target.value,
                      });
                    }}
                    placeholder={`${selected.hospital_id.hospital_name} Remarks`}
                    className="border  p-2 text-[14px] w-full min-h-[100px] rounded-md outline-none shadow-md "
                  />
                </div>
              </div>
              {/* <div className="flex bg-gray-400 rounded-md w-fit h-fit">
                <button
                  className=" p-1 border rounded-md text-black -translate-y-1 active:-translate-y-0 translate-x-1 active:translate-x-0 duration-200 font-semibold bg-white text-xs"
                  onClick={() => {
                    handleHospitals(selected);
                  }}
                >
                  Add
                </button>
              </div> */}
            </div>
          )}
        </div>
        {err.hospital && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.hospital}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            formName={"surgery_cost"}
            name={"Doctor’s Surgery Cost Apporx"}
            readOnly={editData?.surgery_cost ? true : false}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            formName={"medidocs_quotation"}
            name={"Medidocs Quotation"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            formName={"patient_name"}
            name={"Patient Name"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            formName={"email_id"}
            name={"Email Id"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            formName={"region"}
            name={"Region"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            formName={"contact_no"}
            name={"Contact No"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            formName={"alternative_no"}
            name={"Alternative No"}
            hide={true}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            formName={"disease_name"}
            name={"Disease Name"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            formName={"patient_id"}
            name={"Patient ID"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextAreaInput
            formName={"disease_description"}
            name={"Disease Description"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex flex-col w-full sm:w-1/2 h-fit  gap-5">
          <FileInput fileName={"Lab Report"} setFile={setLabReportAttachment} />
          <ShowFile
            file={hospital_lab_report}
            setFile={setLabReportAttachment}
          />
        </div>
        <div className="flex flex-col w-full sm:w-1/2 h-fit  gap-5">
          <FileInput
            fileName={"Insurance Details"}
            setFile={setInsuranceDetailsAttachment}
          />
          <ShowFile
            file={hospital_insurance_details}
            setFile={setInsuranceDetailsAttachment}
          />
        </div>
        <div className="flex flex-col w-full sm:w-1/2 h-fit  gap-5">
          <FileInput fileName={"Prescription"} setFile={setPrescription} />
          <ShowFile file={hospital_prescription} setFile={setPrescription} />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferModal;
