import { axiosAuthorized } from "../../api/config"

export const getDepartments = async (withPagination = false, search = '', page = 1) => {
  try {
    let url = withPagination ? `/department/get_departmentwithpagination` : "/department/get_department";
    const params = [];
    if (withPagination && search) {
      params.push(`search=${encodeURIComponent(search)}`);
    }
    if (withPagination && page) {
      params.push(`page=${page}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    const response = await axiosAuthorized.get(url);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addDepartment = async (department, department_description, department_image, department_content_image, meta_name, meta_description, meta_tag, slug, user, departmentId = null) => {
  try {
    const data = {
      department,
      department_description,
      meta_name,
      meta_description,
      meta_tag,
      admin_id: user?._id,
      slug
    };
    const requestData = departmentId ? data : {
      ...data,
      department_image,
      department_content_image
    };
    const requestConfig = {
      headers: { "Content-Type": departmentId ? "application/json" : "multipart/form-data" },
    };

    const url = departmentId
      ? `/department/update_department/${departmentId}`
      : `/department/add_department`;
    const mainRequest = axiosAuthorized[departmentId ? 'put' : 'post'](url, requestData, requestConfig);
    const imageRequests = [];
    if (departmentId && department_image instanceof File) {
      imageRequests.push(
        axiosAuthorized['put'](`/department/update_departmentimage/${departmentId}`, { department_image }, { headers: { "Content-Type": "multipart/form-data" } })
      );
    }
    if (departmentId && department_content_image instanceof File) {
      imageRequests.push(
        axiosAuthorized['put'](`/department/update_departmentcontentimage/${departmentId}`, { department_content_image }, { headers: { "Content-Type": "multipart/form-data" } })
      );
    }
    const response = await mainRequest;
    await Promise.all(imageRequests);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteDepartment = async (departmentId) => {
  try {
    const response = await axiosAuthorized.delete(`/department/delete_department/${departmentId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getDepartmentQualities = async (departmentId) => {
  try {
    const response = await axiosAuthorized.get(`/department/get_qualities/${departmentId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addDepartmentQualities = async (department_id, title, description, qualities, id = null) => {
  try {
    const requestData = {
      department_id,
      title,
      description,
      qualities
    };
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const url = id
      ? `/department/update_qualities/${department_id}`
      : `/department/create_qualities/${department_id}`;
    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getDepartmentDiseaseInformation = async (departmentId) => {
  try {
    const response = await axiosAuthorized.get(`/department/_get_diseasecontent/${departmentId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addDepartmentDiseaseInformation = async (department_id, title, description, lists, id = null) => {
  try {
    const requestData = {
      title,
      department_id,
      description,
      lists
    };
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const url = id
      ? `/department/_update_diseasecontent/${department_id}`
      : `/department/_create_diseasecontent/${department_id}`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addFaq = async (initialState, departmentId, faqId = null) => {
  try {
    const url = faqId ? `/departmentfaq/update_departmentfaq/${faqId}` : `/departmentfaq/add_departmentfaq`
    const response = await axiosAuthorized[faqId ? 'put' : 'post'](url, {
      department_id: departmentId,
      faq_title: initialState.title,
      faq_answer: initialState.answer,
    },
      {
        headers: { "Content-Type": "application/json" }
      });
    return response;
  } catch (error) {
    throw error;
  }
}


export const getFaqs = async (departmentId) => {
  try {
    const response = await axiosAuthorized.get(`/departmentfaqbydepartmentname/${departmentId}`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const deleteFaq = async (faqId) => {
  try {
    const response = await axiosAuthorized.delete(`/departmentfaq/delete_departmentfaq/${faqId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addDepartmentCTAIcon = async (department_icon_name, departmentId, department_cta_icon, iconId = null) => {
  try {
    const data = {
      department_id: departmentId,
      department_icon_name,
    }
    const requestData = department_cta_icon ? { ...data, department_cta_icon } : { ...data };
    const url = iconId ? `/departmentctaicon/update_departmentctaicon/${iconId}` : `/departmentctaicon/add_departmentctaicon`
    const response = await axiosAuthorized[iconId ? 'put' : 'post'](url, requestData,
      {
        headers: { "Content-Type": "multipart/form-data" }
      });
    return response;
  } catch (error) {
    throw error;
  }
}

export const getDepartmentCTAIcons = async (departmentId) => {
  try {
    const response = await axiosAuthorized.get(`/getdepartmentctaiconbydepartmnet/${departmentId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteDepartmentCTAIcon = async (iconId) => {
  try {
    const response = await axiosAuthorized.delete(`/departmentctaicon/delete_departmentctaicon/${iconId}`);
    return response;
  } catch (error) {
    throw error;
  }
}