import { axiosAuthorized } from "../../api/config"

export const getAbout = async () => {
  try {
    const response = await axiosAuthorized.get(`/getabout`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addAbout = async (initialState, check_points, about_image, id = null) => {
  try {
      const requestData = new FormData();
      Object.entries(initialState).forEach(([key, value]) => {
        requestData.append(key, value);
      });
      check_points.forEach((check_point, index) => {
      Object.entries(check_point).forEach(([check_point_key, check_point_value]) => {
        requestData.append(`check_points[${index}][${check_point_key}]`, check_point_value);
      });
      });
      about_image.forEach((image) => {
        requestData.append('about_image', image);
      });
      const requestConfig = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      const url = id ? `/about/update_about/${id}` : `/about/create_about`;
      const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
      return response;
  } catch (error) {
    throw error;
  }
}

export const deleteAbout = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/about/delete_about/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}