import { axiosAuthorized } from "../../api/config"

export const getSalesForms = async () => {
  try {
    const response = await axiosAuthorized.get(`/getbdform`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addSalesForms = async (initialState, form_fields, bd, id = null) => {
  try {
    const requestData = {
      ...initialState,
      form_fields,
      bd
    };

    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const url = id
      ? `/bdform/update_bdform/${id}`
      : `/bdform/add_bdform`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteSalesForms = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/bdform/delete_bdform/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}