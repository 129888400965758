import React from 'react'
import { useState } from 'react'
import { sendLoginCredentials } from '../../utils/venderUtils/venderUtils';
import { BiCheck } from "react-icons/bi";
import { IoIosClose } from "react-icons/io";

const ShareCredentials = ({ setDatasFn , data}) => {
  const [loading, setLoading] = useState(false);
  const shareLogin = (vendorId, status) => {
    const confirmed = window.confirm("Are you sure you want to this action ?");
    if (!confirmed) return;
    setLoading(true);
    sendLoginCredentials(vendorId, status)
      .then((res) => setDatasFn())
      .catch((err) => console.log(err))
      .finally(()=> setLoading(false));
  };
  if(loading) return (
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="text-sm text-gray-900 capitalize">Please wait...</div>
    </td>
  );
  return (
    <>
      {data?.is_login_credentials === null && (
        <td className="px-6 py-4 whitespace-nowrap text-sm font-semibold w-full flex justify-start gap-3">
          <button
            onClick={() => shareLogin(data?._id, false)}
            className="text-red-600 w-fit border rounded-md p-1  shadow-lg h-fit justify-center items-center active:bg-slate-200"
          >
            <IoIosClose />
          </button>
          <button
            onClick={() => shareLogin(data?._id, true)}
            className="w-fit border rounded-md p-1  shadow-lg h-fit justify-center items-center active:bg-slate-200 text-blue-600"
          >
            <BiCheck />
          </button>
        </td>
      )}
      {data?.is_login_credentials === false && (
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-red-600 capitalize">Rejected</div>
        </td>
      )}
      {data?.is_login_credentials === true && (
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-green-600 capitalize">
            Shared
          </div>
        </td>
      )}
    </>
  );
};

export default ShareCredentials