import React from "react";
import "./Doctors.css";
import { DoctorsMainSection } from "../../components/DoctorsComponents";

const Doctors = () => {
  return (
    <div className="doctors-page-container relative">
      <DoctorsMainSection/>
    </div>
  );
};

export default Doctors;
