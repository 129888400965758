import { axiosAuthorized } from "../../api/config"

export const getAdvertise = async () => {
  try {
    const response = await axiosAuthorized.get(`/advertise/get_advertise`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addAdvertise = async (initialState, advertise_image, id = null) => {
  try {
    const requestData = {
      ...initialState,
      advertise_image
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/advertise/update_advertise/${id}`
      : `/advertise/create_advertise`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteAdvertise = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/advertise/delete_advertise/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}