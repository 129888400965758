import React from "react";
import Advertise from "./Advertise";
import MissionAndVision from "./MissionAndVision";
import AboutValue from "./AboutValue";
import AboutCtaAndValuesIcon from "./AboutCtaAndValuesIcon";
import AboutHeaderSection from "./AboutHeaderSection";
import Regions from "./Regions";

const AboutUsMainSection = () => {
  return (
    <div className=" flex w-full items-center gap-5 md:gap-0 flex-col">
      <div className="flex w-full h-fit text-xl font-semibold">About Us</div>
      <div className="flex flex-col w-full mt-5 gap-5">
        <AboutHeaderSection />
        <Regions/>
        <Advertise />
        <MissionAndVision />
        <AboutValue />
        <AboutCtaAndValuesIcon />
      </div>
    </div>
  );
};

export default AboutUsMainSection;
