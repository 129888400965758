import React, { useState } from "react";
import { TextInput } from "../Common/FormComponents";
import { useForm } from "../../hooks";
import { toast } from "react-toastify";
import { addVendor } from "../../utils/venderUtils/venderUtils";

const emailPattern = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;
const phoneNumberPattern = /^(?!0{10}$)\d{10}$/;

const VendorForm = ({
  setOpen,
  editData = null,
  setEditData,
  setDatasFn,
  setResponse,
  setOtpSended,
}) => {
  const initialState = {
    name: editData?.name || "",
    designation: editData?.designation || "",
    email_id: editData?.email_id || "",
    phone_number: editData?.phone_number || "",
  };
  const [formData, handleInput, clearForm] = useForm(initialState);
  const [err, setErr] = useState({ global: "" });
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.name.trim().length) {
      return setErr({ global: "Invalid Name" });
    }
    if (!formData.designation.trim().length) {
      return setErr({ global: "Invalid Designation" });
    }
    if (!emailPattern.test(formData.email_id)) {
      return setErr({ global: "Invalid Email" });
    }
    if (!phoneNumberPattern.test(formData.phone_number)) {
      return setErr({ global: "Invalid Phone Number" });
    }
    setErr({ global: "" });
    setLoading(true);
    addVendor(formData, editData?._id)
      .then((res) => {
        setErr({ global: "" });
        clearForm();
        if (!editData) {
          setResponse(res.data.data);
          toast(`OTP sent successfully!`, {
            type: "success",
            autoClose: 3000,
          });
          setOtpSended(true);
        } else {
          setDatasFn(); 
          toast(`Vender Incharge User Edited`, {
            type: "success",
            autoClose: 3000,
          });
          setEditData(null);
          setOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (!editData) {  
          toast(`Failed to send OTP. Please try again.`, {
            type: "error",
            autoClose: 3000,
          });  
        } else {
          toast(`Vender Incharge User Edit Failed!`, {
            type: "success",
            autoClose: 3000,
          });
        }
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="flex w-full h-full justify-center items-center flex-col gap-5 py-5">
      <div className="flex w-full h-fit justify-between gap-5">
        <TextInput
          name={"Name"}
          formName={"name"}
          data={formData}
          handleInput={handleInput}
          setError={setErr}
        />
        <TextInput
          name={"Designation"}
          formName={"designation"}
          data={formData}
          handleInput={handleInput}
          setError={setErr}
        />
      </div>
      <div className="flex w-full h-fit justify-between gap-5">
        <TextInput
          name={"Email"}
          formName={"email_id"}
          data={formData}
          handleInput={handleInput}
          setError={setErr}
        />
        <TextInput
          name={"Phone"}
          formName={"phone_number"}
          data={formData}
          handleInput={handleInput}
          setError={setErr}
        />
      </div>
      {err.global && (
        <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
          {err.global}
        </div>
      )}
      <div className="flex w-full h-fit justify-between gap-5">
        <div className=" flex flex-col w-full gap-0.5 ">
          <button
            className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            Cancel
          </button>
        </div>
        <div className="flex flex-col w-full gap-0.5 ">
          <button
            className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
            disabled={loading}
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            {loading ? "Please Wait..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VendorForm;
