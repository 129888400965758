import React from "react";
import { HospitalMainSection } from "../../components/HospitalsComponents";

const Hospitals = () => {
  return (
    <div className=" w-full h-full px-2 pt-10 sm:p-10 flex flex-col gap-4 relative">
        <HospitalMainSection/>
    </div>
  );
};

export default Hospitals;
