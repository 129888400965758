import axios from "axios"
import { store } from "../redux/store";
import { logout } from "../redux/userSlice";

const baseURL = process.env.REACT_APP_SERVER_URL;

export const axiosAuthorized = axios.create({ baseURL: baseURL, });

export const axiosInstance = axios.create({ baseURL: baseURL, });

axiosAuthorized.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
            config.headers["authorization"] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosAuthorized.interceptors.response.use(
    (response) => response,
    (error) => {
        if (
            error.response &&
            error.response.status === 401 || error.response.status === 403
        ) {
            store.dispatch(logout());
        }
        return Promise.reject(error);
    }
);

