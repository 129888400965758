import { axiosAuthorized } from "../../api/config"

export const getCTAIcons = async () => {
  try {
    const response = await axiosAuthorized.get(`/getctaicon`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addCTAIcon = async (initialState, cta_icon, id = null) => {
  try {
    const requestData = {
      ...initialState,
      cta_icon
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/ctaicon/update_ctaicon/${id}`
      : `/ctaicon/add_ctaicon`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteCTAIcon = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/ctaicon/delete_ctaicon/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}