import React from 'react'

const RequestMode = ({selected,setSelected}) => {
  return (
        <div className="flex font-semibold gap-3 text-non_selected cursor-pointer">
          <span className={`${selected === "Approved Requests" && "text-primary"}`}  onClick={()=>{setSelected("Approved Requests");}}>Approved</span>
          <span className="text-black">|</span> 
          <span className={`${selected === "Add Requests" && "text-primary"  }`}  onClick={()=>{setSelected("Add Requests");}}>Add</span>
          <span className="text-black">|</span> 
          <span className={`${selected === "Delete Requests" && "text-primary"  }`}  onClick={()=>{setSelected("Delete Requests");}}>Delete</span>
        </div>
  )
}

export default RequestMode