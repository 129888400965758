import { axiosAuthorized } from "../../api/config"

export const getRegions = async () => {
  try {
    const response = await axiosAuthorized.get(`/region/get_region`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addRegion = async (initialState, popular_region, region_icon, id = null) => {
  try {
    const requestData = {
      ...initialState,
      popular_region,
      region_icon
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/Region/update_region/${id}`
      : `/region/add_region`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteRegion = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/region/delete_region/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}