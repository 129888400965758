import React from "react";
import OfferSlider from "./OfferSlider";
import NeedHelp from "./NeedHelp";
import BannerImages from "./BannerImges";

const UserDashboardMainSection = () => {
  return (
    <div className=" flex w-full items-center gap-5 md:gap-0 flex-col">
      <div className="flex w-full h-fit text-xl font-semibold">
        User Dashboard
      </div>
      <div className="flex flex-col w-full mt-5 gap-5">
        <OfferSlider />
        <NeedHelp />
        <BannerImages />
      </div>
    </div>
  );
};

export default UserDashboardMainSection;
