import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { useForm } from "../../hooks";
import { AddButton, SelectInput, TextInput } from "../Common/FormComponents";
import { toast } from "react-toastify";
import { addSalesForms } from "../../utils/salesFormsUtils/salesFormsUtils";
import { AiOutlineCloseCircle } from "react-icons/ai";

const SalesFormModal = ({
  setOpen,
  editData = null,
  setEditData = () => {},
  setDatasFn,
}) => {
  const initialState = {
    form_name: editData?.form_name || "",
  };
  const [values, setValues] = useState([]);
  const valuesInitialState = {
    label: "",
    value:"",
  }
  const [valuesForm, handleValuesForm, clearValuesFrom] = useForm(valuesInitialState);
  const handleValues = (action, idx = 0) => {
      if (action === "add") {
        if (!valuesForm.label.trim().length) {
          return setErr({ global: "Please Enter Label" });
        } else {
          setValues([
            ...values,
            {
              label: valuesForm.label,
              value: valuesForm.type,
            },
          ]);
          clearValuesFrom();
        }
      } else if (action === "remove") {
        setValues(values.filter((_, index) => index !== idx));
      }
  };
  const [isRequired, setIsRequired] = useState(true);
  const formFieldInitialState = { name: "", type: "Select Input Type" };
  const [formFieldsForm, handleFormFieldsForm, clearFormFieldsForm] = useForm(formFieldInitialState);
  const [form_fields, setFormFields] = useState(editData?.form_fields || []);
  const handleFormFields = (action, idx = 0) => {
    if (action === "add") {
      if (!formFieldsForm.name.trim().length)   return setErr({ global: "Please Enter Field Name" });
      if (formFieldsForm.type === "Select Input Type") return setErr({ global: "Please Select Input Type" });
      if (["Radio", "Select"].includes(formFieldsForm.type) && !values.length) return setErr({ global: "Please Enter One Label And Value" });
        setFormFields([
          ...form_fields,
          {
            name: formFieldsForm.name,
            type: formFieldsForm.type,
            isRequired,
            values,
          },
        ]);
        setValues([]);
        clearFormFieldsForm();
    } else if (action === "remove") {
      setFormFields(form_fields.filter((_, index) => index !== idx));
    }
  };
  const [formData, handleFormData, clearForm] = useForm(initialState);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState({ global: "" });
  const handleSubmit = (e) => {
    e.preventDefault();
    setErr({ global: "" });
    setLoading(true);
    if(!form_fields?.length) return setErr({ global: "Please Enter One Field" });
    addSalesForms(formData,form_fields,[],editData?._id)
      .then((res) => {
        setDatasFn();
        clearForm();
        setEditData(null);
        setOpen(false);
        toast(`Sales Form ${editData ? `Edited` : `Added`}`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        toast(`Sales Form ${editData ? `Edit` : `Add`} Failed`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className="flex  w-full max-w-screen-md overflow-y-auto max-h-screen  border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{editData ? "Edit Sales Form" : "Add New Sales Form"}</span>
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </button>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            formName={"form_name"}
            name={"Name"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        {form_fields?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {form_fields?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs text-white"
                key={idx}
              >
                <span>{item?.name} - {item?.type}</span>
                <button
                  onClick={() => {
                    handleValues("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            formName={"name"}
            name={"Field Name"}
            data={formFieldsForm}
            handleInput={handleFormFieldsForm}
            setError={setErr}
          />
          <SelectInput
            items={[
              "Select Input Type",
              "Text",
              "Number",
              "Description",
              "Select",
              "Date",
              "Time",
              "Checkbox",
              "Radio",
              "Image",
              "File",
            ]}
            formName={"type"}
            name={"Input Type"}
            data={formFieldsForm}
            handleInput={handleFormFieldsForm}
            setError={setErr}
          />
        </div>
        <div className="flex w-full gap-2">
          <span className="text-shadow-black text-white">Field Is Required :</span>
          <input
            type="checkbox"
            checked={isRequired}
            onChange={(e) => setIsRequired(e.target.checked)}
          />
        </div>
        {["Radio", "Select"].includes(formFieldsForm.type) && (
          <>
            {values?.length > 0 && (
              <div className="flex w-full gap-1 flex-wrap">
                {values?.map((item, idx) => (
                  <div
                    className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs text-white"
                    key={idx}
                  >
                    <span>{item?.label}</span>
                    <button
                      onClick={() => {
                        handleValues("remove", idx);
                      }}
                    >
                      <AiOutlineCloseCircle fontSize={14} color="red" />
                    </button>
                  </div>
                ))}
              </div>
            )}
            <div className="flex w-full h-fit justify-between gap-5">
              <TextInput
                formName={"label"}
                name={"Label"}
                data={valuesForm}
                handleInput={handleValuesForm}
                setError={setErr}
              />
              <TextInput
                formName={"value"}
                name={"Value"}
                data={valuesForm}
                handleInput={handleValuesForm}
                setError={setErr}
              />
              <AddButton handleAdd={handleValues} />
            </div>
          </>
        )}
        <AddButton handleAdd={handleFormFields} />
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesFormModal;
