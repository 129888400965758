import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  ImageInput,
  MapContents,
  ShowImage,
} from "../Common/FormComponents";
import { validateContentForm } from "../../utils/treamentUtils/validateContentForm";
import {
  addContent,
  deleteContenet,
  getContents,
} from "../../utils/treamentUtils/treatmentUtils";
import JoditEditor, {Jodit} from "jodit-react";

const config = {
  readonly: false,
  placeholder: "Answer",
  uploader: {
    insertImageAsBase64URI: true,
  },
  controls: {
    paragraph: {
      list: Jodit.atom({
        p: "Pharagraph",
        h1: "Heading 1",
        h2: "Heading 2",
        h3: "Heading 3",
        h4: "Heading 4",
        h5: "Heading 5",
        h6: "Heading 6",
        blockquote: "Quote",
        div: "Div",
        pre: "Source code",
      }),
    },
  },
};
const ContentSection = ({ selectedTreatment }) => {
  const [editData, setEditData] = useState(null);
  const [question, setQuestion] = useState(editData?.question || "");
  const editor = useRef(null);
  const [answer, setAnswer] = useState(editData?.answer || "<p></p>");
  const [contents, setContents] = useState([]);
  const setContentsFn = useCallback(() => {
    getContents(selectedTreatment?._id)
      .then((res) => setContents(res.data.data))
      .catch((err) => console.log(err));
  }, [selectedTreatment?._id]);
  useEffect(() => {
    setContentsFn();
  }, [setContentsFn]);
  const [contentImage, setContentImage] = useState(editData?.content_images || null);
    useEffect(() => {
      if(editData){
          setQuestion(editData?.question || "");
          setAnswer(editData?.answer || "");
          setContentImage(editData?.content_images || null);
      } else {
        setQuestion("");
        setAnswer("");
        setContentImage(null);
      }
    }, [editData]);
  const [err, setErr] = useState({ content: "" });
  const [loading, setLoading] = useState(false);
  const removeContent = (contentId) => {
    deleteContenet(contentId)
      .then((res) => { setEditData(null); setContentsFn(); })
      .catch((err) => console.log(err));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateContentForm(question, answer, contentImage, setErr)) {
      setLoading(true);
      setErr({ content: "" });
      addContent(question, answer, selectedTreatment?._id, contentImage, editData?._id)
        .then((res) => {
          setContentsFn();
          setQuestion("");
          setAnswer("<p></p>");
          setContentImage(null);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <div className="flex flex-col gap-4">
      <div className="flex w-full h-fit items-center justify-between font-semibold">
        <span>{"Add Contents"}</span>
      </div>
      <MapContents
        contents={contents}
        removeContent={removeContent}
        setEditData={setEditData}
      />
      <div className="feild-conatainer">
        <div className="lable-and-filed">
          <label htmlFor={"Question"} className="label">
            {"Question"}
            <span className="text-red-600">*</span>
          </label>
          <div
            className="input-coantiner"
            onClick={() => setErr({ content: "" })}
          >
            <input
              type="text"
              name={"Question"}
              value={question}
              onChange={(e) => {
                setQuestion(e.target.value);
              }}
              placeholder={"Question"}
              className="input"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full gap-0.5">
        <label htmlFor={"Answer"} className="label">
          {"Answer"} <span className="text-red-600">*</span>
        </label>
        <div className="bg-white text-black">
          <JoditEditor
            ref={editor}
            value={answer}
            config={config}
            tabIndex={1}
            onBlur={(newContent) => {
              setAnswer(
                newContent.replace(
                  /<td(?:[^>]*)style="[^"]*"(?:[^>]*)>/g,
                  '<td style="border: 1px solid black;" >'
                )
              );
            }}
          />
        </div>
      </div>
      <div className="feild-conatainer">
        <ShowImage image={contentImage} setImage={setContentImage} />
        <ImageInput
          imageName={"Content Image"}
          dimension="Width 195px,  Height 180px"
          setImage={setContentImage}
        />
      </div>
      <div className="flex gap-2">
        <div className="flex bg-slate-200 rounded-md w-fit h-fit">
          {
            <button
              disabled={loading}
              className="p-1 border rounded-md text-black -translate-y-1 active:-translate-y-0 translate-x-1 active:translate-x-0 duration-200 font-semibold bg-white text-xs"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              {editData ? "Edit" : "Add"}
            </button>
          }
        </div>
        <div className="flex bg-slate-200 rounded-md w-fit h-fit">
          {editData && (
            <button
              className="p-1 border rounded-md text-black -translate-y-1 active:-translate-y-0 translate-x-1 active:translate-x-0 duration-200 font-semibold bg-white text-xs"
              onClick={() => {
                setEditData(null);
              }}
            >
              Clear
            </button>
          )}
        </div>
      </div>
      {err.content && (
        <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
          {err.content}
        </div>
      )}
    </div>
  );
};

export default ContentSection;
