import React, { useCallback, useEffect, useState } from "react";
import { NoDataFound } from "../Common";
import { CiEdit } from "react-icons/ci";
import Table from "../Common/Table/Table";
import { MdOutlineDeleteOutline } from "react-icons/md";
import BannerImagesModal from "./BannerImagesModal";
import { deleteSecondBanner, getSecondBanners } from "../../utils/homeUtils/secondBannerUtils";

const BannerImages = () => {
  const [open, setOpen] = useState(false);
  const [datas, setDatas] = useState([]);
  const setDatasFn = useCallback(() => {
    getSecondBanners()
      .then((res) => setDatas(res.data.data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setDatasFn();
  }, [setDatasFn]);
  const delData = (dataId) => {
    const confirmed = window.confirm("Are you sure you want to delete ?");
    if (!confirmed) return;
    deleteSecondBanner(dataId)
      .then((res) => {
        setDatasFn();
      })
      .catch((err) => console.log(err));
  };
  const [editData, setEditData] = useState(null);
  return (
    <div className="w-full h-fit flex flex-col gap-5 relative">
      {open && (
        <BannerImagesModal
          setOpen={setOpen}
          setDatasFn={setDatasFn}
          editData={editData}
          setEditData={setEditData}
        />
      )}
      <div className="flex w-full h-fit text-base font-semibold gap-5 items-center">
        <span>Banner Images</span>
        <button
          style={{
            background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
          }}
          onClick={() => setOpen(true)}
          className="flex items-center gap-2 px-2 py-1 text-white text-sm font-normal rounded-md"
        >
          <span>Add</span>
        </button>
      </div>
      {datas?.length > 0 ? (
        <Table headings={["Image", "Edit", "Delete"]}>
          {datas.map((data, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    <div className="flex-shrink-0  h-16 w-16  justify-center items-center  rounded-md flex">
                      <img
                        className="rounded-md h-16 w-16 object-cover"
                        src={data?.single_banner_image}
                        alt="single_banner_image"
                      />
                    </div>
                  </div>
                </td>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div
                  className="text-sm text-gray-900 capitalize cursor-pointer"
                  onClick={() => {
                    setEditData(data);
                    setOpen(true);
                  }}
                >
                  <CiEdit color="green" />
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div
                  className="text-sm text-gray-900 capitalize cursor-pointer"
                  onClick={() => {
                    delData(data?._id);
                  }}
                >
                  <MdOutlineDeleteOutline color="red" />
                </div>
              </td>
            </tr>
          ))}
        </Table>
      ) : (
        <NoDataFound data={"Banner Images"} />
      )}
    </div>
  );
};

export default BannerImages;
