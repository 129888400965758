import React, {  useMemo } from "react";
import "./DepartmentsTable.css";
import { NoDataFound } from "../Common";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { deleteDepartment } from "../../utils/departmentUtils/departmentUtils";

const formatDateTime = (date) => {
  if (!date) return "N/A";
  const options = {
    dateStyle: "medium",
    timeStyle: "medium",
    timeZone: "UTC", 
  };
  return new Date(date).toLocaleString("en-US", options);
};

const DepartmentsTable = ({
  setEditData,
  setOpen,
  setDepartmentsFn,
  departments,
  user,
  currentPage,
  limit = 10,
}) => {
  const startIndex = (currentPage - 1) * limit + 1;
  const tableHeadings = useMemo(() => {
    const defaultHeadings = ["SL No", "Name", "Admin-ID", "CreatedAt", "UpdatedAt"];
    const userHasEditAccess = user?.access?.includes("Edit");
    const userHasDeleteAccess = user?.access?.includes("Delete");

    const headings = [...defaultHeadings];

    if (userHasEditAccess) {
      headings.push("Edit");
    }

    if (userHasDeleteAccess) {
      headings.push("Delete");
    }
    return headings;
  }, [user]);
  const delDepartment = (departmentId) => {
    const confirmed = window.confirm("Are you sure you want to delete ?");
    if (!confirmed) return;
    deleteDepartment(departmentId)
      .then((res) => setDepartmentsFn())
      .catch((err) => console.log(err));
  };
  return (
    <div className="departments-information-container flex-col mt-5">
      <div className="dp-table-container max-h-screen ">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              {departments.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-blue-100">
                    <tr>
                      {tableHeadings.map((heading, index) => (
                        <th
                          scope="col"
                          key={`${heading}-${index}`}
                          className="dp-head-row"
                        >
                          {heading}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 max-h-screen  overflow-y-auto">
                    {departments?.map((department, index) => (
                      <tr key={`${department?._id}-${index}`}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 capitalize">
                            {startIndex + index}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center gap-4">
                            <div className="flex-shrink-0 h-9 w-9 justify-center items-center border rounded-full flex">
                              <img
                                className="rounded-full h-9 w-9 object-cover"
                                src={department?.department_image}
                                alt="lab_profile"
                              />
                            </div>
                            <div className="text-sm font-medium text-gray-900">
                              {department?.department}
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 capitalize max-w-sm truncate">
                            {department?.admin_id?.admin_id || "Not added"}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 capitalize max-w-sm truncate">
                            {formatDateTime(department?.createdAt)}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 capitalize max-w-sm truncate">
                            {formatDateTime(department?.updatedAt)}
                          </div>
                        </td>
                        <td
                          className={`${
                            !user?.access.includes("Edit") && "hidden"
                          } px-6 py-4 whitespace-nowrap `}
                        >
                          <div
                            className="text-sm text-gray-900 capitalize cursor-pointer"
                            onClick={() => {
                              setEditData(department);
                              setOpen(true);
                            }}
                          >
                            <CiEdit />
                          </div>
                        </td>
                        <td
                          className={`${
                            !user?.access.includes("Delete") && "hidden"
                          } px-6 py-4 whitespace-nowrap `}
                        >
                          <div
                            className="text-sm text-gray-900 capitalize cursor-pointer"
                            onClick={() => {
                              delDepartment(department?._id);
                            }}
                          >
                            <MdOutlineDeleteOutline color="red" />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <NoDataFound data={"departments"} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepartmentsTable;
