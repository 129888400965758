import React, { useCallback, useEffect, useState } from "react";
import Table from "../Common/Table/Table";
import { CiEdit } from "react-icons/ci";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { NoDataFound } from "../Common";
import { deleteHospitalNetwork, getHospitalNetwork } from "../../utils/homeUtils/hospitalNetworkUtils";
import HospitalNetworkModal from "./HospitalNetworkModal";

const HospitalNetwork = () => {
  const [open, setOpen] = useState(false);
  const [datas, setDatas] = useState([]);
  const [editData, setEditData] = useState(null);
  const setDatasFn = useCallback(() => {
    getHospitalNetwork()
      .then((res) => setDatas(res.data.data || []))
      .catch((err) => console.error(err));
  }, []);
  useEffect(() => {
    setDatasFn();
  }, [setDatasFn]);
  const delData = (dataId) => {
    const confirmed = window.confirm("Are you sure you want to delete ?");
    if (!confirmed) return;
    deleteHospitalNetwork(dataId)
      .then((res) => {
        setDatasFn();
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="w-full h-fit flex flex-col gap-5 relative">
      {open && (
        <HospitalNetworkModal
          setDatasFn={setDatasFn}
          setOpen={setOpen}
          editData={editData}
          setEditData={setEditData}
        />
      )}
      <div className="flex w-full h-fit text-base font-semibold gap-5 items-center">
        <span>Hospital Network</span>
        <button
          style={{
            background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
          }}
          onClick={() => {
            setOpen(true);
          }}
          className="flex items-center gap-2 px-2 py-1 text-white text-sm font-normal rounded-md"
        >
          <span>Add</span>
        </button>
      </div>
      {datas?.length > 0 ? (
        <Table
          headings={["Image", "H-Name", "Location", "Region", "Edit", "Delete"]}
        >
          {datas?.map((data, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  <div className="flex-shrink-0  h-16 w-16  justify-center items-center  rounded-md flex">
                    <img
                      className="rounded-md h-16 w-16 object-cover"
                      src={data?.image_hospital}
                      alt="image_hospital"
                    />
                  </div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 capitalize">
                  {data?.hospital_name}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 capitalize">
                  {data?.location}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 capitalize">
                  {data?.region_name}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div
                  className="text-sm text-gray-900 capitalize cursor-pointer"
                  onClick={() => {
                    setEditData(data);
                    setOpen(true);
                  }}
                >
                  <CiEdit color="green" />
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div
                  className="text-sm text-gray-900 capitalize cursor-pointer"
                  onClick={() => {
                    delData(data?._id);
                  }}
                >
                  <MdOutlineDeleteOutline color="red" />
                </div>
              </td>
            </tr>
          ))}
        </Table>
      ) : (
        <NoDataFound data={"Hospital Network"} />
      )}
    </div>
  );
};

export default HospitalNetwork;
