import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import {
  ImageInput,
  ShowImage,
  // AddButton,
  TextAreaInput, TextInput
} from "../Common/FormComponents";
import { toast } from "react-toastify";
import { useForm } from "../../hooks";
// import { AiOutlineCloseCircle } from "react-icons/ai";
import { addMissionAndVision } from "../../utils/missionAndVisionUtils/missionAndVisionUtils";

const MissionAndVisionModal = ({ setOpen, setDatasFn, editData = null,   setEditData }) => {
  const [err, setErr] = useState({ global: "" });
  const [loading, setLoading] = useState(false);
  const initialsState = {
    title: editData?.title || "",
    description: editData?.description || "",
  };
  const [about_mission_image, setAboutMissionImage] = useState(editData?.about_mission_image || null);
  const [formData, handleFormData] = useForm(initialsState);
  // const visionInitialState = {
  //   percentage : editData?.percentage || "",
  //   text : editData?.text  || "",
  // };
  // const [visionForm, handleVisionForm, clearVisionForm] = useForm(visionInitialState);
  // const [visions, setVisions] = useState(editData?.visions || []);
  // const handleVisions = (action, idx = 0) => {
  //   if (action === "add") {
  //     if (!visionForm.text.trim().length) {
  //       return setErr({ global: "Please Enter Text" });
  //     } else if (!visionForm.percentage.trim().length) {
  //       return setErr({ global: "Please Enter Percentage" });
  //     } else {
  //       setVisions([...visions,{text:visionForm.text, percentage:visionForm.percentage}]);
  //       clearVisionForm();
  //     }
  //   } else if (action === "remove") {
  //     setVisions(visions.filter((_, index) => index !== idx));
  //   }
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.title.trim().length)
      return setErr({ global: "Invalid Title" });
    if (!about_mission_image) return setErr({ global: "Please Select Image" });
    setErr({ global: "" });
    setLoading(true);
    addMissionAndVision(formData,about_mission_image, editData?._id)
      .then((res) => {
        setDatasFn();
        setOpen(false);
        toast(`Mission and Vision ${editData ? "Edited" : "Added"}`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        toast(`Mission and Vision ${editData ? "Edit Failed" : "Add Failed"}`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="flex z-10 absolute w-full h-fit  rounded-md justify-start items-start bg-white pb-10">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className=" flex  w-fit overflow-y-auto max-h-[400px] border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"Mission and Vision"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5 ">
          <TextInput
            data={formData}
            formName={"title"}
            name="Title"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5 ">
          <TextAreaInput
            data={formData}
            formName={"description"}
            name="Description"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-fit h-fit justify-between gap-5">
          <ImageInput
            dimension="Width 250px, Height 250px"
            imageName={"Mission Image"}
            setImage={setAboutMissionImage}
          />
          <ShowImage
            image={about_mission_image}
            setImage={setAboutMissionImage}
          />
        </div>
        {/* {visions?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {visions?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs text-white"
                key={idx}
              >
                <span>{item?.text}</span>
                <button
                  onClick={() => {
                    handleVisions("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5 ">
          <TextInput
            data={visionForm}
            formName={"text"}
            name="Text"
            handleInput={handleVisionForm}
            setError={setErr}
          />
          <TextInput
            data={visionForm}
            formName={"percentage"}
            name="Percentage "
            handleInput={handleVisionForm}
            setError={setErr}
          />
          <AddButton handleAdd={handleVisions} />
        </div> */}
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionAndVisionModal;
