import { axiosAuthorized } from "../../api/config"

export const getHospitals = async (mode = null, withPagination = false, search = '', page = 1) => {
  try {
    let url = withPagination ? `/hospital/get_hospital` : '/getallhospitals';
    if (mode === `About`) {
      url = `/hospital/get_hospitalabout`;
    }
    const params = [];
    if (withPagination && search) {
      params.push(`search=${encodeURIComponent(search)}`);
    }
    if (withPagination && page) {
      params.push(`page=${page}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    const response = await axiosAuthorized.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};



export const addHospital = async (initialState, hospital_picture, profile_image, hospitalId = null) => {
  try {
    const data = {
      ...initialState,
      phone_number: Number(initialState.phone_number),
    };
    const requestData = hospitalId ? data : {
      ...data,
      hospital_picture,
      profile_image
    };
    const requestConfig = {
      headers: { "Content-Type": hospitalId ? "application/json" : "multipart/form-data" },
    };

    const url = hospitalId
      ? `/hospital/update_hospital/${hospitalId}`
      : `/hospital/create_hospital`;
    const mainRequest = axiosAuthorized[hospitalId ? 'put' : 'post'](url, requestData, requestConfig);
    const imageRequests = [];
    if (hospitalId && hospital_picture instanceof File) {
      imageRequests.push(
        axiosAuthorized['put'](`/hospital/update_hospitalpicture/${hospitalId}`, { hospital_picture }, { headers: { "Content-Type": "multipart/form-data" } })
      );
    }
    if (hospitalId && profile_image instanceof File) {
      imageRequests.push(
        axiosAuthorized['put'](`/hospital/update_hospitalprofileimage/${hospitalId}`, { profile_image }, { headers: { "Content-Type": "multipart/form-data" } })
      );
    }
    const response = await mainRequest;
    await Promise.all(imageRequests);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteHospital = async (hospitalId) => {
  try {
    const response = await axiosAuthorized.delete(`/hospital/delete_hospital/${hospitalId}`);
    return response;
  } catch (error) {
    throw error;
  }
}