import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "../../hooks";
import {  TextInput } from "../Common/FormComponents";
import { addTreatmentDiseaseInformation, getTreatmentDiseaseInformation } from "../../utils/treamentUtils/treatmentUtils";

const TreatmentDiseaseInformations = ({ editData }) => {
  const [data, setData] = useState(null);
  const initialState = {
    disease_name: "",
    surgery_name: "",
    duration: "",
    treated_by: "",
  };
  const [formData, handleFormData, clearFormData, setValues] = useForm(initialState);

  const setDatasFn = useCallback(() => {
    getTreatmentDiseaseInformation(editData?.slug)
      .then((res) => {
        setData(res?.data?.data[0]);
        setValues(res?.data?.data[0] || initialState);
      })
      .catch((err) => console.error(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData?._id]);
  useEffect(() => {
    setDatasFn();
  }, [setDatasFn]);
  const [err, setErr] = useState({ global: "" });
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.disease_name.trim().length) {
      return setErr({ global: "Invalid Disease Name" });
    }
    if (!formData.surgery_name.trim().length) {
      return setErr({ global: "Invalid Surgery Name" });
    }
    if (!formData.duration.trim().length) {
      return setErr({ global: "Invalid Duration" });
    }
    setLoading(true);
    addTreatmentDiseaseInformation(
      editData?._id,
      formData,
      data?._id
    )
      .then((res) => {
        setDatasFn();
        setErr({ global: "" });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="flex flex-col gap-4">
      <div className="flex w-full h-fit items-center justify-between font-semibold">
        <span>{"Add Treatment Info"}</span>
      </div>
      <div className="feild-conatainer ">
        <TextInput
          data={formData}
          handleInput={handleFormData}
          name={"Disease"}
          formName={"disease_name"}
          setError={setErr}
        />
        <TextInput
          data={formData}
          handleInput={handleFormData}
          name={"Surgery Name"}
          formName={"surgery_name"}
          setError={setErr}
        />
      </div>
      <div className="feild-conatainer ">
        <TextInput
          data={formData}
          handleInput={handleFormData}
          name={"Duaration"}
          formName={"duration"}
          setError={setErr}
        />
        <TextInput
          data={formData}
          handleInput={handleFormData}
          name={"Treated By"}
          hide={true}
          formName={"treated_by"}
          setError={setErr}
        />
      </div>
      <div className="feild-conatainer">
        <div className="flex bg-slate-200 rounded-md w-fit h-fit">
          <button
            disabled={loading}
            className="p-1 border rounded-md text-black -translate-y-1 active:-translate-y-0 translate-x-1 active:translate-x-0 duration-200 font-semibold bg-white text-xs"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Submit
          </button>
        </div>
      </div>
      {err.global && (
        <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
          {err.global}
        </div>
      )}
    </div>
  );
};

export default TreatmentDiseaseInformations;
