import React, { useCallback, useEffect, useState } from "react";
import { ImageInput, ShowImage } from "../Common/FormComponents";
import {
  addTreatmentCTAIcon,
  deleteTreatmentCTAIcon,
  getTreatmentCTAIcons,
} from "../../utils/treamentUtils/treatmentUtils";
import { AiOutlineCloseCircle } from "react-icons/ai";

const CTATreatmentSection = ({ selectedTreatment }) => {
  const [editData, setEditData] = useState(null);
  const [treatment_icon_name, setIconName] = useState(
    editData?.treatment_icon_name || ""
  );
  const [icons, setIcons] = useState([]);
  const setIconsFn = useCallback(() => {
    getTreatmentCTAIcons(selectedTreatment?.slug)
      .then((res) => setIcons(res.data.data))
      .catch((err) => console.log(err));
  }, [selectedTreatment?.slug]);
  useEffect(() => {
    setIconsFn();
  }, [setIconsFn]);
  const [treatment_cta_icon, setIconImage] = useState(
    editData?.treatment_cta_icon || null
  );
  useEffect(() => {
    if (editData) {
      setIconName(editData?.treatment_icon_name || "");
      setIconImage(editData?.treatment_cta_icon || null);
    } else {
      setIconName("");
      setIconImage(null);
    }
  }, [editData]);
  const [err, setErr] = useState({ content: "" });
  const [loading, setLoading] = useState(false);
  const removeIcon = (iconId) => {
    deleteTreatmentCTAIcon(iconId)
      .then((res) => { setEditData(null); setIconsFn(); })
      .catch((err) => console.log(err));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!treatment_icon_name.trim().length)
      return setErr({ content: "Invalid Icon Name" });
    if (!treatment_cta_icon) return setErr({ content: "Please Select Icon Image" });
    setLoading(true);
    setErr({ content: "" });
    addTreatmentCTAIcon(
      treatment_icon_name,
      selectedTreatment?._id,
      treatment_cta_icon,
      editData?._id
    )
      .then((res) => {
        setIconsFn();
        setIconName("");
        setIconImage(null);
        setEditData(null);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="flex flex-col gap-4">
      <div className="flex w-full h-fit items-center justify-between font-semibold">
        <span>{"Add CTA Icons"}</span>
      </div>
      {icons.length > 0 && (
        <div className="flex w-full flex-wrap gap-2">
          {icons.map((icon, idx) => (
            <div
              onClick={() => setEditData(icon)}
              className="flex gap-2 items-center cursor-pointer border w-fit h-fit rounded-md p-1 shadow-md text-xs"
              key={idx}
            >
              <div className="flex items-center gap-1">
                <div className={`flex w-7 h-7`}>
                  <img
                    src={
                      icon.treatment_cta_icon instanceof File
                        ? URL.createObjectURL(icon.treatment_cta_icon)
                        : icon.treatment_cta_icon
                    }
                    alt={"icon_image"}
                    className={`rounded-full ${
                      icon.treatment_cta_icon ? "flex" : "hidden"
                    }`}
                  />
                </div>
                <span>{icon.treatment_icon_name}</span>
              </div>
              <button
                onClick={() => {
                  removeIcon(icon._id);
                }}
              >
                <AiOutlineCloseCircle fontSize={14} color="red" />
              </button>
            </div>
          ))}
        </div>
      )}
      <div className="feild-conatainer">
        <div className="lable-and-filed">
          <label htmlFor={"Icon Name"} className="label">
            {"Icon Name"}
            <span className="text-red-600">*</span>
          </label>
          <div
            className="input-coantiner"
            onClick={() => setErr({ content: "" })}
          >
            <input
              type="text"
              name={"Icon Name"}
              value={treatment_icon_name}
              onChange={(e) => {
                setIconName(e.target.value);
              }}
              placeholder={"Icon Name"}
              className="input"
            />
          </div>
        </div>
      </div>
      <div className="feild-conatainer">
        <ShowImage image={treatment_cta_icon} setImage={setIconImage} />
        <ImageInput
          imageName={"Icon Image"}
          dimension="Width 56.73px,  Height 61.31px"
          setImage={setIconImage}
        />
      </div>
      <div className="flex gap-2">
        <div className="flex bg-slate-200 rounded-md w-fit h-fit">
          {
            <button
              disabled={loading}
              className="p-1 border rounded-md text-black -translate-y-1 active:-translate-y-0 translate-x-1 active:translate-x-0 duration-200 font-semibold bg-white text-xs"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              {editData ? "Edit" : "Add"}
            </button>
          }
        </div>
        <div className="flex bg-slate-200 rounded-md w-fit h-fit">
          {editData && (
            <button
              className="p-1 border rounded-md text-black -translate-y-1 active:-translate-y-0 translate-x-1 active:translate-x-0 duration-200 font-semibold bg-white text-xs"
              onClick={() => {
                setEditData(null);
              }}
            >
              Clear
            </button>
          )}
        </div>
      </div>
      {err.content && (
        <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
          {err.content}
        </div>
      )}
    </div>
  );
};

export default CTATreatmentSection;
