import React, { useMemo } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import Table from "../Common/Table/Table";
import { deleteMediDocsNumber } from "../../utils/mediDocsNumbersUtils/mediDocsNumbersUtils";

const MediDocsInNumbersTable = ({
  datas,setEditData, setOpen, setDatasFn, user
}) => {
  const tableHeadings = useMemo(() => {
    const defaultHeadings = ["Number", "N-Text"];
    const userHasEditAccess = user?.access?.includes("Edit");
    const userHasDeleteAccess = user?.access?.includes("Delete");

    const headings = [...defaultHeadings];

    if (userHasEditAccess) {
      headings.push("Edit");
    }

    if (userHasDeleteAccess) {
      headings.push("Delete");
    }
    return headings;
  }, [user]);
  const delData = (id) => {
      const confirmed = window.confirm("Are you sure you want to delete ?");
      if (!confirmed) return;
        deleteMediDocsNumber(id).then(res => { setDatasFn() })
            .catch(error => console.error(error));
    }
  return (
    <Table headings={tableHeadings}>
      {datas?.map((data, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.numbers}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.numbers_text}
            </div>
          </td>
          <td
            className={`${
              !user?.access.includes("Edit") && "hidden"
            } px-6 py-4 whitespace-nowrap `}
          >
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                setEditData(data);
                setOpen(true);
              }}
            >
              <CiEdit color="green" />
            </div>
          </td>
          <td
            className={`${
              !user?.access.includes("Delete") && "hidden"
            } px-6 py-4 whitespace-nowrap `}
          >
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                delData(data?._id);
              }}
            >
              <MdOutlineDeleteOutline color="red" />
            </div>
          </td>
        </tr>
      ))}
    </Table>
  );
};

export default MediDocsInNumbersTable;
