export const validateBlogForm = (initialState, title, description, dpId, blogs_faq, blog_image, author_name, setErr) => {

  if (!title.replace(/<[^>]*>/g, '').trim().length) {
    setErr({ global: "Invalid Title" });
    return false;
  }
  if (dpId === 0) {
    setErr({ global: "Please Select Department" });
    return false;
  }
  if (!description.replace(/<[^>]*>/g, '').trim().length) {
    setErr({ global: "Invalid Content" });
    return false;
  }

  if (!blog_image) {
    setErr({ global: "Please Select Image" });
    return false;
  }

  if (!author_name) {
    setErr({ global: "Please Doctor As Author" });
    return false;
  }

  return true;
}