import React from "react";
import { useMemo } from "react";
import Table from "../Common/Table/Table";
import { updateRefferalStatus } from "../../utils/referUtils/referUtils";

const ReferTable = ({ datas,setOpen,setOpenList, setEditData, setRefersFn }) => {
  const headings = useMemo(
    () => [
      "Doctor-Name",
      "D-ID",
      "Region",
      "R-Actions",
      "P-Details",
      "L-Hospitals",
    ],
    []
  );
  const updateStatus = (refferalId, status) => {
    const confirmed = window.confirm("Are you sure you want to do his action ?");
    if (!confirmed) return;
      updateRefferalStatus(refferalId, status).then(res => setRefersFn()).catch(err => console.error(err));
  }
  return (
    <Table headings={headings}>
      {datas?.map((data, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex items-center gap-4">
              <div className="flex-shrink-0 h-9 w-9 justify-center items-center border rounded-full flex">
                <img
                  className="rounded-full h-9 w-9 object-cover"
                  src={data?.doctor_id?.doctor_image}
                  alt="doctor_image"
                />
              </div>
              <div className="text-sm font-medium text-gray-900">
                {data?.doctor_id?.name}
              </div>
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.doctor_id?.doctor_id}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.region}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            {data?.refferal_status === "Pending" ? (
              <div className="text-sm text-white flex gap-2 capitalize">
                <button
                  onClick={() => {
                    updateStatus(data?._id, "Rejected");
                  }}
                  className="px-2 py-1 text-center bg-red-500 rounded-full"
                >
                  Reject
                </button>
                <button
                  onClick={() => {
                    updateStatus(data?._id, "Accepted");
                  }}
                  className="px-2 py-1 text-center bg-green-500 rounded-full"
                >
                  Accept
                </button>
              </div>
            ) : (
              <div
                className={`text-sm text-center capitalize px-2 py-1 rounded-full text-white ${
                  data?.refferal_status === "Rejected"
                    ? "bg-red-500"
                    : "bg-green-500"
                }`}
              >
                {data?.refferal_status}
              </div>
            )}
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <button
              className="text-sm text-white text-center bg-primary rounded-md capitalize px-2 py-1 cursor-pointer "
              onClick={() => {
                setEditData(data);
                setOpen(true);
              }}
            >
              Patient Details
            </button>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <button
              onClick={() => {
                setEditData(data);
                setOpenList(true);
              }}
              className="text-sm  px-2 py-1 text-white text-center  rounded-md capitalize cursor-pointer "
              style={{
                background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
              }}
            >
              List Of Hospitals
            </button>
          </td>
          {/* <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.medidocs_quotation || (
                <span className="text-red-600 text-sm">Not Added</span>
              )}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div
              className={`text-sm text-center capitalize px-2 py-1 rounded-full text-white ${
                data?.refferal_status === "Rejected"
                  ? "bg-red-500"
                  : data?.refferal_status === "Pending"
                  ? "bg-blue-500"
                  : "bg-green-500"
              }`}
            >
              {data?.refferal_status}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <button
              className="text-sm  px-2 py-1 text-white text-center  rounded-md capitalize cursor-pointer "
              style={{
                background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
              }}
              onClick={() => {
                setEditData(data);
                setOpen(true);
              }}
            >
              Re-Negotiate
            </button>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.negotiation_status}
            </div>
          </td> */}
        </tr>
      ))}
    </Table>
  );
};

export default ReferTable;
