import { axiosAuthorized } from "../../api/config"

export const getNewsAndArticles = async () => {
  try {
    const response = await axiosAuthorized.get(`/news/get_news`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addNewsAndArticle = async (initialState, news_image , id = null) => {
  try {
    const requestData = {
      ...initialState,
      news_image 
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/news/update_news/${id}`
      : `/news/add_news`;
    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteNewsAndArticle = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/news/delete_news/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}