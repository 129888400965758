import { axiosAuthorized } from "../../api/config"

export const getDoctorSlider = async () => {
  try {
    const response = await axiosAuthorized.get(`/slider/get_doctorslider`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addDoctorSlider = async (pic_doctors) => {
  try {
    const requestData = {
      pic_doctors
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = `/slider/add_doctorslider`;

    const response = await axiosAuthorized['post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteDoctorSlider = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/slider/delete_doctorslider/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}