import React, { useEffect, useState } from "react";
import { CiStickyNote } from "react-icons/ci";
import { Loader, Pagination, SearchInput } from "../Common";
import { getBlogs } from "../../utils/blogsUtils/blogsUtils";
import BlogsTable from "./BlogsTable";
import BlogsModal from "./BlogsModal";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import _ from "lodash";

const limit = 10;
const BlogsMainSection = () => {
  const user = useSelector((state) => state.userReducer.admin);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    next: null,
    prev: null,
    total: 1,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const fetchBlogs = async (search, page = 1) => {
    setLoading(true);
    try {
      const response = await getBlogs(search, page);
      const { data: inside } = response?.data;
      const { data: blogs, pagination } = inside;
      setBlogs(blogs);
      setPagination(pagination);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchBlogs = useMemo(
    () =>
      _.debounce(async (search, page = 1) => {
        await fetchBlogs(search, page);
      }, 300),
    []
  );

  useEffect(() => {
    debouncedFetchBlogs(searchQuery, pagination?.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, debouncedFetchBlogs, pagination?.current]);

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({ ...prev, current: newPage }));
  };
  const handleSearchQueryChange = (newSearchQuery) => {
    const value = newSearchQuery;
    if (value.length === 1 && value === " ") {
      return;
    }
    const modifiedValue = value.replace(/\s+$/, " ");
    setSearchQuery(modifiedValue);
    setPagination((prev) => ({ ...prev, current: 1 }));
    debouncedFetchBlogs(modifiedValue, 1);
  };
  return (
    <>
      {open && (
        <BlogsModal
          setBlogsFn={() => fetchBlogs(searchQuery, pagination?.current)}
          setEditData={setEditData}
          setOpen={setOpen}
          editData={editData}
        />
      )}
      <div className=" flex w-full items-center gap-5 flex-col">
        <div className="flex w-fit h-fit text-xl font-semibold">Blogs</div>
        <div className="flex w-full h-full justify-center items-center">
          <div className="flex w-full md:w-1/2">
            <SearchInput
              placeholder={"Search By Title"}
              searchQuery={searchQuery}
              setSearchQuery={handleSearchQueryChange}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full p-3 flex-col">
        <div className="flex w-full justify-between items-center h-fit">
          <span className="font-semibold text-base">Informations</span>
          <button
            disabled={!user.access.includes("Edit")}
            style={{
              background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
            }}
            className={`text-white ${
              !user.access.includes("Edit") ? "hidden" : "flex"
            } text-sm rounded-md gap-2 p-2 items-center`}
            onClick={() => setOpen(true)}
          >
            <span>
              <CiStickyNote />
            </span>
            <span>Blog</span>
          </button>
        </div>
        {loading ? (
          <div className="flex mt-10">
            <Loader />
          </div>
        ) : (
          <>
            <BlogsTable
              blogs={blogs}
              setBlogsFn={() => fetchBlogs(searchQuery, pagination?.current)}
              setEditData={setEditData}
              setOpen={setOpen}
              user={user}
              currentPage={pagination?.current}
              limit={limit}
            />
            {!open && (
              <Pagination
                current={pagination?.current}
                total={pagination?.total}
                onPageChange={handlePageChange}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default BlogsMainSection;
