export const validateHospitalForm = (initialState, hospital_picture, profile_image, setError) => {
    const emailPattern = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;

    if (!initialState.hospital_name.trim().length) {
        setError({ global: "Invalid Hospital Name" });
        return false;
    }

    if (!emailPattern.test(initialState.email_id)) {
        setError({ global: "Invalid Email Address" });
        return false;
    }

    if (!initialState.phone_number.trim().length) {
        setError({ global: "Invalid Phone Number" });
        return false;
    }

    if (!profile_image) {
        setError({ global: "Please Add Profile Image" });
        return false;
    }

    if (!hospital_picture) {
        setError({ global: "Please Add Hospital Image" });
        return false;
    }

    return true;
}