import { axiosAuthorized } from "../../api/config"

export const getSalesBD = async () => {
  try {
    const response = await axiosAuthorized.get(`/getbdregister`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addSalesBD = async (initialState, id = null) => {
  try {
    const requestData = {
      ...initialState,
    };

    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const url = id
      ? `/bdregister/update_bdregister/${id}`
      : `/bdregister/add_bdregister`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteSalesBD = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/bdregister/delete_bdregister/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}