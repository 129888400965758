import { axiosAuthorized } from "../../api/config"

export const getBlogs = async (search = '', page = 1) => {
  try {
    let url = `/blog/get_blog`;
    const params = [];
    if (search) {
      params.push(`search=${encodeURIComponent(search)}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    const response = await axiosAuthorized.get(url);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addBlog = async (initialState, blog_title, blog_description, blog_category, blogs_faq, faq, meta_tag, blog_image, author_name, blogId = null) => {
  try {
    const requestData = {
      blog_title,
      blog_category,
      blog_image,
      blog_description,
      blogs_faq,
      faq,
      author_name: author_name?._id,
      meta_name: initialState.meta_name,
      meta_description: initialState.meta_description,
      meta_tag
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = blogId
      ? `/blog/update_blog/${blogId}`
      : `/blog/create_blog`;

    const response = await axiosAuthorized[blogId ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteBlog = async (blogId) => {
  try {
    const response = await axiosAuthorized.delete(`/blog/delete_blog/${blogId}`);
    return response;
  } catch (error) {
    throw error;
  }
}  