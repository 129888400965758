import { axiosAuthorized } from "../../api/config"

export const getAboutAdvertise = async () => {
  try {
    const response = await axiosAuthorized.get(`/aboutadd/get_aboutadd`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addaboutAdvertise = async (initialState, about_add_image, id = null) => {
  try {
    const requestData = {
      ...initialState,
      about_add_image 
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/aboutadd/update_aboutadd/${id}`
      : `/aboutadd/create_aboutadd`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteAboutAdvertise = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/aboutadd/delete_aboutadd/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}