import { axiosAuthorized } from "../../api/config"

export const getCards = async () => {
  try {
    const response = await axiosAuthorized.get(`/getservicecard`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addCard = async (initialState, card_image, id = null) => {
  try {
    const requestData = {
      ...initialState,
      card_image
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/servicecard/update_servicecard/${id}`
      : `/servicecard/create_servicecard`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteCard = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/servicecard/delete_servicecard/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}