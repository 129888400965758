import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import {
  TextAreaInput,
  TextInput,
} from "../Common/FormComponents";
import { toast } from "react-toastify";
import { useForm } from "../../hooks";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useCallback } from "react";
import { useEffect } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { getDoctors } from "../../utils/doctorUtils/doctorUtils";
import { addOurSpecialitiesDoctors } from "../../utils/homeUtils/ourSpecialitiesDoctors";

const OurSpecialitiesDoctorsModal = ({ setOpen, setDatasFn, editData = null }) => {
  const [doctors, setDoctors] = useState([]);
  const [selected, setSelected] = useState(null);
  const setDoctorsFn = useCallback(() => {
    getDoctors("Approved Requests")
      .then((res) => setDoctors(res.data.data))
      .catch((error) => console.error(error));
  }, []);
  useEffect(() => {
    setDoctorsFn();
  }, [setDoctorsFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = doctors?.filter((doctor) => {
      const nameMatch = doctor?.name
        ?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
      const idMatch = doctor?.doctor_id
        ?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
      return nameMatch || idMatch;
    });
    setFilteredData(filteredData);
  }, [doctors, searchQuery]);
  const [err, setErr] = useState({ global: "" });
  const [doctor_id, setDoctorsData] = useState(editData?.doctor_id || []);
  const [loading, setLoading] = useState(false);
  const initialsState = {
    title: editData?.title || "",
    description: editData?.description || "",
  };
  const [formData, handleFormData] = useForm(initialsState);
  const handleServiceData = (action, idx = 0, item = null) => {
    if (action === "add") {
      if (!item) return;
        const exist = doctor_id.find(data => data?._id === item?._id);
        if(exist) return;
        setSelected(item);
        setDoctorsData([
          ...doctor_id,
          item,
        ]);
    } else if (action === "remove") {
      setDoctorsData(doctor_id.filter((_, index) => index !== idx));
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.title.trim().length)
      return setErr({ global: "Invalid Title" });
    if (!formData.description.trim().length)
      return setErr({ global: "Invalid Description" });
    if (!doctor_id.length)
      return setErr({ global: "Add At Least One Doctors" });
    setErr({ global: "" });
    setLoading(true);
    addOurSpecialitiesDoctors(formData, doctor_id, editData?._id)
      .then((res) => {
        setDatasFn();
        setOpen(false);
        toast(`Our Specialities ${editData ? "Edited" : "Added"}`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        toast(`Our Specialities ${editData ? "Edit Failed" : "Add Failed"}`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="flex z-10 absolute w-full h-fit  rounded-md justify-start items-start bg-white pb-10">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className=" flex  w-fit overflow-y-auto max-h-[400px] border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"Our Specialities Doctors"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit flex-col gap-5 ">
          <TextInput
            data={formData}
            formName={"title"}
            name="Title"
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextAreaInput
            data={formData}
            formName={"description"}
            name="Description"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        {doctor_id?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {doctor_id?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <div className="flex items-center gap-1 text-white">
                  <div className={`flex w-7 h-7`}>
                    <img
                      src={
                        item?.doctor_image instanceof File
                          ? URL.createObjectURL(item?.doctor_image)
                          : item?.doctor_image
                      }
                      alt={"service_image"}
                      className={`rounded-full ${
                        item?.doctor_image ? "flex" : "hidden"
                      }`}
                    />
                  </div>
                  <span>{item?.name}</span>
                </div>
                <button
                  onClick={() => {
                    handleServiceData("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="relative flex flex-col gap-0.5">
          <span className="absolute inset-y-0 left-0 top-5 flex items-center pl-2">
            <IoSearchOutline fontSize={23} />
          </span>
          <label
            htmlFor={"List Of Doctors"}
            className="text-[14px] text-shadow-black text-white"
          >
            {"List Of Doctors"}
          </label>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            className="appearance-none rounded-md w-full bg-white border border-gray-300 py-2 pl-10 pr-4 text-gray-700 leading-tight focus:outline-none focus:border-primary"
            placeholder="Search By Name"
          />
        </div>
        <div className="flex gap-5 flex-wrap min-h-[100px] max-h-[100px] overflow-y-scroll px-5 m-3 ">
          {filteredData.length > 0 ? (
            filteredData.map((item) => (
              <div
                key={item._id}
                onClick={() => {
                  handleServiceData("add", null, item);
                }}
                className={`flex px-2 py-1 cursor-pointer w-fit h-fit  border rounded-md ${
                  selected?._id === item?._id
                    ? `bg-white text-primary border-primary`
                    : `text-white`
                }`}
              >
                {`${item?.name} - ${item?.doctor_id}`}
              </div>
            ))
          ) : (
            <div className="flex h-full w-full justify-center items-center text-white">
              No Doctors Found!
            </div>
          )}
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurSpecialitiesDoctorsModal;
