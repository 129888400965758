import React, { useCallback, useEffect, useState } from 'react'
import { CiHospital1 } from 'react-icons/ci';
import { NoDataFound, SearchInput } from '../Common';
import { useSelector } from 'react-redux';
import SalesTable from './SalesTable';
import SalesDBModal from './SalesBDModal';
import { getSalesBD } from '../../utils/salesUtils/salesUtils';

const SalesMainSection = () => {
  const user = useSelector((state) => state.userReducer.admin);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [datas, setDatas] = useState([]);
  const setDatasFn = useCallback(() => {
    getSalesBD()
      .then((res) => setDatas(res.data.data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setDatasFn();
  }, [setDatasFn]);
  const [filteredData, setFilteredDatas] = useState([]);
  useEffect(() => {
    const filteredData = datas?.filter((data) => {
      const nameMatch = data?.name?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
      const idMatch = data?.bd_id?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      return nameMatch || idMatch;
    });
    setFilteredDatas(filteredData);
  }, [datas, searchQuery]);
  return (
    <>
      {open && (
        <SalesDBModal
          setDatasFn={setDatasFn}
          setOpen={setOpen}
          editData={editData}
          setEditData={setEditData}
        />
      )}
      <div className=" flex w-full items-center gap-5 flex-col">
        <div className="flex w-fit h-fit text-xl font-semibold flex-shrink-0">
          Sales BD
        </div>
        <div className="flex w-full h-full justify-center items-center">
          <div className="flex w-full md:w-1/2">
            <SearchInput
              placeholder={"Search by Name, ID"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full p-3 flex-col">
        <div className="flex w-full justify-between items-center h-fit">
          <span className="font-semibold text-base">Informations</span>
          <button
            style={{
              background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
            }}
            disabled={!user.access.includes("Edit")}
            className={` text-white ${
              !user.access.includes("Edit") ? "hidden" : "flex"
            } text-sm rounded-md gap-2 p-2   items-center`}
            onClick={() => setOpen(true)}
          >
            <span>
              <CiHospital1 />
            </span>
            <span>BD</span>
          </button>
        </div>
        {datas?.length > 0 ? (
          <SalesTable
            datas={filteredData}
            setDatasFn={setDatasFn}
            setEditData={setEditData}
            setOpen={setOpen}
            user={user}
          />
        ) : (
          <div className="flex mt-10">
            <NoDataFound data={"sales bd"} />
          </div>
        )}
      </div>
    </>
  );
}

export default SalesMainSection