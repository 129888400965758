import { axiosAuthorized } from "../../api/config"

export const getUsers = async (withPagination = false, search = '', page = 1) => {
  try {
    let url = `/getsalesbduserwithpagination`;
    const params = [];
    if (withPagination && search) {
      params.push(`search=${encodeURIComponent(search)}`);
    }
    if (withPagination && page) {
      params.push(`page=${page}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    const response = await axiosAuthorized.get(url);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteUser = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/salesbduser/delete_salesbduser/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}