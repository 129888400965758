import { axiosAuthorized } from "../../api/config"

export const getMediDocsNumbers = async () => {
  try {
    const response = await axiosAuthorized.get(`/cta/getnumbers`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addMediDocsNumber = async (initialState, id = null) => {
  try {
    const requestData = {
      ...initialState,
    };

    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const url = id
      ? `/cta/updatenumbers_by_id/${id}`
      : `/cta/createnumbers`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteMediDocsNumber = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/cta/deletenumbers_by_id/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}