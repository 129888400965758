import React from "react";
import  ServicesMainSection from "../../components/ServicesComponents/ServicesMainSection";

const Services = () => {
  return (
    <div className=" w-full h-full px-2 pt-10 sm:p-10 flex flex-col gap-4 relative">
      <ServicesMainSection />
    </div>
  );
};

export default Services;
