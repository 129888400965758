import React, { useCallback, useEffect, useState } from 'react'
import { NoDataFound, SearchInput } from '../Common';
import { CiHospital1 } from 'react-icons/ci';
import { getMediDocsNumbers } from '../../utils/mediDocsNumbersUtils/mediDocsNumbersUtils';
import MediDocsInNumberModal from './MediDocsInNumberModal';
import MediDocsInNumbersTable from './MediDocsInNumbersTable';
import { useSelector } from 'react-redux';

const MediDocsNumbersMainSection = () => {
  const [datas, setDatas] = useState([ ]);
    const setDatasFn = useCallback(() => {
        getMediDocsNumbers().then(res => setDatas(res.data.data || [])).catch(error => console.error(error));
  }, []);
  useEffect(() => {
    setDatasFn();
  }, [setDatasFn]);
  const [searchQuery, setSearchQuery] = useState("");
    const [editData,setEditData] = useState(null);
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.userReducer.admin);
  return (
    <>
      {open && (
        <MediDocsInNumberModal
          setOpen={setOpen}
          setEditData={setEditData}
          setDatasFn={setDatasFn}
          editData={editData}
        />
      )}
      <div className=" flex w-full items-center gap-5 flex-col">
        <div className="flex w-fit h-fit text-xl font-semibold">Numbers</div>
        <div className="flex w-full h-full justify-center items-center">
          <div className="flex w-full md:w-1/2">
            <SearchInput
              placeholder={"Search by Number, N-Text"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full p-3 flex-col">
        <div className="flex w-full justify-between items-center h-fit">
          <span className="font-semibold text-base">Informations</span>
          <button
            disabled={!user.access.includes("Edit")}
            style={{
              background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
            }}
            className={`  text-white text-sm rounded-md gap-2 p-2  items-center  ${
              !user.access.includes("Edit") ? "hidden" : "flex"
            }`}
            onClick={() => setOpen(true)}
          >
            <span>
              <CiHospital1 />
            </span>
            <span>Number</span>
          </button>
        </div>
        {datas?.length > 0 ? (
          <MediDocsInNumbersTable
            datas={datas}
            setEditData={setEditData}
            setDatasFn={setDatasFn}
            setOpen={setOpen}
            user={user}
          />
        ) : (
          <div className="flex mt-10">
            <NoDataFound data={"Numbers"} />
          </div>
        )}
      </div>
    </>
  );
}

export default MediDocsNumbersMainSection