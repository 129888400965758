export const vaidateTreatmentForm = (initialState, description, department_id, symptoms, treatment_image, meta_tag, setError) => {

  if (!initialState.name.trim().length) {
    setError({ global: "Invalid Name" });
    return false;
  }

  if (department_id === 0) {
    setError({ global: "Please Select Department" });
    return false;
  }
  if (!description.replace(/<[^>]*>/g, '').trim().length) {
    setError({ content: "Invalid Description" });
    return false;
  }
  // if (!treatment_image) {
  //   setError({ global: "Please Select Image" });
  //   return false;
  // }
  return true;
}