import React, { useMemo } from 'react'
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { NoDataFound } from '../Common';
import { BiCheck } from 'react-icons/bi';
import { IoIosClose } from 'react-icons/io';
import { deleteLead, udpateLeadValid } from '../../utils/leadsUtils/leadsUitls';
import { TbUserEdit } from 'react-icons/tb';
const LeadsTable = ({user, leads, setLeadsFn, setEditData}) => {
    const tableHeadings = useMemo(
        () => {
            const defaultHeadings = ["Name", "Contact", "Email","Date","Time","Page", "Remark", "isValid"];
            const userHasDeleteAccess = user?.access?.includes("Delete");
            const userHasEditAccess = user?.access?.includes("Edit");
            const headings = [...defaultHeadings];
            if (userHasEditAccess) {
              headings.push("Edit");
            }
            if (userHasDeleteAccess) {
              headings.push("Delete");
            }
            return headings;
        },
      [user]
    );
  const setValid = (type, leadId) => {
      const confirmed = window.confirm("Are you sure you want to do this action ?");
      if (!confirmed) return;
      udpateLeadValid(type, leadId).then(res => setLeadsFn()).catch(err => console.log(err));
    }
  const delLead = (leadId) => {
      const confirmed = window.confirm("Are you sure you want to delete ?");
    if (!confirmed) return;
      deleteLead(leadId).then(res => setLeadsFn()).catch(err => console.log(err));
    }
  return (
    <div className="flex h-fit items-center w-full flex-col mt-5">
      <div className="flex flex-col w-full mt-3 max-h-screen">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              {leads.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-blue-100">
                    <tr>
                      {tableHeadings.map((heading, index) => (
                        <th
                          scope="col"
                          key={index}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                        >
                          {heading}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 max-h-screen">
                    {leads?.map((lead, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 capitalize max-w-sm ">
                            {lead?.patient_name}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 capitalize max-w-sm ">
                            {lead?.contact_number}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 max-w-sm ">
                            {lead?.email_id}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {lead?.createdAt
                              ? new Date(lead.createdAt).toLocaleDateString()
                              : "N/A"}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {lead?.createdAt
                              ? new Date(lead.createdAt).toLocaleTimeString(
                                  "en-US",
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                    timeZone: "UTC",
                                  }
                                )
                              : "N/A"}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 capitalize max-w-sm ">
                            {lead?.page_name}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 capitalize max-w-sm ">
                            {!lead?.remarks ? (
                              "No Remarks"
                            ) : (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: lead.remarks,
                                }}
                              />
                            )}
                          </div>
                        </td>
                        {lead?.valid === null ? (
                          <td className="px-4 py-4 whitespace-nowrap ">
                            <div className="flex gap-2">
                              <button
                                onClick={() => setValid(false, lead?._id)}
                                className="w-fit border rounded-md p-1  shadow-lg h-fit justify-center items-center active:bg-slate-200 text-red-600"
                              >
                                <IoIosClose />
                              </button>
                              <button
                                onClick={() => setValid(true, lead?._id)}
                                className="w-fit border rounded-md p-1  shadow-lg h-fit justify-center items-center active:bg-slate-200 text-blue-600"
                              >
                                <BiCheck />
                              </button>
                            </div>
                          </td>
                        ) : (
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div
                              className={`text-sm ${
                                lead?.valid ? "text-green-600" : "text-red-600"
                              } capitalize`}
                            >
                              {lead?.valid ? "Valid" : "Not Valid"}
                            </div>
                          </td>
                        )}
                        <td
                          className={`${
                            !user?.access.includes("Edit") && "hidden"
                          } px-6 py-4 whitespace-nowrap `}
                        >
                          <div
                            className="text-sm text-gray-900 capitalize cursor-pointer"
                            onClick={() => {
                              setEditData(lead);
                            }}
                          >
                            <TbUserEdit />
                          </div>
                        </td>
                        <td
                          className={`${
                            !user?.access.includes("Delete") && "hidden"
                          } px-6 py-4 whitespace-nowrap `}
                        >
                          <div
                            onClick={() => delLead(lead?._id)}
                            className="text-sm text-gray-900 capitalize cursor-pointer"
                          >
                            <MdOutlineDeleteOutline color="red" />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <NoDataFound data={" leads "} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeadsTable