import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "../../hooks";
import { getDepartments } from "../../utils/departmentUtils/departmentUtils";
import { GrFormClose } from "react-icons/gr";
import {
  AddButton,
  ImageInput,
  SelectInput,
  ShowImage,
  TextAreaInput,
  TextInput,
} from "../Common/FormComponents";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import { addBlog } from "../../utils/blogsUtils/blogsUtils";
import { validateBlogForm } from "../../utils/blogsUtils/validateBlogFrom";
import JoditEditor, { Jodit } from "jodit-react";
import {
  getAllDoctors,
  getDoctorsHospitalWise,
} from "../../utils/appointmentmentDBUtils/doctorUtils/doctorUtils";
import { getHospitals } from "../../utils/appointmentmentDBUtils/hospitalUtils/hospitalUtils";
import { IoSearchOutline } from "react-icons/io5";

const config = {
  readonly: false,
  placeholder: "Content",
  uploader: {
    insertImageAsBase64URI: true,
  },
  controls: {
    paragraph: {
      list: Jodit.atom({
        p: "Pharagraph",
        h1: "Heading 1",
        h2: "Heading 2",
        h3: "Heading 3",
        h4: "Heading 4",
        h5: "Heading 5",
        h6: "Heading 6",
        blockquote: "Quote",
        div: "Div",
        pre: "Source code",
      }),
    },
  },
};
const titleConfig = {
  readonly: false,
  placeholder: "Title",
  uploader: {
    insertImageAsBase64URI: true,
  },
  controls: {
    paragraph: {
      list: Jodit.atom({
        p: "Pharagraph",
        h1: "Heading 1",
        h2: "Heading 2",
        h3: "Heading 3",
        h4: "Heading 4",
        h5: "Heading 5",
        h6: "Heading 6",
        blockquote: "Quote",
        div: "Div",
        pre: "Source code",
      }),
    },
  },
};
const faqAnswerConfig = {
  readonly: false,
  placeholder: "Answer",
  uploader: {
    insertImageAsBase64URI: true,
  },
  controls: {
    paragraph: {
      list: Jodit.atom({
        p: "Pharagraph",
        h1: "Heading 1",
        h2: "Heading 2",
        h3: "Heading 3",
        h4: "Heading 4",
        h5: "Heading 5",
        h6: "Heading 6",
        blockquote: "Quote",
        div: "Div",
        pre: "Source code",
      }),
    },
  },
};
const blogFaqAnswerConfig = {
  readonly: false,
  placeholder: "Answer",
  uploader: {
    insertImageAsBase64URI: true,
  },
  controls: {
    paragraph: {
      list: Jodit.atom({
        p: "Pharagraph",
        h1: "Heading 1",
        h2: "Heading 2",
        h3: "Heading 3",
        h4: "Heading 4",
        h5: "Heading 5",
        h6: "Heading 6",
        blockquote: "Quote",
        div: "Div",
        pre: "Source code",
      }),
    },
  },
};
const BlogsModal = ({ setOpen, editData = null, setEditData, setBlogsFn }) => {
  const [departments, setDepartments] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(
    editData?.author_name || null
  );
  const [doctors, setDoctors] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState(null);
  const setHospitalsFn = useCallback(() => {
    getHospitals()
      .then((res) => setHospitals(res.data.data || []))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setHospitalsFn();
  }, [setHospitalsFn]);
  const [hospitalSearchQuery, setHospitalSearchQuery] = useState("");
  const [filterdHospitals, setFilteredHospitals] = useState([]);
  useEffect(() => {
    const filteredData = hospitals?.filter((hospital) => {
      const nameMatch = hospital?.hospital_name
        ?.toLowerCase()
        ?.includes(hospitalSearchQuery.toLowerCase());
      return nameMatch;
    });
    setFilteredHospitals(filteredData);
  }, [hospitals, hospitalSearchQuery]);
  const setDoctorsFn = useCallback(() => {
    if (selectedHospital?._id) {
      getDoctorsHospitalWise(selectedHospital?._id)
        .then((res) => setDoctors(res.data.data || []))
        .catch((err) => console.log(err));
    } else {
      getAllDoctors()
        .then((res) => setDoctors(res.data.data || []))
        .catch((err) => console.log(err));
    }
  }, [selectedHospital?._id]);
  useEffect(() => {
    setDoctorsFn();
  }, [setDoctorsFn]);
  const [doctorSearchQuery, setDoctorSearchQuery] = useState("");
  const [filterdDoctors, setFilteredDoctors] = useState([]);
  useEffect(() => {
    const filteredData = doctors?.filter((doctor) => {
      const nameMatch = doctor?.name
        ?.toLowerCase()
        ?.includes(doctorSearchQuery.toLowerCase());
      return nameMatch;
    });
    setFilteredDoctors(filteredData);
  }, [doctors, doctorSearchQuery]);
  const initialState = {
    blog_category: editData?.blog_category?.department || "Select Department",
    meta_name: editData?.meta_name || "",
    meta_description: editData?.meta_description || "",
  };
  const editor = useRef(null);
  const titleEditor = useRef(null);
  const faqAnswerEditor = useRef(null);
  const blogFaqAnswerEditor = useRef(null);
  const [title, setTitle] = useState(editData?.blog_title || "<p></p>");
  const [description, setDescription] = useState(
    editData?.blog_description || "<p></p>"
  );
  const [faq_answer, setFaqAnswer] = useState("<p></p>");
  const [blog_faq_answer, setBlogFaqAnswer] = useState("<p></p>");
  const metaTagInitialState = { tag: "" };
  const [metaFormData, handleMetaForm, clearMetaForm] =
    useForm(metaTagInitialState);
  const blogFaqInitialState = { blog_faq_question: "", blog_faq_answer: "" };
  const [blogFaqFormData, handleBlogFaqFromData, clearBlogFaqForm] =
    useForm(blogFaqInitialState);
  const faqInitialState = { faq_question: "", faq_answer: "" };
  const [faqFormData, handleFaqFromData, clearFaqForm] =
    useForm(faqInitialState);
  const [blogs_faq, setBlogFaqs] = useState(editData?.blogs_faq || []);
  const [faq, setFaqs] = useState(editData?.faq || []);
  const handleFaqs = (action, idx = 0) => {
    if (action === "add") {
      if (
        !faqFormData.faq_question.trim().length ||
        !faq_answer.replace(/<[^>]*>/g, "").trim().length
      ) {
        return setError({ faq: "Please Enter Question & Answer" });
      } else {
        setFaqs([
          ...faq,
          {
            faq_question: faqFormData.faq_question,
            faq_answer: faq_answer,
          },
        ]);
        setFaqAnswer("<p></p>");
        clearFaqForm();
      }
    } else if (action === "remove") {
      setFaqs(faq.filter((_, index) => index !== idx));
    }
  };
  const handleBlogFaqs = (action, idx = 0) => {
    if (action === "add") {
      if (
        !blogFaqFormData.blog_faq_question.trim().length ||
        !blog_faq_answer.replace(/<[^>]*>/g, "").trim().length
      ) {
        return setError({ blogFaq: "Please Enter Question & Answer" });
      } else {
        setBlogFaqs([
          ...blogs_faq,
          {
            blog_faq_question: blogFaqFormData.blog_faq_question,
            blog_faq_answer: blog_faq_answer,
          },
        ]);
        setBlogFaqAnswer("<p></p>");
        clearBlogFaqForm();
      }
    } else if (action === "remove") {
      setBlogFaqs(blogs_faq.filter((_, index) => index !== idx));
    }
  };
  const [err, setError] = useState({ global: "", blogFaq: "" });
  const [metaTags, setMetaTags] = useState(editData?.meta_tag || []);
  const handleMetaTags = (action, idx = 0) => {
    if (action === "add") {
      if (!metaFormData.tag.trim().length) {
        return setError({ global: "Please Enter Tag" });
      } else {
        setMetaTags([...metaTags, metaFormData.tag]);
        clearMetaForm();
      }
    } else if (action === "remove") {
      setMetaTags(metaTags.filter((_, index) => index !== idx));
    }
  };
  const [formData, handleInput, clearForm] = useForm(initialState);
  const [deps, setDeps] = useState([]);
  const [dpId, setDpId] = useState(0);
  useEffect(() => {
    getDepartments()
      .then((res) => {
        setDepartments([
          { department: "Select Department", _id: 0 },
          ...res.data.data,
        ]);
        setDeps([
          "Select Department",
          ...res.data.data.map((item) => item.department),
        ]);
      })
      .catch((err) => {
        setDepartments([{ department: "Select Department", _id: 0 }]);
      });
  }, []);
  useEffect(() => {
    const foundDepartment = departments.find(
      (dep) => dep.department === formData.blog_category
    );
    if (foundDepartment) {
      setDpId(foundDepartment._id);
    }
  }, [departments, formData.blog_category]);
  const [loading, setLoading] = useState(false);
  const [blog_image, setBlogImage] = useState(editData?.blog_image || null);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !validateBlogForm(
        formData,
        title,
        description,
        dpId,
        blogs_faq,
        blog_image,
        selectedDoctor,
        setError
      )
    )
      return;
    setError({ global: "", blogFaq: "", faq: "" });
    setLoading(true);
    addBlog(
      formData,
      title,
      description,
      dpId,
      blogs_faq,
      faq,
      metaTags,
      blog_image,
      selectedDoctor,
      editData?._id
    )
      .then((res) => {
        clearForm();
        setBlogImage(null);
        toast(`Blog ${editData ? "Edited" : "Added"}`, {
          type: "success",
          autoClose: 3000,
        });
        setEditData(null);
        setBlogsFn();
        setOpen(false);
      })
      .catch((err) => {
        toast(`Blog ${editData ? "edit" : "add"} failed`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="modal-container">
      <div className="modal">
        <div className="modal-header">
          <span>{editData ? "Edit Blog" : "Add New Blog"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              clearForm();
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex flex-col w-full gap-0.5 text-black">
          <label htmlFor={"Title"} className="label">
            {"Title"} <span className="text-red-600">*</span>
          </label>
          <JoditEditor
            ref={titleEditor}
            value={title}
            config={titleConfig}
            tabIndex={1}
            onBlur={(value) => setTitle(value)}
          />
        </div>
        <div className="feild-conatainer">
          <SelectInput
            data={formData}
            handleInput={handleInput}
            items={deps}
            name={"Department"}
            formName={"blog_category"}
            setError={setError}
          />
        </div>
        <div className="flex flex-col w-full gap-0.5 text-black">
          <label htmlFor={"Content"} className="label">
            {"Content"} <span className="text-red-600">*</span>
          </label>
          <JoditEditor
            ref={editor}
            value={description}
            config={config}
            tabIndex={1}
            onBlur={(newContent) => setDescription(newContent)}
          />
        </div>
        <div className="feild-conatainer">
          {
            <>
              <ShowImage image={blog_image} setImage={setBlogImage} />
              <ImageInput
                image={blog_image}
                setImage={setBlogImage}
                dimension="width: 6240px, height: 4160px"
                imageName={"Blog Image"}
              />
            </>
          }
        </div>
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"List of Hospitals"}</span>
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
            }}
          >
            <GrFormClose fontSize={16} />
          </button>
        </div>
        <div className="flex w-full h-fit flex-col gap-5">
          <div className="relative flex flex-col gap-0.5">
            <span className="absolute inset-y-0 left-0 top-5 flex items-center pl-2">
              <IoSearchOutline fontSize={23} />
            </span>
            <label
              htmlFor={"List Of Hospitals"}
              className="text-[14px] text-shadow-black text-white"
            >
              {"List Of Hospitals"}
            </label>
            <input
              type="text"
              value={hospitalSearchQuery}
              onChange={(e) => {
                setHospitalSearchQuery(e.target.value);
              }}
              className="appearance-none rounded-md w-full bg-white border border-gray-300 py-2 pl-10 pr-4 text-gray-700 leading-tight focus:outline-none focus:border-primary"
              placeholder="Search By Name"
            />
          </div>
          <div className="flex gap-5 flex-wrap min-h-[200px] max-h-[200px] overflow-y-scroll px-5 m-3 ">
            {filterdHospitals?.length > 0 ? (
              filterdHospitals?.map((item) => (
                <div
                  key={item._id}
                  onClick={() => {
                    setSelectedHospital(item);
                  }}
                  className={`flex px-2 py-1 cursor-pointer w-fit h-fit  border rounded-md ${
                    selectedHospital?._id === item?._id
                      ? `bg-white text-primary border-primary`
                      : `text-white`
                  }`}
                >
                  {item?.hospital_name}
                </div>
              ))
            ) : (
              <div className="flex h-full w-full justify-center items-center text-white">
                No Hospitals Found!
              </div>
            )}
          </div>
        </div>
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"List of Hospitals"}</span>
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
            }}
          >
            <GrFormClose fontSize={16} />
          </button>
        </div>
        <div className="flex w-full h-fit flex-col gap-5">
          <div className="relative flex flex-col gap-0.5">
            <span className="absolute inset-y-0 left-0 top-5 flex items-center pl-2">
              <IoSearchOutline fontSize={23} />
            </span>
            <label
              htmlFor={"List Of Doctors"}
              className="text-[14px] text-shadow-black text-white"
            >
              {"List Of doctors"}
            </label>
            <input
              type="text"
              value={doctorSearchQuery}
              onChange={(e) => {
                setDoctorSearchQuery(e.target.value);
              }}
              className="appearance-none rounded-md w-full bg-white border border-gray-300 py-2 pl-10 pr-4 text-gray-700 leading-tight focus:outline-none focus:border-primary"
              placeholder="Search By Name"
            />
          </div>
          <div className="flex gap-5 flex-wrap min-h-[200px] max-h-[200px] overflow-y-scroll px-5 m-3 ">
            {filterdDoctors?.length > 0 ? (
              filterdDoctors?.map((item) => (
                <div
                  key={item._id}
                  onClick={() => {
                    setSelectedDoctor(item);
                  }}
                  className={`flex px-2 py-1 cursor-pointer w-fit h-fit  border rounded-md ${
                    selectedDoctor?._id === item?._id
                      ? `bg-white text-primary border-primary`
                      : `text-white`
                  }`}
                >
                  {item?.name}
                </div>
              ))
            ) : (
              <div className="flex h-full w-full justify-center items-center text-white">
                No Doctors Found!
              </div>
            )}
          </div>
        </div>
        {selectedDoctor && (
          <>
            <div className="flex font-semibold">Selected Author :</div>
            <div
              className={`flex px-2 py-1 cursor-pointer w-fit h-fit  border rounded-md ${`bg-white text-primary border-primary`}`}
            >
              {selectedDoctor?.name}
            </div>
          </>
        )}
        {blogs_faq?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {blogs_faq?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span>{item?.blog_faq_question}</span>
                <button
                  onClick={() => {
                    handleBlogFaqs("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer flex-col">
          <TextInput
            data={blogFaqFormData}
            handleInput={handleBlogFaqFromData}
            name={"Blog Question"}
            formName={"blog_faq_question"}
            setError={setError}
          />
          <div className="flex">
            <div className="flex flex-col w-full gap-0.5 text-black">
              <label htmlFor={"Blog Answer"} className="label">
                {"Blog Answer"} <span className="text-red-600">*</span>
              </label>
              <JoditEditor
                ref={blogFaqAnswerEditor}
                value={blog_faq_answer}
                config={blogFaqAnswerConfig}
                tabIndex={1}
                onBlur={(value) => setBlogFaqAnswer(value)}
              />
            </div>
            <AddButton handleAdd={handleBlogFaqs} />
          </div>
        </div>
        {err.blogFaq && <div className="error-message">{err.blogFaq}</div>}
        {faq?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {faq?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span>{item?.faq_question}</span>
                <button
                  onClick={() => {
                    handleFaqs("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer flex-col">
          <TextInput
            data={faqFormData}
            handleInput={handleFaqFromData}
            name={"Question"}
            formName={"faq_question"}
            setError={setError}
          />
          <div className="flex">
            <div className="flex flex-col w-full gap-0.5 text-black">
              <label htmlFor={"Answer"} className="label">
                {"Answer"} <span className="text-red-600">*</span>
              </label>
              <JoditEditor
                ref={faqAnswerEditor}
                value={faq_answer}
                config={faqAnswerConfig}
                tabIndex={1}
                onBlur={(value) => setFaqAnswer(value)}
              />
            </div>
            <AddButton handleAdd={handleFaqs} />
          </div>
        </div>
        {err.faq && <div className="error-message">{err.faq}</div>}
        <div className="feild-conatainer flex-col">
          <TextInput
            hide={true}
            data={formData}
            handleInput={handleInput}
            name={"Meta Name"}
            formName={"meta_name"}
            setError={setError}
          />
          <TextAreaInput
            hide={true}
            data={formData}
            handleInput={handleInput}
            name={"Meta Description"}
            formName={"meta_description"}
            setError={setError}
          />
        </div>
        {metaTags?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {metaTags?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span>{item}</span>
                <button
                  onClick={() => {
                    handleMetaTags("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer">
          <TextInput
            hide={true}
            data={metaFormData}
            handleInput={handleMetaForm}
            name={"Meta Tag"}
            formName={"tag"}
            setError={setError}
          />
          <AddButton handleAdd={handleMetaTags} />
        </div>
        {err.global && <div className="error-message">{err.global}</div>}
        <div className="feild-conatainer">
          <div className="lable-and-filed">
            <button
              className="cancle-button"
              onClick={() => {
                clearForm();
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="lable-and-filed">
            <button
              className="submit-button"
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogsModal;
