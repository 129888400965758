import { axiosAuthorized } from "../../api/config"

export const getSecondBanners = async () => {
  try {
    const response = await axiosAuthorized.get(`/bannersingle`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addSecondBanner = async (single_banner_image, id = null) => {
  try {
    const requestData = {
      single_banner_image 
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/singlebanner/update_singlebanner/${id}`
      : `/singlebanner/add_singlebanner`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteSecondBanner = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/singlebanner/delete_singlebanner/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}