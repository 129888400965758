import { axiosAuthorized } from "../../api/config"

export const getHealthCards = async () => {
  try {
    const response = await axiosAuthorized.get(`/medidocscard/get_medidocscard`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addHelathCard = async (initialState, medidocs__card_image, id = null) => {
  try {
    const requestData = {
      ...initialState,
      medidocs__card_image
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/medidocscard/update_medidocscard/${id}`
      : `/medidocscard/create_medidocscard`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteHealthCard = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/medidocscard/delete_medidocscard/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}