import React from "react";
import HospitalListingMainSection from "../../components/HospitalListingComponents/HospitalListingMainSection";
const HospitalListing = () => {
  return (
    <div className=" w-full h-full px-2 py-10 sm:p-10 flex flex-col gap-4 relative">
        <HospitalListingMainSection/>
    </div>
  );
};

export default HospitalListing;
