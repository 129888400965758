import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { AddButton, ImageInput, ShowImage, TextAreaInput, TextInput } from "../Common/FormComponents";
import { toast } from "react-toastify";
import { useForm } from "../../hooks";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { addBanner } from "../../utils/homeUtils/mainBannerUtils";

const MainBannerModal = ({ setOpen, setDatasFn, editData = null, setEditData }) => {
  const [err, setErr] = useState({ global: "" });
  const [image, setImage] = useState(editData?.image_doctor || null);
  const [buttons, setButtons] = useState(editData?.buttons || []);
  const buttonInitailsState = { text: "", link: "" };
  const [buttonFormData, handleButtonFromData, clearButtonFormData] =
    useForm(buttonInitailsState);
  const handleButtons = (action, idx = 0) => {
    if (action === "add") {
      if (
        !buttonFormData.text.trim().length ||
        !buttonFormData.link.trim().length
      ) {
        return setErr({ global: "Please Enter Text & Link" });
      } else {
        setButtons([
          ...buttons,
          {
            text: buttonFormData.text,
            link: buttonFormData.link,
          },
        ]);
        clearButtonFormData();
      }
    } else if (action === "remove") {
      setButtons(buttons.filter((_, index) => index !== idx));
    }
  };
  const [loading, setLoading] = useState(false);
  const initialsState = {
    heading: editData?.heading || "",
    description: editData?.description || ""
  }
  const [formData, handleFormData, clearForm] = useForm(initialsState);
  const handleSubmit = (e) => {
    e.preventDefault();
    if(!formData.heading.trim().length) return setErr({ global: "Invalid Heading" });
    if(!formData.description.trim().length) return setErr({ global: "Invalid Description" });
    if (!image) return setErr({ global: "Select Image" });
    setErr({ global: "" });
    setLoading(true);
    addBanner(formData, buttons,image, editData?._id)
      .then((res) => {
        setDatasFn();
        setImage(null);
        setOpen(false);
        clearForm();
        setEditData(null);
        toast(`Main Banner ${editData ? 'Edited' : 'Added'}`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        toast(`Main Banner ${editData ? "Edit Failed" : "Add Failed"}`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="flex z-10 absolute w-full h-fit  rounded-md justify-start items-start bg-white pb-10">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className=" flex  w-fit overflow-y-auto max-h-[400px] border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"Banner Image"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5 flex-col">
          <TextInput
            data={formData}
            formName={"heading"}
            name="Heading"
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextAreaInput
            data={formData}
            formName={"description"}
            name="Description"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        {buttons?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {buttons?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs text-white"
                key={idx}
              >
                <span>{item?.text}</span>
                <button
                  onClick={() => {
                    handleButtons("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer flex-col">
          <TextInput
            data={buttonFormData}
            handleInput={handleButtonFromData}
            name={"Text"}
            formName={"text"}
            setError={setErr}
          />
          <div className="flex">
            <TextAreaInput
              data={buttonFormData}
              handleInput={handleButtonFromData}
              name={"Link"}
              formName={"link"}
              setError={setErr}
            />
            <AddButton handleAdd={handleButtons} />
          </div>
        </div>
        <div className="flex w-fit h-fit justify-between gap-5">
          <ImageInput imageName={"Banner Image"} setImage={setImage} />
          <ShowImage image={image} setImage={setImage} />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setEditData(null);
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainBannerModal;
