import { axiosAuthorized } from "../../api/config"

export const getOurServices = async () => {
  try {
    const response = await axiosAuthorized.get(`/getourservice`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addServices = async (initialState, service_image, id = null) => {
  try {
    const requestData = {
      ...initialState,
      service_image
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };
   
    const url = id
      ? `/ourservice/update_ourservice/${id}`
      : `/ourservice/add_ourservice`;
    
    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteService = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/ourservice/delete_ourservice/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}