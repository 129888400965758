import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BiCheck } from "react-icons/bi";
import { IoIosClose } from "react-icons/io";
import { NoDataFound } from "../Common";
import { getApplicants, updateApplicantStatus } from "../../utils/careerUtils/careerUtils";

const ApplicantsList = ({ job }) => {
  const [applicants, setApplicants] = useState([]);
  const setApplicantsFn = useCallback(() => {
    getApplicants(job?._id)
      .then((res) => {
        setApplicants(res?.data?.data || []);
      })
      .catch((err) => console.error(err));
  }, [job?._id]);
  useEffect(() => {
    setApplicantsFn();
  }, [setApplicantsFn]);
  const tableHeadings = useMemo(() => {
    const defaultHeadings = ["Name", "Email", "Phone", "Resume", "Status"];
    return defaultHeadings;
  }, []);
  const acceptFn = (appointmentId) =>{
    updateApplicantStatus(appointmentId, true)
      .then(res=>setApplicantsFn())
      .catch(err=>console.log(err));
  }
  const rejectFn = (appointmentId) => {
    updateApplicantStatus(appointmentId, false)
      .then(res => setApplicantsFn())
      .catch(err => console.log(err));
  }
  return (
    <div className="flex h-fit items-center w-full flex-col mt-5">
      <div className="flex flex-col w-full mt-3 max-h-[400px]">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              {applicants?.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-blue-100">
                    <tr>
                      {tableHeadings.map((heading, index) => (
                        <th
                          scope="col"
                          key={index}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                        >
                          {heading}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 max-h-[400px] ">
                    {applicants?.map((applicant, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 capitalize max-w-sm truncate">
                            {applicant?.name}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 capitalize">
                            {applicant?.email}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 capitalize">
                            {applicant?.phone_number}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <button
                            className="flex justify-center items-center gap-2 text-xs border rounded-md bg-blue-600 text-white p-2"
                            onClick={() =>
                              window.open(applicant?.resume_pdf,"_blank")
                            }
                          >
                            Download
                          </button>
                        </td>
                        {["true", "false"].includes(applicant?.status) ? (
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className={`text-sm capitalize ${applicant?.status ? 'text-green-600' : 'text-red-600'}`}>
                              {applicant?.status === "true" ? "Accepted": "Rejected"}
                            </div>
                          </td>
                        ) : (
                          <td className="px-4 py-4 whitespace-nowrap flex gap-2 ">
                            <button
                              onClick={() => rejectFn(applicant?._id)}
                              className="w-fit border rounded-md p-1  shadow-lg h-fit justify-center items-center active:bg-slate-200 text-red-600"
                            >
                              <IoIosClose />
                            </button>
                            <button
                              onClick={() => acceptFn(applicant?._id)}
                              className="w-fit border rounded-md p-1  shadow-lg h-fit justify-center items-center active:bg-slate-200 text-blue-600"
                            >
                              <BiCheck />
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <NoDataFound data={" applicants "} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicantsList;
