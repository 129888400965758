import React, { useState } from 'react';
import {GrFormClose} from "react-icons/gr"
import { useForm } from '../../hooks';
import {  ImageInput, ShowImage, TextInput } from '../Common/FormComponents';
import { toast } from "react-toastify";
import { addCTAIcon } from '../../utils/CTAIconsUtils/CTAIconsUtils';


const CTAIconModal = ({
  setOpen,
  editData = null,
  setEditData = () => {},
  setDatasFn,
}) => {
  const initialState = {
    cta_icon_type: editData?.cta_icon_type || "",
    cta_icon_name: editData?.cta_icon_name || "",
  };
  const [cta_icon, setCTAIcon] = useState(editData?.cta_icon || null);
  const [formData, handleFormData, clearForm] = useForm(initialState);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState({ global: "" });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.cta_icon_name.trim().length) { 
      setErr({ global: "Invalid CTA Icon Name" });
      return
    }
    if (!formData.cta_icon_type.trim().length) {
      setErr({ global: "Invalid CTA Icon Type" });
      return;
     }
    if (!cta_icon) {
      setErr({ global: "Please Select Image" });
      return;
     }
      setErr({ global: "" });
      setLoading(true);
      addCTAIcon(formData,cta_icon, editData?._id)
        .then((res) => {
          setDatasFn();
          clearForm();
          setEditData(null);
          setOpen(false);
          toast(`Icon ${editData ? `Edited` : `Added`}`, {
            type: "success",
            autoClose: 3000,
          });
        })
        .catch((err) => {
          console.error(err);
          toast(`Icon ${editData ? `Edit` : `Add`} Failed`, {
            type: "error",
            autoClose: 3000,
          });
        })
        .finally(() => {
          setLoading(false);
        });
  };
  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className="flex  w-full max-w-screen-md overflow-y-auto max-h-screen  border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{editData ? "Edit Icon" : "Add New Icon"}</span>
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </button>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            formName={"cta_icon_name"}
            name={"CTA Icon Name"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            formName={"cta_icon_type"}
            name={"CTA Icon Type"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <ShowImage image={cta_icon} setImage={setCTAIcon} />
          <ImageInput imageName={"Icon Image"} setImage={setCTAIcon}/>
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTAIconModal