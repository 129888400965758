import React from "react";
import { useMemo } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import Table from "../Common/Table/Table";
import { deleteUser } from "../../utils/userUtils/userUtils";

const UsersTable = ({ datas, setDatasFn, user, currentPage, limit = 10 }) => {
  const tableHeadings = useMemo(() => {
    const defaultHeadings = ["SL No", "Name", "Number", "Email", "BD-Name", "BD-Id", "Date", "Time"];
    const userHasDeleteAccess = user?.access?.includes("Delete");
    const headings = [...defaultHeadings];
    if (userHasDeleteAccess) {
      headings.push("Delete");
    }
    return headings;
  }, [user?.access]);
  const startIndex = (currentPage - 1) * limit + 1; 
  const delData = (id) => {
    const confirmed = window.confirm("Are you sure you want to delete ?");
    if (!confirmed) return;
    deleteUser(id)
      .then((res) => {
        setDatasFn();
      })
      .catch((error) => console.error(error));
  };
  return (
    <Table headings={tableHeadings}>
      {datas?.map((data, index) => (
        <tr key={`${data?._id}-${index}`}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {startIndex + index}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 ">{data?.name}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">{data?.phone_number}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">{data?.email_id}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">{data?.bd_id?.name}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">{data?.bd_id?.bd_id}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">
              {data?.createdAt
                ? new Date(data.createdAt).toLocaleDateString()
                : "N/A"}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">
              {data?.createdAt
                ? new Date(data.createdAt).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                    timeZone: "UTC", 
                  })
                : "N/A"}
            </div>
          </td>
          <td className={`px-6 py-4 whitespace-nowrap `}>
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                delData(data?._id);
              }}
            >
              <MdOutlineDeleteOutline color="red" />
            </div>
          </td>
        </tr>
      ))}
    </Table>
  );
};

export default UsersTable;
