import React from "react";
import UserDashboardMainSection from "../../components/UserDashboardComponents/UserDashboardMainSection";

const UserDashboard = () => {
  return (
    <div className=" w-full h-full px-2 pt-10 sm:p-10 flex flex-col gap-4 relative">
      <UserDashboardMainSection />
    </div>
  );
};

export default UserDashboard;
