import React, { useMemo } from "react";
import "./DoctorsTable.css";
import { NoDataFound } from "../Common";
import { BiCheck } from "react-icons/bi";
import { IoIosClose } from "react-icons/io";
import { TbUserEdit } from "react-icons/tb";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { deleteDoctor, updateDoctorStatus } from "../../utils/doctorUtils/doctorUtils";

const DoctorsInformation = ({
  selected,
  doctors,
  setDoctorsFn,
  user,
  setEditData,
  setOpen,
  currentPage,
  limit = 10,
}) => {
  const startIndex = (currentPage - 1) * limit + 1; 
  const tableHeadings = useMemo(() => {
    const defaultHeadings =
      selected !== "Approved Requests"
        ? [
            "SL No",
            "Name",
            "ID",
            "Hospital",
            "Email",
            "Phone",
            "Experience",
            "Actions",
          ]
        : ["SL No", "Name", "ID", "Hospital", "Email", "Phone", "Experience"];

    let userHasEditAccess = false;
    let userHasDeleteAccess = false;
    if (selected === "Approved Requests") {
      userHasEditAccess = user?.access?.includes("Edit");
      userHasDeleteAccess = user?.access?.includes("Delete");
    }
    const headings = [...defaultHeadings];
    if (userHasEditAccess) {
      headings.push("Edit");
    }

    if (userHasDeleteAccess) {
      headings.push("Delete");
    }
    return headings;
  }, [selected, user?.access]);
  const updateStatus = (doctorId, status) => {
    const confirmed = window.confirm(
      "Are you sure you want to do this action ?"
    );
    if (!confirmed) return;
    updateDoctorStatus(doctorId, status, selected)
      .then((res) => setDoctorsFn())
      .catch((err) => console.error(err));
  };
  const delDoctor = (doctorId) => {
    const confirmed = window.confirm("Are you sure you want to delete ?");
    if (!confirmed) return;
    deleteDoctor(doctorId)
      .then((res) => setDoctorsFn())
      .catch((err) => console.log(err));
  };
  return (
    <div className="doctors-information-container flex-col mt-5">
      <div className="di-table-container max-h-screen ">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              {doctors?.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-blue-100">
                    <tr>
                      {tableHeadings.map((heading, index) => (
                        <th scope="col"  key={`${heading}-${index}`} className="di-head-row">
                          {heading}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 max-h-screen  overflow-y-auto">
                    {doctors.map((doctor, index) => (
                      <tr key={`${doctor?._id}-${index}`}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 capitalize">
                            {startIndex + index}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center gap-4">
                            <div className="flex-shrink-0 h-9 w-9 justify-center items-center border rounded-full flex">
                              <img
                                className="rounded-full h-9 w-9 object-cover"
                                src={doctor?.doctor_image}
                                alt="doctor_profile"
                              />
                            </div>
                            <div className="text-sm font-medium text-gray-900">
                              {doctor?.name}
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {doctor?.doctor_id || (
                              <span className="text-red-600">Not Approved</span>
                            )}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {doctor?.hospital_id?.hospital_name}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {doctor?.email}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {doctor?.phone_number}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 capitalize">
                            {doctor?.experience} <span>Year</span>
                          </div>
                        </td>
                        {selected !== "Approved Requests" && (
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-semibold w-full flex justify-start gap-3">
                            <button
                              onClick={() => updateStatus(doctor?._id, false)}
                              className="text-red-600 w-fit border rounded-md p-1  shadow-lg h-fit justify-center items-center active:bg-slate-200"
                            >
                              <IoIosClose />
                            </button>
                            <button
                              onClick={() => updateStatus(doctor?._id, true)}
                              className="w-fit border rounded-md p-1  shadow-lg h-fit justify-center items-center active:bg-slate-200 text-blue-600"
                            >
                              <BiCheck />
                            </button>
                          </td>
                        )}
                        {selected === "Approved Requests" && (
                          <>
                            <td
                              className={`${
                                !user?.access.includes("Edit") && "hidden"
                              } px-6 py-4 whitespace-nowrap `}
                            >
                              <div
                                className="text-sm text-gray-900 capitalize cursor-pointer"
                                onClick={() => {
                                  setEditData(doctor);
                                  setOpen(true);
                                }}
                              >
                                <TbUserEdit />
                              </div>
                            </td>
                            <td
                              className={`${
                                !user?.access.includes("Delete") && "hidden"
                              } px-6 py-4 whitespace-nowrap`}
                            >
                              <div
                                className="text-sm text-gray-900 capitalize cursor-pointer"
                                onClick={() => {
                                  delDoctor(doctor?._id);
                                }}
                              >
                                <MdOutlineDeleteOutline color="red" />
                              </div>
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <NoDataFound data={"doctors"} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorsInformation;
