import { axiosAuthorized } from "../../api/config";

export const getAllPatients = async () => {
    try {
        const response = await axiosAuthorized.get(`/appointments`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getDoctorsData = async () => {
    try {
        const response = await axiosAuthorized.get(`/doctor_counts`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getLabData = async () => {
    try {
        const response = await axiosAuthorized.get(`/lab_counts`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getPatientsData  = async () => {
    try {
        const response = await axiosAuthorized.get(`/total_patient_counts`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getAdmins = async () => {
    try {
      const response = await axiosAuthorized.get(`/getadmin`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const addAdmin = async (initialState,pages,access, image, adminId = null) => {
    try {
        const requestData = {
            name: initialState.name,
            phone_number: initialState.contact,
            email_id: initialState.email,
            department: initialState.department,
            category: initialState.category,
            admin_photo: image,
            pages,
            access
        }
        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };

        const url = adminId
          ? `/admin/update_admin/${adminId}`
          : `/admin/add_admin`;
        const response = await axiosAuthorized[adminId ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteAdmin = async (adminId) => {
    try {
      const response = await axiosAuthorized.delete(`/admin/delete_admin/${adminId}`);
        return response;
    } catch (error) {
        throw error;
    }
}