import JoditEditor from 'jodit-react';
import React, { useRef, useState } from 'react'
import { GrFormClose } from 'react-icons/gr';
import { udpateRemark } from '../../utils/leadsUtils/leadsUitls';

const config = { readonly: false, placeholder: "Remarks" };
const LeadModal = ({ editData, setEditData, setLeadsFn }) => {
    const [err, setErr] = useState({ global: "" });
    const editor = useRef(null);
    const [remarks, setRemarks] = useState( editData?.remarks || "<p></p>");
    const [loading, setLoading] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        if (remarks.replace(/<[^>]*>/g, "").trim().length) {
            setLoading(true);
            udpateRemark(remarks, editData?._id)
                .then(res => { setLeadsFn(); setEditData(null) })
                .catch(err => console.log(err))
                .finally(() => setLoading(false));
        } else {
            setErr({ global: "Invalid Description" });
        }
    }
  return (
    <div className="absolute flex w-full h-full overflow-y-auto top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div className="flex bg-white w-full max-w-screen-md overflow-y-auto h-full border shadow-lg  rounded-md gap-5 px-4 pb-4 pt-2 flex-col">
        <div className="flex w-full h-fit items-center justify-between font-semibold">
          <span>{"Remark"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit">
          <div className="flex flex-col w-full gap-0.5 ">
            <label
              htmlFor={"Address"}
              className="text-[14px] text-shadow-black"
            >
              {"Address"}
            </label>
            <div className="relative flex flex-col justify-center items-end">
              <textarea
                readOnly
                value={editData?.address}
                className="border  p-2 text-[14px] w-full rounded-md outline-none shadow-md  min-h-[90px]"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full gap-0.5">
          <label htmlFor={"Remarks"} className="label">
            {"Remarks"} <span className="text-red-600">*</span>
          </label>
          <JoditEditor
            ref={editor}
            value={remarks}
            config={config}
            tabIndex={1}
            onBlur={(newContent) =>
              setRemarks(
                newContent.replace(
                  /<td(?:[^>]*)style="[^"]*"(?:[^>]*)>/g,
                  '<td style="border: 1px solid black;" >'
                )
              )
            }
          />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeadModal