export const validateReferForm = (initialState, lab_report, insurence_details, prescription, selectedHospital, setErr) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!initialState.surgery_cost.toString().trim().length) {
        setErr({ global: "Invalid Doctor Cost" });
        return false;
    }
    if (!initialState.medidocs_quotation.trim().length) {
        setErr({ global: "Invalid Medidocs Quotation" });
        return false;
    }
    if (!initialState.patient_name.trim().length) {
        setErr({ global: "Invalid Patient Name" });
        return false;
    }
    if (!initialState.email_id.trim().length || !emailRegex.test(initialState.email_id)) {
        setErr({ global: "Invalid Email Address" });
        return false;
    }
    if (!initialState.region.trim().length) {
        setErr({ global: "Invalid Region" });
        return false;
    }
    if (!initialState.contact_no.toString().trim().length) {
        setErr({ global: "Invalid Contact No" });
        return false;
    }
    if (!initialState.disease_name.trim().length) {
        setErr({ global: "Invalid Disease Name" });
        return false;
    }
    if (!initialState.patient_id.trim().length) {
        setErr({ global: "Invalid Patient Id" });
        return false;
    }
    if (!initialState.disease_description.trim().length) {
        setErr({ global: "Invalid Disease Description" });
        return false;
    }
    if (!selectedHospital.hospital_id) {
        setErr({ global: "Please Select Hospital" });
        return false;
    }
    if (!selectedHospital.hospital_quotation.trim().length) {
        setErr({ global: "Invalid Hospital Qoutation" });
        return false;
    }
    return true;
}

export const validateReNegotaiteForm = (initialState, selectedHospital, setErr) => {
    if (!initialState.medidocs_quotation.trim().length) {
        setErr({ global: "Invalid Medidocs Quotation" });
        return false;
    }
    if (!selectedHospital.hospital_id) {
        setErr({ global: "Please Select Hospital" });
        return false;
    }
    if (!selectedHospital.hospital_quotation.trim().length) {
        setErr({ global: "Invalid Hospital Qoutation" });
        return false;
    }
    return true;
}