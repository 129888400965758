import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { useForm } from "../../hooks";
import {  TextInput } from "../Common/FormComponents";
import { IoSearchOutline } from "react-icons/io5";
import { useCallback } from "react";
import { useEffect } from "react";
import { getRefferedListOfHospitals, updateHospitalRefferalStatus } from "../../utils/referUtils/referUtils";
import { validateReNegotaiteForm } from "../../utils/referUtils/validateReferForm";

const LisOfHospitals = ({
  setOpen,
  editData = null,
  setEditData = () => {},
  setRefersFn = () => {},
}) => {
  //   const [selectedHospitals, setSelectedHospitals] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const setHospitalsFn = useCallback(() => {
      getRefferedListOfHospitals(editData?._id)
          .then((res) => setHospitals(res.data.data))
          .catch((error) => console.error(error));
  }, [editData?._id]);
  useEffect(() => {
    setHospitalsFn();
  }, [setHospitalsFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterdHospitals, setFilteredHospitals] = useState([]);
  useEffect(() => {
    const filteredData = hospitals?.filter((hospital) => {
      const nameMatch = hospital?.hospital_id?.hospital_name
        ?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
      return nameMatch;
    });
    setFilteredHospitals(filteredData);
  }, [hospitals, searchQuery]);
  const initialState = {
    email_id: editData?.email_id || "",
    region: editData?.region || "",
    patient_name: editData?.patient_name || "",
    contact_no: editData?.contact_no || "",
    alternative_no: editData?.alternative_no || "",
    disease_name: editData?.disease_name || "",
    patient_id: editData?.patient_id || "",
    surgery_cost: editData?.surgery_cost || "",
    medidocs_quotation: editData?.medidocs_quotation || "",
    disease_description: editData?.disease_description || "",
  };
  const [selected, setSelected] = useState({
    _id: null,
    hospital_id:  null,
    hospital_quotation: "",
    remarks: "",
    refferal_status: null,
    negotiation_status: null,
  });
  //   const handleHospitals = (selectedHospital, idx = null) => {
  //       if (idx !== null) {
  //           setSelectedHospitals(selectedHospitals.filter((item, index) => index !== idx));
  //           return;
  //     }
  //     if (!selectedHospital.hospital_quotation.trim().length) {
  //         setErr({ hospital: "Please Enter Quotation" });
  //         return;
  //       }
  //       const index = selectedHospitals.findIndex(hospital => hospital.hospital_id?._id === selectedHospital.hospital_id?._id);
  //       const updatedHospitals = [...selectedHospitals];
  //     if (index !== -1) {
  //         updatedHospitals[index] = { hospital_id: selectedHospital.hospital_id, hospital_quotation: selectedHospital.hospital_quotation, remarks: selectedHospital.remarks };
  //     } else {
  //         updatedHospitals.push({
  //           hospital_id: selectedHospital.hospital_id,
  //           hospital_quotation: selectedHospital.hospital_quotation,
  //           remarks: selectedHospital.remarks,
  //         });
  //     }
  //     setSelectedHospitals(updatedHospitals);
  //   };
  const [formData, handleFormData, _clearForm, setValues] = useForm(initialState);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState({ global: "", hospital: "" });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateReNegotaiteForm(formData,selected, setErr)) {
      setErr({ global: "" });
      setLoading(true);
        updateHospitalRefferalStatus(formData, selected).then(res => {
            setRefersFn();
        }).catch(err => {
            console.error(err);
        }).finally(() => setLoading(false));
    }
  };
  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className="flex  w-full max-w-screen-md overflow-y-auto max-h-screen  border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"List of Hospitals"}</span>
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </button>
        </div>
        <div className="flex w-full h-fit flex-col gap-5">
          {/* <div className="flex flex-wrap w-full">
            {selectedHospitals?.map((item, idx) => (
              <div
                key={idx}
                className="text-sm px-2 py-1 text-white flex gap-2 border border-white items-center rounded-md"
              >
                <button onClick={()=>{setSelected({ hospital_id: item.hospital_id, hospital_quotation: item.hospital_quotation, remarks: item.remarks });}}>{item?.hospital_id?.hospital_name}</button>
                <button><AiOutlineCloseCircle color="red" onClick={()=>handleHospitals(item,idx)}/></button>
              </div>
            ))}
          </div> */}
          <div className="relative flex flex-col gap-0.5">
            <span className="absolute inset-y-0 left-0 top-5 flex items-center pl-2">
              <IoSearchOutline fontSize={23} />
            </span>
            <label
              htmlFor={"List Of Hospitals"}
              className="text-[14px] text-shadow-black text-white"
            >
              {"List Of Hospitals"}
            </label>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              className="appearance-none rounded-md w-full bg-white border border-gray-300 py-2 pl-10 pr-4 text-gray-700 leading-tight focus:outline-none focus:border-primary"
              placeholder="Search By Name"
            />
          </div>
          <div className="flex gap-5 flex-wrap min-h-[100px] max-h-[100px] overflow-y-scroll px-5 m-3 ">
            {filterdHospitals.length > 0 ? (
              filterdHospitals.map((item) => (
                <div
                  key={item._id}
                  onClick={() => {
                    setSelected({
                      _id: item?._id,
                      hospital_id: item?.hospital_id,
                      hospital_quotation: item?.hospital_quotation || "",
                      remarks: item?.remarks || "",
                      refferal_status: item?.refferal_status || "Pending",
                      negotiation_status: item?.negotiation_status || "Pending",
                    });
                    setValues({
                      ...initialState,
                      medidocs_quotation: item?.medidocs_quotation,
                    });
                  }}
                  className={`flex px-2 py-1 cursor-pointer w-fit h-fit  border rounded-md ${
                    selected?.hospital_id?._id === item?.hospital_id?._id
                      ? `bg-white text-primary border-primary`
                      : `text-white`
                  }`}
                >
                  {item?.hospital_id?.hospital_name}
                </div>
              ))
            ) : (
              <div className="flex h-full w-full justify-center items-center text-white">
                No Hospitals Found!
              </div>
            )}
          </div>
          {selected.hospital_id && (
            <div className="flex flex-col w-full gap-5">
              <div className="flex w-full h-fit justify-between gap-5">
                <div className="flex flex-col w-full gap-0.5 ">
                  <label
                    htmlFor={""}
                    className=" text-[14px] text-shadow-black text-white "
                  >
                    {`${selected.hospital_id.hospital_name} Quotation`}
                    <span className="text-red-600">*</span>
                  </label>
                  <div
                    className=" relative flex flex-col justify-center items-end "
                    onClick={() => setErr({ hospital: "" })}
                  >
                    <input
                      type="text"
                      value={selected.hospital_quotation}
                      onChange={(e) => {
                        setSelected({
                          ...selected,
                          hospital_quotation: e.target.value,
                        });
                      }}
                      placeholder={`${selected.hospital_id.hospital_name} Quotation`}
                      className="border  p-2 text-[14px] w-full rounded-md outline-none shadow-md "
                    />
                  </div>
                </div>
                <TextInput
                  formName={"medidocs_quotation"}
                  name={"Medidocs Quotation"}
                  data={formData}
                  handleInput={handleFormData}
                  setError={setErr}
                />
              </div>
              <div className="flex flex-col w-full gap-0.5 ">
                <label
                  htmlFor={""}
                  className=" text-[14px] text-shadow-black text-white "
                >
                  {`${selected.hospital_id.hospital_name} remarks`}
                </label>
                <div
                  className=" relative flex flex-col justify-center items-end "
                  onClick={() => setErr({ hospital: "" })}
                >
                  <textarea
                    value={selected.remarks}
                    onChange={(e) => {
                      setSelected({
                        ...selected,
                        remarks: e.target.value,
                      });
                    }}
                    placeholder={`${selected.hospital_id.hospital_name} Remarks`}
                    className="border  p-2 text-[14px] w-full min-h-[100px] rounded-md outline-none shadow-md "
                  />
                </div>
              </div>
              {/* <div className="flex bg-gray-400 rounded-md w-fit h-fit">
                <button
                  className=" p-1 border rounded-md text-black -translate-y-1 active:-translate-y-0 translate-x-1 active:translate-x-0 duration-200 font-semibold bg-white text-xs"
                  onClick={() => {
                    handleHospitals(selected);
                  }}
                >
                  Add
                </button>
              </div> */}
            </div>
          )}
        </div>
        {selected.refferal_status && selected.negotiation_status && (
          <div className="flex w-full h-fit justify-between gap-5">
            <div className="flex w-full gap-2 items-center">
              <span className="text-white ">Refferal Status</span>
              <div
                className={`text-sm text-center capitalize px-2 py-1 rounded-full text-white ${
                  selected?.refferal_status === "Rejected"
                    ? "bg-red-500"
                    : selected?.refferal_status === "Pending"
                    ? "bg-blue-500"
                    : "bg-green-500"
                }`}
              >
                {selected?.refferal_status}
              </div>
            </div>
            <div className="flex w-full gap-2  items-center">
              <span className="text-white ">Negotiation Status</span>
              <div
                className={`text-sm text-center capitalize px-2 py-1 rounded-full text-white ${
                  selected?.negotiation_status === "Rejected"
                    ? "bg-red-500"
                    : selected?.negotiation_status === "Pending"
                    ? "bg-blue-500"
                    : "bg-green-500"
                }`}
              >
                {selected?.negotiation_status}
              </div>
            </div>
          </div>
        )}
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              style={{
                background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
              }}
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Re-Negotitate
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LisOfHospitals;
