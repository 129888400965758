import React, { useState } from 'react';
import {GrFormClose} from "react-icons/gr"
import { useForm } from '../../hooks';
import { ImageInput, RadioInput, ShowImage, TextInput } from '../Common/FormComponents';
import { addHospital } from '../../utils/hospitalUtils/hospitalUtils';
import { toast } from "react-toastify";
import { validateHospitalForm } from '../../utils/hospitalUtils/validateHospitalForm';

const HospitalModal = ({ setOpen, editData = null, setEditData = () => { }, setHospitalsFn }) => {
  const initialState = {
    hospital_name: editData?.hospital_name || "",
    type: editData?.type || "hospital",
    email_id:editData?.email_id || "",
    phone_number:editData?.phone_number?.toString() || ""
  };
  const [hospital_picture,setHospitalImage] = useState(editData?.hospital_picture || null);
  const [profile_image, setProfileImage] = useState(editData?.profile_image || null);
  const [formData, handleFormData, clearForm] = useForm(initialState);
  const [loading,setLoading] = useState(false);
  const [err, setErr] = useState({ global: "" });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateHospitalForm(formData,hospital_picture,profile_image,setErr)) {
      setErr({ global: "" });
      setLoading(true);
        addHospital(formData, hospital_picture, profile_image, editData?._id)
            .then(res => {
                setHospitalsFn(); clearForm(); setEditData(null); setOpen(false); toast(`Hospital ${editData ? `Edited` : `Added`}`, {
                    type: "success",
                    autoClose: 3000,
                })
            })
            .catch(err => { console.error(err);toast(`Hospital ${editData ? `Edit` : `Add`} Failed`, {
              type: "error",
              autoClose: 3000,
            }); })
            .finally(() => { setLoading(false) });
    }
  }
  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className="flex  w-full max-w-screen-md overflow-y-auto max-h-screen  border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{editData ? "Edit Hospital" : "Add New Hospital"}</span>
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </button>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <RadioInput
            data={formData}
            name="Type"
            formName={"type"}
            handleInput={handleFormData}
            setError={setErr}
            hide={true}
            options={[
              { label: "Hospital", value: "hospital" },
              { label: "Clinic", value: "clinic" },
            ]}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            formName={"hospital_name"}
            name={"Hospital Name"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            formName={"email_id"}
            name={"Email"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            formName={"phone_number"}
            name={"Phone Number"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <ImageInput
            image={profile_image}
            setImage={setProfileImage}
            imageName={"Profile Image"}
            dimension="height : 350px, width:550px"
          />
          <ImageInput
            image={hospital_picture}
            setImage={setHospitalImage}
            imageName={"Hospital Image"}
            dimension="height : 350px, width:550px"
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          {
            <>
              <ShowImage image={profile_image} setImage={setProfileImage} />
              <ShowImage image={hospital_picture} setImage={setHospitalImage} />
            </>
          }
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HospitalModal