import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import {
  // AddButton,
  ImageInput,
  ShowImage,
} from "../Common/FormComponents";
import { toast } from "react-toastify";
// import { AiOutlineCloseCircle } from "react-icons/ai";
import { addBannerSlider } from "../../utils/hospitalListingUtils/hospitalListingUtils";

const BannerSliderModal = ({
  setOpen,
  setDatasFn,
  editData = null,
  setEditData,
}) => {
  const [err, setErr] = useState({ global: "" });
  const [image, setImage] = useState(editData?.banner_slider || null);
  // const [images, setImages] = useState([]);
  // const handleImages = (action, idx = 0) => {
  //   setErr({ global: "" });
  //   if (action === "add") {
  //     if (!image) {
  //       return setErr({ global: "Please Select Image" });
  //     } else {
  //       setImages([...images, image]);
  //       setImage(null);
  //     }
  //   } else if (action === "remove") {
  //     setImages(images.filter((_, index) => index !== idx));
  //   }
  // };
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!image)
      return setErr({ global: "Please Select Image" });
    setErr({ global: "" });
    setLoading(true);
    addBannerSlider(image, editData?._id)
      .then((res) => {
        setDatasFn();
        setImage(null);
        setOpen(false);
        setEditData(null);
        toast(`Banner Slider ${editData ? "Edited" : "Added"}`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        toast(`Banner Slider ${editData ? "Edit Failed" : "Add Failed"}`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="flex z-10 absolute w-full h-fit  rounded-md justify-start items-start bg-white pb-10">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className=" flex  w-fit overflow-y-auto max-h-[400px] border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"Banner Slider"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        {/* {images?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {images?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs text-white"
                key={idx}
              >
                <div className={`flex w-10 h-10 rounded overflow-hidden`}>
                  <img
                    src={
                      item instanceof File ? URL.createObjectURL(item) : item
                    }
                    alt={"certificate_banner_image"}
                    className={`rounded object-cover`}
                  />
                </div>
                <button
                  onClick={() => {
                    handleImages("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )} */}
        <div className="flex w-fit h-fit justify-between gap-5">
          <ImageInput imageName={"Banner Image"} dimension="Widht: 1642px, Height: 600px" setImage={setImage} />
          <ShowImage image={image} setImage={setImage} />
        </div>
          {/* <AddButton handleAdd={handleImages} /> */}
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setEditData(null);
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerSliderModal;
