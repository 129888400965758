import { axiosAuthorized } from "../../api/config"

export const getAboutCtaAndValuesIcon = async () => {
  try {
    const response = await axiosAuthorized.get(`/aboutcta/get_aboutcta`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addAboutCtaAndValuesIcon = async (initialState, about_cta_image, id = null) => {
  try {
    const requestData = {
      ...initialState,
      about_cta_image 
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/aboutcta/update_aboutcta/${id}`
      : `/aboutcta/create_aboutcta`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteAboutCtaAndValuesIcon = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/aboutcta/delete_aboutcta/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}