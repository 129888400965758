import { axiosAuthorized } from "../../api/config"

export const getAboutValue = async () => {
  try {
    const response = await axiosAuthorized.get(`/aboutvalues/get_aboutvalues`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addAboutValue = async (initialState, about_value_image, id = null) => {
  try {
    const requestData = {
      ...initialState,
      about_value_image 
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/aboutvalues/update_aboutvalues/${id}`
      : `/aboutvalues/create_aboutvalues`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteAboutValue = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/aboutvalues/delete_aboutvalues/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}