import { axiosAuthorized } from "../../api/config"

export const getTitleAndDescriptions = async () => {
  try {
    const response = await axiosAuthorized.get(`/homepage/get_homepage`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addTitleAndDescriptions = async (initialState, id = null) => {
  try {
    const requestData = {
      ...initialState
    };

    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const url = id
      ? `/homepage/update_homepage/${id}`
      : `/homepage/add_homepage`;
    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteTitleAndDescriptions = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/homepage/delete_homepage/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}