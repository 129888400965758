export const validateContentForm = (question,answer, image, setError) => {
    if (!question.trim().length) {
        setError({ content: "Invalid Question" });
        return false;
    }

    if (!answer.replace(/<[^>]*>/g, '').trim().length) {
        setError({ content: "Invalid Answer" });
        return false;
    }

    return true;

}