import React, { useEffect, useState, useMemo } from "react";
import { Loader, NoDataFound, Pagination, SearchInput } from "../Common";
import HospitalTable from "./HospitalsTable";
import { CiHospital1 } from "react-icons/ci";
import HospitalModal from "./HospitalModal";
import { getHospitals } from "../../utils/hospitalUtils/hospitalUtils";
import { useSelector } from "react-redux";
import _ from "lodash";

const limit = 10; 

const HospitalMainSection = () => {
  const [hospitals, setHospitals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    next: null,
    prev: null,
    total: 1,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [editData, setEditData] = useState(null);
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.userReducer.admin);

  const fetchHospitals = async (search, page = 1) => {
    setLoading(true);
    try {
      const response = await getHospitals(null, true, search, page);
      const { data: inside } = response?.data;
      const { data: hospitals, pagination } = inside;
      setHospitals(hospitals);
      setPagination(pagination);
    } catch (error) {
      console.error("Error fetching hospitals:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchHospitals = useMemo(
    () =>
      _.debounce(async (search, page = 1) => {
        await fetchHospitals(search, page);
      }, 300),
    []
  );

  useEffect(() => {
    debouncedFetchHospitals(searchQuery, pagination?.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFetchHospitals, pagination?.current, searchQuery]);

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({ ...prev, current: newPage }));
  };
  const handleSearchQueryChange = (newSearchQuery) => {
    const value = newSearchQuery;
    if (value.length === 1 && value === " ") {
      return;
    }
    const modifiedValue = value.replace(/\s+$/, " ");
    setSearchQuery(modifiedValue);
    setPagination((prev) => ({ ...prev, current: 1 }));
    debouncedFetchHospitals(modifiedValue, 1);
  };
  return (
    <>
      {open && (
        <HospitalModal
          setOpen={setOpen}
          setEditData={setEditData}
          setHospitalsFn={() =>
            fetchHospitals(searchQuery, pagination?.current)
          }
          editData={editData}
        />
      )}
      <div className="flex w-full flex-col items-center gap-5">
        <div className="flex w-fit h-fit text-xl font-semibold">
          Hospital/Clinic
        </div>
        <div className="flex w-full h-full justify-center items-center">
          <div className="flex w-full md:w-1/2">
            <SearchInput
              placeholder={"Search by Name, Id"}
              searchQuery={searchQuery}
              setSearchQuery={handleSearchQueryChange}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full p-3 flex-col">
        <div className="flex w-full justify-between items-center h-fit">
          <span className="font-semibold text-base">Informations</span>
          <button
            disabled={!user.access.includes("Edit")}
            style={{
              background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
            }}
            className={`text-white text-sm rounded-md gap-2 p-2 items-center ${
              !user.access.includes("Edit") ? "hidden" : "flex"
            }`}
            onClick={() => setOpen(true)}
          >
            <span>
              <CiHospital1 />
            </span>
            <span>Hospital/Clinic</span>
          </button>
        </div>
        {loading ? (
          <div className="flex mt-10">
            <Loader />
          </div>
        ) : hospitals?.length > 0 ? (
          <>
            <HospitalTable
              datas={hospitals}
              setEditData={setEditData}
              setHospitalsFn={() =>
                fetchHospitals(searchQuery, pagination?.current)
              }
              setOpen={setOpen}
              user={user}
              currentPage={pagination?.current}
              limit={limit}
            />
            {!open && (
              <Pagination
                current={pagination?.current}
                total={pagination?.total}
                onPageChange={handlePageChange}
              />
            )}
          </>
        ) : (
          <div className="flex mt-10">
            <NoDataFound data={"Hospital/Clinic"} />
          </div>
        )}
      </div>
    </>
  );
};


export default HospitalMainSection;
