import { axiosAuthorized } from "../../api/config"

export const getOfferSlider = async () => {
  try {
    const response = await axiosAuthorized.get(`/getuserhomepage`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addOfferSlider = async (initialState, user_offers, user_offer_image, id = null) => {
  try {
    const requestData = {
      ...initialState,
      user_offers,
      user_offer_image
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/userhomepage/update_userhomepage/${id}`
      : `/userhomepage/add_userhomepage`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteOfferSlider = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/userhomepage/delete_userhomepage/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const getNeedHelp = async () => {
  try {
    const response = await axiosAuthorized.get(`/getuserhelp`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addNeedHelp = async (initialState, id = null) => {
  try {
    const requestData = {
      ...initialState,
    };

    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const url = id
      ? `/userhelp/update_userhelp/${id}`
      : `/userhelp/add_userhelp`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteNeedHelp = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/userhelp/delete_userhelp/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const getBannerImages = async () => {
  try {
    const response = await axiosAuthorized.get(`/getuserbannerimage`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addBannerImage = async (user_offer_banner_image, id = null) => {
  try {
    const requestData = {
      user_offer_banner_image
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/userad/update_userad/${id}`
      : `/userad/add_userad`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteBannerImage = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/userad/delete_userad/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}