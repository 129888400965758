import React, { useCallback, useEffect, useState } from "react";
import Table from "../Common/Table/Table";
import { NoDataFound } from "../Common";
import FaqModal from "./FaqModal";
import { getFaq } from "../../utils/homeUtils/faqUtils";

const Faq = () => {
  const [open, setOpen] = useState(false);
  const [datas, setDatas] = useState(null);
  const [editData, setEditData] = useState(null);
  const setDatasFn = useCallback(() => {
    getFaq()
      .then((res) => {setDatas(res.data.data[0] || null)})
      .catch((err) => console.error(err));
  }, []);
  useEffect(() => {
    setDatasFn();
  }, [setDatasFn]);
  return (
    <div className="w-full h-fit flex flex-col gap-5 relative">
      {open && (
        <FaqModal
          setDatasFn={setDatasFn}
          setOpen={setOpen}
          editData={editData}
        />
      )}
      <div className="flex w-full h-fit text-base font-semibold gap-5 items-center">
        <span>Faq</span>
        <button
          style={{
            background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
          }}
          onClick={() => {
            setEditData(datas);
            setOpen(true);
          }}
          className="flex items-center gap-2 px-2 py-1 text-white text-sm font-normal rounded-md"
        >
          <span>Add</span>
        </button>
      </div>
      {datas?.faq?.length > 0 ? (
        <Table headings={["Question"]}>
          {datas?.faq?.map((data, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 capitalize">
                  {data?.faq_question}
                </div>
              </td>
            </tr>
          ))}
        </Table>
      ) : (
        <NoDataFound data={"Faqs"} />
      )}
    </div>
  );
};

export default Faq;
