import { axiosAuthorized } from "../../api/config"

export const getOurSpecialitiesDoctors = async () => {
  try {
    const response = await axiosAuthorized.get(`/getourspecialist`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addOurSpecialitiesDoctors = async (initialState, doctor_id, id = null) => {
  try {
    doctor_id = doctor_id?.map(item => item?._id);
    const requestData = {
      ...initialState,
      doctor_id
    };

    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const url = id
      ? `/ourspecialist/update_ourspecialist/${id}`
      : `/ourspecialist/add_ourspecialist`;
    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteOurSpecialitesDoctors = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/ourspecialist/delete_ourspecialist/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}