import { axiosAuthorized } from "../../api/config"

export const getSerivceHeaderSection = async () => {
  try {
    const response = await axiosAuthorized.get(`/getourservicepagetitle`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addSerivceHeaderSection = async (initialState, id = null) => {
  try {
    const requestData = {
      ...initialState,
    };

    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const url = id
      ? `/ourservicepagetitle/update_ourservicepagetitle/${id}`
      : `/ourservicepagetitle/add_ourservicepagetitle`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteServiceHeaderSection = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/ourservicepagetitle/delete_ourservicepagetitle/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getServices = async () => {
  try {
    const response = await axiosAuthorized.get(`/getourservicepage`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addService = async (initialState, description, our_services_images, second_our_service_image, id = null) => {
  try {
    const data = {
      ...initialState,
      description
    }
    const requestData = id ? data : {
      ...data,
      our_services_images,
      second_our_service_image
    }
    // const requestData = new FormData();
    // Object.entries(initialState).forEach(([key, value]) => {
    //   requestData.append(key, value);
    // });
    // description.forEach((item, index) => {
    //   Object.entries(item).forEach(([item_key, item_value]) => {
    //     requestData.append(`description[${index}][${item_key}]`, item_value);
    //   });
    // });
    // requestData.append('our_services_images', our_services_images);
    // requestData.append('second_our_service_image', second_our_service_image);
    const requestConfig = {
      headers: { "Content-Type": id ? "application/json" : "multipart/form-data" },
    };

    const url = id ? `/ourservicepage/update_ourservicepage/${id}` : `/ourservicepage/add_ourservicepage`;
    const mainRequest = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    const imageRequests = [];
    if (id && our_services_images instanceof File) {
      imageRequests.push(
        axiosAuthorized['put'](`/ourservicepage/update_ourserviceimage/${id}`, { our_services_images }, { headers: { "Content-Type": "multipart/form-data" } })
      );
    }
    if (id && second_our_service_image instanceof File) {
      imageRequests.push(
        axiosAuthorized['put'](`/ourservicepage/update_ourservicepage/${id}`, { second_our_service_image }, { headers: { "Content-Type": "multipart/form-data" } })
      );
    }
    const response = await mainRequest;
    await Promise.all(imageRequests);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteService = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/ourservicepage/delete_ourservicepage/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getBenefits = async () => {
  try {
    const response = await axiosAuthorized.get(`/getourbenefits`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addBenefits = async (initialState, our_service, our_benefits_icon , id = null) => {
  try {
    const requestData = {
      ...initialState,
      our_service,
      our_benefits_icon
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/ourbenefits/update_ourbenefits/${id}`
      : `/ourbenefits/add_ourbenefits`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteBenefit = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/ourbenefits/delete_ourbenefits/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}
