import React, { useCallback, useEffect, useState } from "react";
import AboutHeaderSectionModal from "./AboutHeaderSectionModal";
import { getAboutHeaderSection } from "../../utils/aboutHeaderSectionUtils/aboutHeaderSectionUtils";

const AboutHeaderSection = () => {
  const [open, setOpen] = useState(false);
  const [datas, setDatas] = useState([]);
  const setDatasFn = useCallback(() => {
    getAboutHeaderSection()
      .then((res) => setDatas(res.data.data[0] || null))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setDatasFn();
  }, [setDatasFn]);
  return (
    <div className="w-full h-fit flex flex-col gap-5 relative">
      {open && (
        <AboutHeaderSectionModal
          setDatasFn={setDatasFn}
          setOpen={setOpen}
          editData={datas}
        />
      )}
      <div className="flex w-full h-fit text-base font-semibold gap-5 items-center">
        <span>About Header Section</span>
        <button
          style={{
            background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
          }}
          onClick={() => {
            setOpen(true);
          }}
          className="flex items-center gap-2 px-2 py-1 text-white text-sm font-normal rounded-md"
        >
          <span>Update</span>
        </button>
      </div>
    </div>
  );
};

export default AboutHeaderSection;
