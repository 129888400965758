import { capitalize } from 'lodash';
import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from "react-router-dom";

export const UserProtected = ({element}) => {
    const { admin } = useSelector((state) => state.userReducer);
    const location = useLocation();
    const url = location.pathname.split("/")[1];
    if (admin && (admin.pages.includes(capitalize(url)) || url === "")) {
      return <>{element}</>;
    }
    return <Navigate to={"/login"} replace />;
}

export const AuthProtected = ({ element }) => {
    const { admin } = useSelector((state) => state.userReducer);
    if (admin) {
        return <Navigate to={"/"} replace />;
    }
    return <>{element}</>;
}
