import React from 'react'

const Table = ({headings,children}) => {
  return (
    <div className="h-full w-full mt-3">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 max-h-screen ">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 ">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-blue-100">
                <tr>
                  {headings.map((heading, index) => (
                    <th
                      scope="col"
                      key={`${heading}-${index}`}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase "
                    >
                      {heading}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 max-h-screen overflow-y-auto">
                {children}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table