import React from 'react'
import "./AddButton.css"

const AddButton = ({handleAdd}) => {
  return (

      <div className="button-shadow">
        <button className='button-body' onClick={()=>{handleAdd("add")}}>Add</button>
      </div>

  );
}

export default AddButton