import React from "react";
import BannerSlider from "./BannerSlider";
import ListingBanner from "./ListingBanner";

const HospitalListingMainSection = () => {
  return (
    <>
      <div className=" flex w-full items-center gap-5 md:gap-0 flex-col">
        <div className="flex w-full h-fit text-xl font-semibold">Hospitals Listing</div>
        <div className="flex flex-col w-full mt-5 gap-5">
          <BannerSlider />
          <ListingBanner/>
        </div>
      </div>
    </>
  );
};

export default HospitalListingMainSection;
