import React from "react";
import BranchesMainSection from "../../components/BranchesComponents/BranchesMainSection";

const Branches = () => {
  return (
    <div className=" w-full h-full px-2 py-10 sm:p-10 flex flex-col gap-4 relative">
      <BranchesMainSection/>
    </div>
  );
};

export default Branches;
