import React, { useEffect, useState } from "react";
import "./TreatmentsMainSection.css";
import { Loader, Pagination, SearchInput } from "../Common";
import { LiaStethoscopeSolid } from "react-icons/lia";
import TreatmentInformation from "./TreatmentsTable";
import TreatmentModal from "./TreatmentModal";
import { getTreatments } from "../../utils/treamentUtils/treatmentUtils";
import { useSelector } from "react-redux";

import _ from "lodash";
import { useMemo } from "react";

const limit = 10;

const TreatmensMainSection = () => {
  const [treatments, setTreatments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    next: null,
    prev: null,
    total: 1,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [editData, setEditData] = useState(null);
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.userReducer.admin);

  const fetchTreatments = async (search, page = 1) => {
    setLoading(true);
    try {
      const response = await getTreatments(true, search, page);
      const { data: inside } = response?.data;
      const { data: treatments, pagination } = inside;
      setTreatments(treatments);
      setPagination(pagination);
    } catch (error) {
      console.error("Error fetching treatments:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchTreatments = useMemo(
    () =>
      _.debounce(async (search, page = 1) => {
        await fetchTreatments(search, page);
      }, 300),
    []
  );

  useEffect(() => {
    debouncedFetchTreatments(searchQuery, pagination?.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, debouncedFetchTreatments, pagination?.current]);

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({ ...prev, current: newPage }));
  };
  const handleSearchQueryChange = (newSearchQuery) => {
    const value = newSearchQuery;
    if (value.length === 1 && value === " ") {
      return;
    }
    const modifiedValue = value.replace(/\s+$/, " ");
    setSearchQuery(modifiedValue);
    setPagination((prev) => ({ ...prev, current: 1 }));
    debouncedFetchTreatments(modifiedValue, 1);
  };
  return (
    <>
      {open && (
        <TreatmentModal
          user={user}
          setOpen={setOpen}
          editData={editData}
          setEditData={setEditData}
          setTreatmentFn={() =>
            fetchTreatments(searchQuery, pagination?.current)
          }
        />
      )}
      <div className="tp-header flex-col">
        <div className="flex w-fit h-fit text-xl font-semibold">Treatments</div>
        <div className="tp-header-search-container">
          <div className="flex w-full md:w-1/2">
            <SearchInput
              placeholder={"Search by Name"}
              searchQuery={searchQuery}
              setSearchQuery={handleSearchQueryChange}
            />
          </div>
        </div>
      </div>
      <div className="tp-info-container">
        <div className="tp-info-header">
          <span className="font-semibold text-base">Informations</span>
          <button
            disabled={!user.access.includes("Edit")}
            style={{
              background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
            }}
            className={`treatment-add-button
              ${!user.access?.includes("Edit") ? "hidden" : "flex"}`}
            onClick={() => setOpen(true)}
          >
            <span>
              <LiaStethoscopeSolid />
            </span>
            <span>Treatment</span>
          </button>
        </div>
        {loading ? (
          <div className="flex mt-10">
            <Loader />
          </div>
        ) : (
          <>
            <TreatmentInformation
              setEditData={setEditData}
              setOpen={setOpen}
              user={user}
              treatments={treatments}
              setTreatmentFn={() =>
                fetchTreatments(searchQuery, pagination?.current)
              }
              currentPage={pagination?.current}
              limit={limit}
            />
            {!open && (
              <Pagination
                current={pagination?.current}
                total={pagination?.total}
                onPageChange={handlePageChange}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TreatmensMainSection;
