import React, { useCallback, useEffect, useState } from "react";
import Table from "../Common/Table/Table";
import { CiEdit } from "react-icons/ci";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { NoDataFound, SearchInput } from "../Common";
import { deleteBenefit, getBenefits } from "../../utils/serviceUtils/serviceUtils";
import BenefitsModal from "./BenefitsModal";

const Benefits = () => {
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [datas, setDatas] = useState([]);
  const setDatasFn = useCallback(() => {
    getBenefits()
      .then((res) => setDatas(res.data.data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setDatasFn();
  }, [setDatasFn]);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    useEffect(() => {
      const filteredData = datas?.filter((data) => {
        const nameMatch = data?.title
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase());
        const serviceMatch = data?.our_service?.title
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase());
        return nameMatch || serviceMatch;
      });
      setFilteredData(filteredData);
    }, [datas, searchQuery]);
  const delData = (dataId) => {
    const confirmed = window.confirm("Are you sure you want to delete ?");
    if (!confirmed) return;
    deleteBenefit(dataId)
      .then((res) => {
        setDatasFn();
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="w-full h-fit flex flex-col gap-5 relative">
      {open && (
        <BenefitsModal
          setDatasFn={setDatasFn}
          setOpen={setOpen}
          editData={editData}
          setEditData={setEditData}
        />
      )}
      <div className="flex w-full h-fit text-base font-semibold gap-5 items-center">
        <span>Benefits</span>
        <button
          style={{
            background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
          }}
          onClick={() => {
            setOpen(true);
          }}
          className="flex items-center gap-2 px-2 py-1 text-white text-sm font-normal rounded-md"
        >
          <span>Add</span>
        </button>
      </div>
      <div className="flex w-full h-full justify-center items-center">
        <div className="flex w-full md:w-1/2">
          <SearchInput
            placeholder={"Search by Title, Service"}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
      </div>
      {filteredData?.length > 0 ? (
        <Table headings={["Image", "Title", "Service", "Edit", "Delete"]}>
          {filteredData.map((data, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  <div className="flex-shrink-0  h-16 w-16  justify-center items-center  rounded-md flex">
                    <img
                      className="rounded-md h-16 w-16 object-cover"
                      src={data?.our_benefits_icon}
                      alt="our_benefits_icon"
                    />
                  </div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">{data?.title}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {data?.our_service?.title}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div
                  className="text-sm text-gray-900 capitalize cursor-pointer"
                  onClick={() => {
                    setEditData(data);
                    setOpen(true);
                  }}
                >
                  <CiEdit color="green" />
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div
                  className="text-sm text-gray-900 capitalize cursor-pointer"
                  onClick={() => {
                    delData(data?._id);
                  }}
                >
                  <MdOutlineDeleteOutline color="red" />
                </div>
              </td>
            </tr>
          ))}
        </Table>
      ) : (
        <NoDataFound data={"benefits"} />
      )}
    </div>
  );
};

export default Benefits;
