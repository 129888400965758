import { axiosAuthorized } from "../../api/config";

export const getTreatments = async (withPagination = false, search = '', page = 1) => {
  try {
    let url = withPagination ? `/getsubdepartmentwithpagination` : '/getsubdepartment';
    const params = [];
    if (withPagination && search) {
      params.push(`search=${encodeURIComponent(search)}`);
    }
    if (withPagination && page) {
      params.push(`page=${page}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    const response = await axiosAuthorized.get(url);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addTreatment = async (initialState, sub_department_descriptiion, dpId, symptoms, treatment_image, treatments_banners_slider, meta_tag,user, subdepartment_id = null) => {
  try {
    const data = {
      sub_department_name: initialState.name,
      slug: initialState.slug,
      admin_id: user?._id,
      department_id: dpId,
      sub_department_descriptiion,
      meta_name: initialState.meta_name,
      meta_description: initialState.meta_description,
      meta_tag,
      symptoms,
    };
    const requestData = subdepartment_id ? {
      ...data,
      ...(
        (!(treatment_image instanceof File) && !(treatments_banners_slider instanceof File)) && {
          treatment_image: treatment_image ? treatment_image : "",
          treatments_banners_slider: treatments_banners_slider ? treatments_banners_slider : ""
        }
      )
    } : {
      ...data,
      treatment_image,
      treatments_banners_slider
    };
    
    const requestConfig = {
      headers: { "Content-Type": subdepartment_id ? "application/json" : "multipart/form-data" },
    };
    const url = subdepartment_id
      ? `/department/update_subdepartment/${subdepartment_id}`
      : `/department/add_subdepartment`;

    const mainRequest  = axiosAuthorized[subdepartment_id ? 'put' : 'post'](url, requestData, requestConfig);
    const imageRequests = [];
    if (subdepartment_id && treatment_image instanceof File) {
      imageRequests.push(
        axiosAuthorized['put'](`/subdepartment/update_treatmentimage/${subdepartment_id}`, { treatment_image }, { headers: { "Content-Type": "multipart/form-data" } })
      );
    }
    if (subdepartment_id && treatments_banners_slider instanceof File) {
      imageRequests.push(
        axiosAuthorized['put'](`/subdepartment/update_treatmentbannerimage/${subdepartment_id}`, { treatments_banners_slider }, { headers: { "Content-Type": "multipart/form-data" } })
      );
    }
    const response = await mainRequest;
    await Promise.all(imageRequests);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteTreatment = async (treatmentId) => {
  try {
    const response = await axiosAuthorized.delete(`/department/delete_subdepartment/${treatmentId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addPackage = async (initialState, benefits, treatmentId, image) => {
  try {
    const response = await axiosAuthorized.post('/package/add_package', {
      subdepartment_id: treatmentId,
      package_name: initialState.name,
      price: initialState.price,
      benefits,
      duration: initialState.duration,
      image,
    },
      {
        headers: { "Content-Type": "multipart/form-data" }
      });
    return response;
  } catch (error) {
    throw error;
  }
};

export const deletePackage = async (packageId) => {
  try {
    const response = await axiosAuthorized.delete(`/package/delete_package/${packageId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getPackages = async (treatmentId) => {
  try {
    const response = await axiosAuthorized.get(`/packages/${treatmentId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addContent = async (question, answer, treatmentId, image, contentId = null) => {
  try {
    const data = {
      subdepartment_id: treatmentId,
      question,
      answer,
    }
    const requestData = image ? { ...data, content_images: image } : { ...data };
    const url = contentId ? `/content/update_content/${contentId}` : `/content/add_content`
    const response = await axiosAuthorized[contentId ? 'put' : 'post'](url, requestData,
      {
        headers: { "Content-Type": "multipart/form-data" }
      });
    return response;
  } catch (error) {
    throw error;
  }
}

export const getContents = async (treatmentId) => {
  try {
    const response = await axiosAuthorized.get(`/content/${treatmentId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteContenet = async (contentId) => {
  try {
    const response = await axiosAuthorized.delete(`/content/delete_content/${contentId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addFaq = async (initialState, treatmentId, faqId = null) => {
  try {
    const url = faqId ? `/faq/update_faq/${faqId}` : `/faq/add_faq`;
    const response = await axiosAuthorized[faqId ? 'put' : 'post'](url, {
      subdepartment_id: treatmentId,
      faq_title: initialState.title,
      faq_answer: initialState.answer,
    },
      {
        headers: { "Content-Type": "application/json" }
      });
    return response;
  } catch (error) {
    throw error;
  }
}


export const getFaqs = async (treatmentId) => {
  try {
    const response = await axiosAuthorized.get(`/faqsubdepartment/${treatmentId}`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const deleteFaq = async (faqId) => {
  try {
    const response = await axiosAuthorized.delete(`/faq/delete_faq/${faqId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getTreatmentDiseaseInformation = async (treatmentId) => {
  try {
    const response = await axiosAuthorized.get(`/treatmentinfobysubdepartmentname/${treatmentId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addTreatmentDiseaseInformation = async (sub_department_id, formData, id = null) => {
  try {
    const requestData = {
      sub_department_id,
      ...formData,
    };
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const url = id
      ? `/treatmentinfo/update_treatmentinfo/${id}`
      : `/treatmentinfo/add_treatmentinfo`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}



export const addTreatmentCTAIcon = async (treatment_icon_name, treatmentId, treatment_cta_icon, iconId = null) => {
  try {
    const data = {
      subdepartment_id: treatmentId,
      treatment_icon_name,
    }
    const requestData = treatment_cta_icon ? { ...data, treatment_cta_icon } : { ...data };
    const url = iconId ? `/treatmentctaicon/update_treatmentctaicon/${iconId}` : `/treatmentctaicon/add_treatmentctaicon`
    const response = await axiosAuthorized[iconId ? 'put' : 'post'](url, requestData,
      {
        headers: { "Content-Type": "multipart/form-data" }
      });
    return response;
  } catch (error) {
    throw error;
  }
}

export const getTreatmentCTAIcons = async (treatmentId) => {
  try {
    const response = await axiosAuthorized.get(`/gettreatmentctaiconbytreatment/${treatmentId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteTreatmentCTAIcon = async (iconId) => {
  try {
    const response = await axiosAuthorized.delete(`/treatmentctaicon/delete_treatmentctaicon/${iconId}`);
    return response;
  } catch (error) {
    throw error;
  }
}
