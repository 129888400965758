import React, { useMemo } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import Table from "../Common/Table/Table";
import { deleteSalesBD } from "../../utils/salesUtils/salesUtils";

const SalesTable = ({ datas, setEditData, setOpen, setDatasFn, user }) => {
  const tableHeadings = useMemo(() => {
    const defaultHeadings = ["Name", "ID", "Region", "Email", "Phone", "Designation"];
    const userHasEditAccess = user?.access?.includes("Edit");
    const userHasDeleteAccess = user?.access?.includes("Delete");

    const headings = [...defaultHeadings];

    if (userHasEditAccess) {
      headings.push("Edit");
    }

    if (userHasDeleteAccess) {
      headings.push("Delete");
    }
    return headings;
  }, [user]);
  const delData = (id) => {
    const confirmed = window.confirm("Are you sure you want to delete ?");
    if (!confirmed) return;
    deleteSalesBD(id)
      .then((res) => {
        setDatasFn();
      })
      .catch((error) => console.error(error));
  };
  return (
    <Table headings={tableHeadings}>
      {datas?.map((data, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">{data?.name}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.bd_id}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.region}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">
              {data?.email_id}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.phone_number}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.designation}
            </div>
          </td>
          <td
            className={`${
              !user?.access.includes("Edit") && "hidden"
            } px-6 py-4 whitespace-nowrap `}
          >
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                setEditData(data);
                setOpen(true);
              }}
            >
              <CiEdit color="green" />
            </div>
          </td>
          <td
            className={`${
              !user?.access.includes("Delete") && "hidden"
            } px-6 py-4 whitespace-nowrap `}
          >
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                delData(data?._id);
              }}
            >
              <MdOutlineDeleteOutline color="red" />
            </div>
          </td>
        </tr>
      ))}
    </Table>
  );
};

export default SalesTable;
