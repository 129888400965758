import React from "react";
import ServiceHeaderSection from "./ServicesHeaderSection";
import Services from "./Services";
import Benefits from "./Benefits";

const ServicesMainSection = () => {
  return (
    <div className=" flex w-full items-center gap-5 md:gap-0 flex-col">
      <div className="flex w-full h-fit text-xl font-semibold">Services</div>
      <div className="flex flex-col w-full mt-5 gap-5">
        <ServiceHeaderSection />
        <Services />
        <Benefits/>
      </div>
    </div>
  );
};

export default ServicesMainSection;
