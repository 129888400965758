import { axiosAuthorized } from "../../api/config"

export const getBanner = async () => {
    try {
      const response = await axiosAuthorized.get(`/getbanner`);
        return response;
    } catch (error) {
        throw error;
    }
}


export const addBanner = async (initialState, buttons , image_doctor, id = null) => {
    try {
        const requestData = {
          ...initialState,
          buttons,
          image_doctor 
        };

        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };

        const url = id
          ? `/banner/update_banner/${id}`
          : `/banner/add_banner`;

        const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteBanner = async (id) => {
    try {
      const response = await axiosAuthorized.delete(`/banner/delete_banner/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}